<template>
    <div class="row"
         v-if="isValid('CanViewPurchaseDraft') || isValid('CanAddPurchaseInvoice') || isValid('CanEditPurchaseInvoice')">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 v-if="purchase.id === '00000000-0000-0000-0000-000000000000'" class="page-title">{{$t('AddPurchase.AddPurchaseInvoice')}}</h4>
                                <h4 v-else class="page-title">Update Purchase Invoice</h4>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                   class="btn btn-sm btn-outline-danger">
                                    {{ $t('Sale.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="hr-dashed hr-menu mt-0" />
            <div class="row">
                <div class="col-lg-6">
                    <div class="row form-group" v-bind:key="supplierRender">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline "> {{ $t('AddPurchaseOrder.Supplier')}} : <span class="text-danger">*</span></span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <supplierdropdown v-model="$v.purchase.supplierId.$model" :paymentTerm="purchase.isCredit" v-bind:key="randerSupplier" 
                                              :disable="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'"
                                              v-bind:values="purchase.supplierId" :status="purchase.isRaw" />
                           
                        </div>
                    </div>
                    <fieldset class="form-group">
                        <div class="row">
                            <div class="col-form-label col-lg-4 pt-0">
                                <span id="ember694" class="tooltip-container text-dashed-underline ">
                                    {{
                                        $t('AddPurchase.PaymentMethod')
                                    }} : <span class="text-danger">*</span>
                                </span>
                            </div>
                            <div class="col-lg-8">
                                <div class="form-check form-check-inline" v-if="isValid('CashPurchase')"
                                     v-on:click="SelectPaymentMethod(false)">
                                    <input v-model="purchase.isCredit" name="contact-sub-type" id="a49946497"
                                           class=" form-check-input" type="radio" v-bind:value="false">
                                    <label class="form-check-label pl-0" for="a49946497">{{$t('AddPurchase.Cash')}}</label>
                                </div>
                                <div class="form-check form-check-inline" v-if="isValid('CreditPurchase')"
                                     v-on:click="SelectPaymentMethod(true)">
                                    <input v-model="purchase.isCredit" name="contact-sub-type" id="a9ff8eb35"
                                           class=" form-check-input" type="radio" v-bind:value="true">
                                    <label class="form-check-label pl-0" for="a9ff8eb35">
                                        {{
 $t('AddSale.Credit')
                                        }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </fieldset>

                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline "> {{$t('AddPurchase.SupplierInvoiceNumber')}}#</span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <input v-model="$v.purchase.invoiceNo.$model" class="form-control" type="text">
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">{{$t('AddPurchase.Invoice')}} #</span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <input v-model="purchase.registrationNo" class="form-control" type="text" disabled>
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">{{$t('AddPurchase.DraftInvoice')}} #</span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <input v-model="autoNumber" class="form-control" type="text" disabled>
                        </div>
                    </div>

                    <div class="row form-group" v-if="isValid('CanSelectWarehouse')">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.WareHouse') }} :<span class="text-danger">
                                    *
                                </span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <warehouse-dropdown v-model="$v.purchase.wareHouseId.$model" />
                        </div>
                    </div>

                    <div class="row form-group"
                         v-if="isValid('CreditPurchase') && (isValid('CanViewPostOrder') || isValid('CanAddPurchaseOrder'))">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.PurchaseOrder') }} :
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <purchase-order-dropdown v-on:input="GetPoData(purchase.purchaseOrderId, false)"
                                                     v-model="purchase.purchaseOrderId" v-bind:values="purchase.purchaseOrderId" />
                        </div>
                    </div>
                    <div class="row form-group" v-if="!purchase.isCredit">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.PaymentType') }} :<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <multiselect :options="paymentTypeOptions" @select="GetAccount()"
                                         v-model="purchase.paymentType" :show-labels="false">
                            </multiselect>
                        </div>
                    </div>
                    <div class="row form-group" v-if="!purchase.isCredit">
                        <label class="col-form-label col-lg-4" v-if="purchase.paymentType == 'Cash'">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.CashAccount') }}:<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <label class="col-form-label col-lg-4" v-if="purchase.paymentType != 'Cash'">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.BankAccount') }}:<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <accountdropdown v-model="purchase.bankCashAccountId"
                                             :formName="purchase.paymentType == 'Cash' ? 'CashPay' : 'BankPay'" :advance="'true'"
                                             :key="accountRender" />
                        </div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">{{ $t('AddPurchase.Date') }} :</span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <datepicker v-model="purchase.date" v-if="purchase.allowPreviousFinancialPeriod" />
                            <input v-model="purchase.date" class="form-control" type="text" disabled v-else>
                        </div>
                    </div>

                    <div class="row form-group"
                         v-if="isValid('CanAddGoodsReceiveasPost') || isValid('CanViewGoodsReceiveasPost')">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('GoodReceive.GoodReceive') }} :
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <GoodReceiveDropdown v-on:input="GetGRNData(purchase.goodReceiveNoteId, false)"
                                                 v-model="purchase.goodReceiveNoteId" v-bind:values="purchase.goodReceiveNoteId" />
                        </div>
                    </div>
                    <div class="row form-group"
                         v-if="defaultVat == 'DefaultVatHead' || defaultVat == 'DefaultVatHeadItem'">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.TaxMethod') }} :<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <multiselect :options="options" v-bind:disabled="purchase.purchaseItems.length > 0"
                                         v-model="purchase.taxMethod" :show-labels="false"
                                         v-bind:placeholder="$t('SelectMethod')">
                            </multiselect>
                        </div>
                    </div>

                    <div class="row form-group"
                         v-if="defaultVat == 'DefaultVatHead' || defaultVat == 'DefaultVatHeadItem'">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.VAT%') }} :<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <taxratedropdown v-model="purchase.taxRateId" v-bind:value="purchase.taxRateId"
                                             :isDisable="purchase.purchaseItems.length > 0 ? true : false" v-bind:key="rander" />
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{$t('AddPurchase.DiscountType') }} :<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <multiselect :options="['At Transaction Level', 'At Line Item Level']"
                                         v-bind:disabled="purchase.purchaseItems.length > 0" v-model="discountTypeOption"
                                         @select="purchase.isDiscountOnTransaction = (discountTypeOption === 'At Transaction Level' ? false : true)"
                                         :show-labels="false" v-bind:placeholder="$t('AddStockValue.SelectMethod')">
                            </multiselect>
                        </div>
                    </div>
                    <div class="row form-group" v-if="raw == 'true'">
                        <label class="col-form-label col-lg-4">
                        </label>
                        <div class="inline-fields col-lg-8">
                            <div class="checkbox form-check-inline mx-2">
                                <input type="checkbox" id="inlineCheckbox1" v-model="purchase.isRaw"
                                       @change="ChangeSupplier">
                                <label for="inlineCheckbox1">
                                    {{
 $t('AddPurchase.RawProduct')
                                    }}
                                </label>
                            </div>

                        </div>
                    </div>
                </div>

                <purchase-item @input="SavePurchaseItems" ref="childComponentRef" @summary="updateSummary"
                               :purchaseItems="purchase.purchaseItems" :taxMethod="purchase.taxMethod"
                               :taxRateId="purchase.taxRateId" :raw="purchase.isRaw" :purchaseOrderId="purchase.purchaseOrderId"
                               :key="randerLineItem" @discountChanging="updateDiscountChanging" :adjustmentProp="purchase.discount"
                               :adjustmentSignProp="adjustmentSignProp" :isDiscountOnTransaction="purchase.isDiscountOnTransaction"
                               :transactionLevelDiscountProp="purchase.transactionLevelDiscount" :isFixed="purchase.isFixed"
                               :isBeforeTax="purchase.isBeforeTax" />

                <div class="col-lg-12 invoice-btn-fixed-bottom">
                    <div v-if="!loading && purchase.id == '00000000-0000-0000-0000-000000000000'"
                         class="col-md-12 arabicLanguage">

                        <button class="btn btn-outline-primary me-2" v-on:click="savePurchasePost(false)"
                                v-if="isValid('CanViewPurchaseDraft')"
                                v-bind:disabled="$v.$invalid || purchase.purchaseItems.filter(x => x.totalPiece == '').length > 0 || purchase.purchaseItems.filter(x => x.unitPrice == '').length > 0">
                            <i class="far fa-save"></i> {{ $t('AddPurchase.SaveAsDraft') }}
                        </button>
                        <button class="btn btn-outline-primary  me-2" v-on:click="savePurchasePost(true)"
                                v-if="isValid('CanAddPurchaseInvoice')"
                                v-bind:disabled="$v.$invalid || purchase.purchaseItems.filter(x => x.totalPiece == '').length > 0 || purchase.purchaseItems.filter(x => x.unitPrice == '').length > 0 || (isFifo ? (purchase.purchaseItems.filter(x => x.expiryDate == '').length > 0 || purchase.purchaseItems.filter(x => x.batchNo == '').length > 0) : false)">
                            <i class="far fa-save"></i> {{ $t('AddPurchase.SaveAndPost') }}
                        </button>
                        <button class="btn btn-danger me-2" v-on:click="goToPurchase">
                            {{ $t('AddPurchase.Cancel') }}
                        </button>
                    </div>
                    <div v-if="!loading && purchase.id != '00000000-0000-0000-0000-000000000000'"
                         class="col-md-12 arabicLanguage">

                        <button class="btn btn-outline-primary me-2" v-on:click="savePurchasePost(false)"
                                v-if="isValid('CanViewPurchaseDraft') && isValid('CanEditPurchaseInvoice')"
                                v-bind:disabled="$v.$invalid || purchase.purchaseItems.filter(x => x.totalPiece == '').length > 0 || purchase.purchaseItems.filter(x => x.unitPrice == '').length > 0">
                            <i class="far fa-save"></i> {{ $t('AddPurchase.UpdateAsDraft') }}
                        </button>
                        <button class="btn btn-outline-primary me-2" v-on:click="savePurchasePost(true)"
                                v-if="isValid('CanAddPurchaseInvoice') || isValid('CanEditPurchaseInvoice')"
                                v-bind:disabled="$v.$invalid || purchase.purchaseItems.filter(x => x.totalPiece == '').length > 0 || purchase.purchaseItems.filter(x => x.unitPrice == '').length > 0 || (isFifo ? (purchase.purchaseItems.filter(x => x.expiryDate == '').length > 0 || purchase.purchaseItems.filter(x => x.batchNo == '').length > 0) : false)">
                            <i class="far fa-save"></i> {{ $t('AddPurchase.UpdateAsPost') }}
                        </button>
                        <button class="btn btn-danger me-2" v-on:click="goToPurchase">
                            {{ $t('AddPurchase.Cancel') }}
                        </button>
                    </div>
                </div>

                <div class="col-lg-12 mt-4 mb-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-8" style="border-right: 1px solid #eee;">

                                </div>
                                <div class="col-lg-4" v-if="purchase.id == '00000000-0000-0000-0000-000000000000'">
                                    <div class="form-group ps-3" v-if="!loading">
                                        <div class="font-xs mb-1">{{ $t('AddPurchase.AttachFiles') }}</div>

                                        <button v-on:click="Attachment()" type="button"
                                                class="btn btn-light btn-square btn-outline-dashed mb-1">
                                            <i class="fas fa-cloud-upload-alt"></i> {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                        <div>
                                            <small class="text-muted">
                                                You can upload a maximum of 10 files, 5MB each
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4" v-if="purchase.id != '00000000-0000-0000-0000-000000000000'">
                                    <div class="form-group ps-3" v-if="!loading">
                                        <div class="font-xs mb-1">{{ $t('AddPurchase.AttachFiles') }}</div>

                                        <button v-on:click="Attachment()" type="button"
                                                class="btn btn-light btn-square btn-outline-dashed mb-1">
                                            <i class="fas fa-cloud-upload-alt"></i> {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                        <div>
                                            <small class="text-muted">
                                                {{ $t('AddPurchase.FileSize') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="accordion" role="tablist"
                                     v-if="internationalPurchase == 'true' && purchase.purchaseOrderId != null && purchase.purchaseOrderId != ''">
                                    <b-card no-body class="mb-1" v-if="isValid('CanViewPIAttachment')">
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                            <b-button block v-b-toggle.accordion-1 variant="primary">
                                                {{
                                                    $t('AddPurchase.Attachment')
                                                }}
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <import-attachment :purchase="purchase" :show="attachment"
                                                                   v-if="attachment" @close="attachmentSave" :document="'Purchase'" />
                                                <div>
                                                    <div class="row"
                                                         v-if="purchase.id != '00000000-0000-0000-0000-000000000000'">
                                                        <div class="col-md-12 text-right">
                                                            <a href="javascript:void(0)"
                                                               class="btn btn-outline-primary "
                                                               v-on:click="attachment = true"> {{ $t('AddPurchase.Upload') }}</a>
                                                        </div>
                                                    </div>
                                                    <div class=" table-responsive">
                                                        <table class="table ">
                                                            <thead class="m-0">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{{ $t('AddPurchase.Date') }} </th>
                                                                    <th v-if="isValid('CanDownloadPIAttachment')">
                                                                        {{
                                                                            $t('AddPurchase.Attachment')
                                                                        }}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(contact, index) in purchase.purchaseAttachments"
                                                                    v-bind:key="index">
                                                                    <td>
                                                                        {{ index + 1 }}
                                                                    </td>
                                                                    <th>{{ getDate(contact.date) }}</th>
                                                                    <td v-if="isValid('CanDownloadPIAttachment')">
                                                                        <button class="btn btn-primary  btn-icon mr-2"
                                                                                v-if="contact.path != ''"
                                                                                v-on:click="DownloadAttachment(contact.path)">
                                                                            <i class="fa fa-download"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                    <b-card no-body class="mb-1" v-if="isValid('CanViewPIActions')">
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                            <b-button block v-b-toggle.accordion-2 variant="primary">{{ $t('AddPurchase.Actions') }}</b-button>
                                        </b-card-header>
                                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <add-company-action :action="action" :show="show" v-if="show"
                                                                    @close="IsSave" :document="'Purchase'" />

                                                <div class="row">
                                                    <div class="col-md-12"
                                                         v-if="purchase.id != '00000000-0000-0000-0000-000000000000'">
                                                        <div class="col-sm-6 float-right">
                                                            <a href="javascript:void(0)"
                                                               class="btn btn-outline-primary  float-right"
                                                               v-on:click="show = true">{{ $t('AddPurchase.Action') }}</a>
                                                        </div>
                                                    </div>
                                                    <div class=" table-responsive">
                                                        <table class="table ">
                                                            <thead class="m-0">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>{{ $t('AddPurchase.Status') }}</th>
                                                                    <th>{{ $t('AddPurchase.Date') }} </th>
                                                                    <th>{{ $t('AddPurchase.Description/Reason') }}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(process, index) in purchase.actionProcess"
                                                                    v-bind:key="process.id">
                                                                    <td>
                                                                        {{ index + 1 }}
                                                                    </td>
                                                                    <th>
                                                                        <span class="badge badge-primary">
                                                                            {{
                                                                            process.processName
                                                                            }}
                                                                        </span>
                                                                    </th>
                                                                    <th>{{ getDate(process.date) }}</th>
                                                                    <th>{{ process.description }}</th>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                    <b-card no-body class="mb-1" v-if="isValid('CanViewPIPayments')">
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                            <b-button block v-b-toggle.accordion-3 variant="primary">{{ $t('AddPurchase.Payment') }}</b-button>
                                        </b-card-header>
                                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <div>
                                                    <div class=" table-responsive">
                                                        <table class="table ">
                                                            <thead class="m-0">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th style="width:20%;">
                                                                        {{ $t('AddPurchase.Date') }}
                                                                    </th>
                                                                    <th style="width:20%;">
                                                                        {{
                                                                            $t('AddPurchase.VoucherNumber')
                                                                        }}
                                                                    </th>
                                                                    <th class="text-right">
                                                                        {{
 $t('AddPurchase.Amount')
                                                                        }}
                                                                    </th>
                                                                    <th class="text-center">
                                                                        {{
                                                                            $t('AddPurchase.PaymentMode')
                                                                        }}
                                                                    </th>
                                                                    <th>{{ $t('AddPurchase.Description') }} </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(payment, index) in purchase.paymentVoucher"
                                                                    v-bind:key="index">
                                                                    <td>
                                                                        {{ index + 1 }}
                                                                    </td>
                                                                    <th>{{ getDate(payment.date) }}</th>
                                                                    <th>{{ payment.voucherNumber }}</th>
                                                                    <th class="text-right">
                                                                        {{ currency }}
                                                                        {{
                                                                                parseFloat(payment.amount).toFixed(3).slice(0,
                                                                                    -1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g,
                                                                                        "$1,")
                                                                        }}
                                                                    </th>
                                                                    <th class="text-center">
                                                                        <span v-if="payment.paymentMode == 0">{{ $t('AddPurchase.Cash') }}</span><span v-if="payment.paymentMode == 1">{{ $t('AddPurchase.Bank') }}</span>
                                                                    </th>
                                                                    <th>{{ payment.narration }}</th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                    <b-card no-body class="mb-1" v-if="isValid('CanViewPIExpenses')">
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                            <b-button block v-b-toggle.accordion-4 variant="primary">{{ $t('AddPurchase.Expense') }}</b-button>
                                        </b-card-header>
                                        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                                <div>
                                                    <div class=" table-responsive">
                                                        <table class="table ">
                                                            <thead class="m-0">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th style="width:20%;">
                                                                        {{ $t('AddPurchase.Date') }}
                                                                    </th>
                                                                    <th style="width:20%;">
                                                                        {{
                                                                            $t('AddPurchase.VoucherNumber')
                                                                        }}
                                                                    </th>
                                                                    <th class="text-center">
                                                                        {{
 $t('AddPurchase.Amount')
                                                                        }}
                                                                    </th>
                                                                    <th class="text-center">
                                                                        {{
                                                                            $t('AddPurchase.PaymentMode')
                                                                        }}
                                                                    </th>
                                                                    <th>{{ $t('AddPurchase.Description') }} </th>
                                                                    <th> </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(payment, index) in purchase.purchasePostExpense"
                                                                    v-bind:key="index">
                                                                    <td>
                                                                        {{ index + 1 }}
                                                                    </td>
                                                                    <th>{{ getDate(payment.date) }}</th>
                                                                    <th>{{ payment.voucherNumber }}</th>
                                                                    <th>
                                                                        <decimal-to-fixed v-model="payment.amount" />

                                                                        <!--{{currency}} {{parseFloat(payment.amount).toFixed(3).slice(0,-1).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}-->
                                                                    </th>
                                                                    <th class="text-center">
                                                                        <span v-if="payment.paymentMode == 0">

                                                                            {{ $t('AddPurchase.Cash') }}
                                                                        </span><span v-if="payment.paymentMode == 1">

                                                                            {{ $t('AddPurchase.Bank') }}
                                                                        </span>
                                                                    </th>

                                                                    <th>{{ payment.narration }}</th>
                                                                    <th>
                                                                        <a href="javascript:void(0)" title="Remove"
                                                                           class="btn  btn-icon btn-danger btn-sm"
                                                                           v-on:click="removeExpense(payment.id)"
                                                                           v-if="isValid('CanDeletePIExpenses')">
                                                                            <i class="fa fa-times"></i>
                                                                        </a>
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>

                                <div class="card-footer col-md-3 text-left" v-if="loading">
                                    <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
                                </div>



                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <bulk-attachment :attachmentList="purchase.attachmentList" :show="isAttachshow" v-if="isAttachshow"
                         @close="attachmentSave" />
        <purchaseInvoice :printDetails="printDetails" v-if="printDetails.length != 0" v-bind:key="printRender">
        </purchaseInvoice>
    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from "vue-loading-overlay";
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required, requiredIf } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'
    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        name: "AddPurchase",
        components: {
            Loading,
            Multiselect
        },
        data: function () {
            return {
                discountTypeOption: 'At Line Item Level',
                adjustmentSignProp: '+',


                defaultVat: '',
                accountRender: 0,
                isFifo: false,
                CanSelectWarehouse: false,
                purchaseOrder: false,
                internationalPurchase: '',
                currency: '',
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    allowPreviousFinancialPeriod: false,
                    purchaseOrderId: "",
                    goodReceiveNoteId: "",
                    bankCashAccountId: "",
                    paymentType: "Cash",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    isCredit: false,
                    isPurchaseReturn: false,
                    invoiceDate: "",
                    purchaseOrder: "",
                    wareHouseId: "",
                    purchaseItems: [],
                    attachmentList: [],
                    isRaw: false,
                    isPurchasePost: false,
                    taxMethod: '',
                    taxRateId: "00000000-0000-0000-0000-000000000000",
                    actionProcess: [],
                    purchaseAttachments: [],
                    paymentVoucher: [],
                    purchasePostExpense: [],
                    partiallyPurchase: false,
                    autoPurchaseVoucher: false,
                    expenseToGst: false,
                    internationalPurchase: false,
                    colorVariants: false,
                    dueAmount: 0,

                    discount: 0,
                    isDiscountOnTransaction: false,
                    isFixed: false,
                    isBeforeTax: true,
                    transactionLevelDiscount: 0,

                    grossAmount: 0,
                    vatAmount: 0,
                    discountAmount: 0,
                    totalAmount: 0,
                },

                printId: '00000000-0000-0000-0000-000000000000',
                printDetails: [],
                options: [],
                paymentTypeOptions: [],
                loading: false,
                rander: 0,
                randerSupplier: 0,
                raw: '',
                printRender: 0,
                randerLineItem: 0,
                autoNumber: '',
                language: 'Nothing',
                supplierRender: 0,
                wareRander: 0,
                show: false,
                isAttachshow: false,
                attachment: false,
                action: {
                    id: '00000000-0000-0000-0000-000000000000',
                    purchaseOrderId: '',
                    processId: '',
                    date: '',
                    description: '',
                },
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},
                wareHouseId: {},
                purchaseItems: { required },
                bankCashAccountId: {
                    required: requiredIf((x) => {
                        if (x.isCredit)
                            return false;
                        return true;
                    })
                },
                paymentType: {
                    required: requiredIf((x) => {
                        if (x.isCredit)
                            return false;
                        return true;
                    })
                },
            },
        },
        methods: {
            updateSummary: function (summary) {
                this.purchase.grossAmount = summary.total;
                this.purchase.vatAmount = summary.vat;
                this.purchase.discountAmount = summary.discount;
                this.purchase.totalAmount = summary.withVat;

            },

            GotoPage: function (link) {
                this.$router.push({ path: link });
            },
            Attachment: function () {
                this.isAttachshow = true;
            },

            attachmentSave: function (attachment) {
                this.purchase.attachmentList = attachment;
                this.isAttachshow = false;
            },

            GetAccount: function () {
                this.accountRender++;
            },
            SelectPaymentMethod: function (value) {
                 

                this.purchase.isCredit = value;
                if (this.purchase.isCredit) {
                    this.purchase.bankCashAccountId = '';
                }
                else {
                    if (localStorage.getItem('CashAccountId') != null && localStorage.getItem('CashAccountId') != undefined && localStorage.getItem('CashAccountId') != '') {

                        this.purchase.bankCashAccountId = localStorage.getItem('CashAccountId');
                    }
                }
                this.rander++;
                this.randerSupplier++;
            },
            removeExpense: function (id) {
                this.purchase.purchasePostExpense = this.purchase.purchasePostExpense.filter((prod) => {
                    return prod.id != id;
                });
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },

            ChangeSupplier: function () {
                this.supplierRender++;
                this.randerLineItem++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: this.$t('AddPurchase.Error'),
                            text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            GetPoData: function (id, isEdit) {


                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                console.log(isEdit);
                var multi = localStorage.getItem('IsMultiUnit') == 'true' ? true : false
                root.$https.get('/Purchase/PurchaseOrderDetail?Id=' + id + '&isMultiUnit=' + multi, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {



                            if (root.internationalPurchase == 'true') {
                                root.purchase.actionProcess = response.data.actionProcess;
                                root.purchase.purchaseAttachments = response.data.purchaseAttachments;
                                root.purchase.paymentVoucher = response.data.paymentVoucher;
                                response.data.purchaseOrderExpenses.forEach(function (item) {
                                    if (item.amount - item.usedAmount > 0) {
                                        root.purchase.purchasePostExpense.push({
                                            id: item.id,
                                            date: item.date,
                                            bankCashAccountId: item.bankCashAccountId,
                                            contactAccountId: item.contactAccountId,
                                            vatAccountId: item.vatAccountId,
                                            taxRateId: item.taxRateId,
                                            taxMethod: item.taxMethod,
                                            voucherNumber: item.voucherNumber,
                                            narration: item.narration,
                                            chequeNumber: item.chequeNumber,
                                            amount: item.amount - item.usedAmount,
                                            paymentMode: item.paymentMode,
                                            paymentMethod: item.paymentMethod,
                                        });
                                    }
                                });
                            }

                            if (!isEdit) {
                                root.purchase.purchaseItems = [];
                                root.purchase.purchaseOrderId = response.data.id;
                                root.purchase.supplierId = response.data.supplierId;
                                root.purchase.taxMethod = response.data.taxMethod;
                                root.purchase.taxRateId = response.data.taxRateId;
                                root.purchase.isCredit = true;

                                response.data.purchaseOrderItems.forEach(function (item) {
                                    if (item.remainingQuantity > 0) {
                                        root.purchase.purchaseItems.push({
                                            rowId: item.id,
                                            id: item.id,
                                            description: item.description,
                                            isService: item.isService,
                                            batchNo: item.batchNo,
                                            discount: item.discount,
                                            expiryDate: item.expiryDate,
                                            isExpire: item.productId == null ? false : item.product.isExpire,
                                            fixDiscount: item.fixDiscount,
                                            product: item.product,
                                            basicUnit: item.unit == null ? '' : item.unit.name,
                                            productId: item.productId,
                                            purchaseId: item.purchaseId,
                                            quantity: item.quantity,
                                            highQty: item.highQty,
                                            unitPerPack: item.unitPerPack,
                                            taxMethod: item.taxMethod,
                                            taxRateId: item.taxRateId,
                                            serial: item.productId == null ? false : item.product.serial,
                                            serialNo: item.serialNo,
                                            guarantee: item.productId == null ? false : item.product.guarantee,
                                            guaranteeDate: item.guaranteeDate,
                                            unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        });
                                    }
                                });
                                root.rander++;
                                root.supplierRender++;
                                root.randerLineItem++;
                            }

                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },
            GetGRNData: function (id, isEdit) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                console.log(isEdit);
                var multi = localStorage.getItem('IsMultiUnit') == 'true' ? true : false
                root.$https.get('/Purchase/GoodReceiveDetail?Id=' + id + '&isMultiUnit=' + multi, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {


                            if (!isEdit) {
                                root.purchase.purchaseItems = [];
                                //root.purchase.purchaseOrderId = response.data.id;
                                root.purchase.supplierId = response.data.supplierId;
                                root.purchase.taxMethod = response.data.taxMethod;
                                root.purchase.taxRateId = response.data.taxRateId;
                                root.purchase.isCredit = true;

                                response.data.goodReceiveNoteItems.forEach(function (item) {
                                    {
                                        root.purchase.purchaseItems.push({
                                            rowId: item.id,
                                            id: item.id,
                                            batchNo: item.batchNo,
                                            discount: item.discount,
                                            expiryDate: item.expiryDate,
                                            isExpire: item.product.isExpire,
                                            fixDiscount: item.fixDiscount,
                                            product: item.product,
                                            basicUnit: item.unit == null ? '' : item.unit.name,
                                            productId: item.productId,
                                            purchaseId: item.purchaseId,
                                            quantity: item.remainingQuantity,
                                            highQty: item.highQty,
                                            unitPerPack: item.unitPerPack,
                                            taxMethod: item.taxMethod,
                                            taxRateId: item.taxRateId,
                                            serial: item.product.serial,
                                            serialNo: item.serialNo,
                                            guarantee: item.product.guarantee,
                                            guaranteeDate: item.guaranteeDate,
                                            unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        });
                                    }
                                });
                                root.rander++;
                                root.randerLineItem++;
                            }

                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },
            AutoIncrementCode: function () {

                //eslint-disable-line
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                var terminalId = '';

                if (localStorage.getItem('TerminalId') != null && localStorage.getItem('TerminalId') != undefined && localStorage.getItem('TerminalId') != "null" && localStorage.getItem('TerminalId') != 'null') {
                    terminalId = localStorage.getItem('TerminalId');
                }

                root.$https
                    .get("/Purchase/PurchaseAutoGenerateNo?terminalId=" + terminalId + '&invoicePrefix=' + localStorage.getItem('InvoicePrefix') + '&userID=' + localStorage.getItem('UserID'), {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            if (root.purchase.id == '00000000-0000-0000-0000-000000000000') {
                                root.purchase.registrationNo = response.data.post;
                                root.autoNumber = response.data.draft;
                            }
                            else {
                                root.autoNumber = response.data.post;
                            }
                            //    root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (purchaseItems, discount, adjustmentSignProp, transactionLevelDiscount) {
                this.purchase.purchaseItems = purchaseItems;

                this.purchase.discount = (discount == '' || discount == null) ? 0 : (adjustmentSignProp == '+' ? parseFloat(discount) : (-1) * parseFloat(discount))

                this.purchase.transactionLevelDiscount = (transactionLevelDiscount == '' || transactionLevelDiscount == null) ? 0 : parseFloat(transactionLevelDiscount)
                this.getTotalAmount();
            },

            updateDiscountChanging: function (isFixed, isBeforeTax) {
                this.purchase.isFixed = isFixed
                this.purchase.isBeforeTax = isBeforeTax
            },

            getTotalAmount: function () {
                this.purchase.dueAmount = this.$refs.childComponentRef.getTotalAmount();
            },

            savePurchasePost: function (invoiceType) {

                this.loading = true;
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                if (this.purchase.invoiceDate == "Invalid date") {
                    this.purchase.invoiceDate = "";
                }
                if (invoiceType == false) {
                    localStorage.setItem('active', 'Draft');

                }
                if (invoiceType == true) {
                    localStorage.setItem('active', 'post');

                }
                if (this.purchase.taxMethod == "غير شامل") {
                    this.purchase.taxMethod = "Exclusive";
                }
                if (this.purchase.taxMethod == "شامل") {
                    this.purchase.taxMethod = "Inclusive";
                }

                this.purchase.allowPreviousFinancialPeriod = localStorage.getItem('AllowPreviousFinancialPeriod') == 'true' ? true : false;
                // if (this.purchase.allowPreviousFinancialPeriod) {
                //     this.purchase.date = this.purchase.date + " " + moment().format("hh:mm A");

                // }

                var purchasePost = {
                    id: this.purchase.id,
                    date: this.purchase.date,
                    allowPreviousFinancialPeriod: this.purchase.allowPreviousFinancialPeriod,

                    registrationNo: this.purchase.registrationNo,
                    supplierId: this.purchase.supplierId,
                    invoiceNo: this.purchase.invoiceNo,
                    invoiceDate: this.purchase.invoiceDate,
                    purchaseOrder: this.purchase.purchaseOrder,
                    wareHouseId: this.purchase.wareHouseId,
                    purchaseOrderId: this.purchase.purchaseOrderId,
                    goodReceiveNoteId: this.purchase.goodReceiveNoteId,
                    taxRateId: this.purchase.taxRateId,
                    taxMethod: this.purchase.taxMethod,
                    isRaw: this.purchase.isRaw,
                    purchasePostItems: this.purchase.purchaseItems,
                    actionProcess: this.purchase.actionProcess,
                    purchaseAttachments: this.purchase.purchaseAttachments,
                    paymentVoucher: this.purchase.paymentVoucher,
                    purchasePostExpense: this.purchase.purchasePostExpense,
                    isPurchasePost: invoiceType,
                    partiallyPurchase: this.purchase.partiallyPurchase,
                    autoPurchaseVoucher: this.purchase.autoPurchaseVoucher,
                    expenseToGst: localStorage.getItem('ExpenseToGst') == 'true' ? true : false,
                    internationalPurchase: this.internationalPurchase == 'true' ? true : false,
                    dueAmount: this.purchase.dueAmount,
                    isCredit: this.purchase.isCredit,
                    attachmentList: this.purchase.attachmentList,
                    bankCashAccountId: this.purchase.isCredit ? '' : this.purchase.bankCashAccountId,
                    paymentType: this.purchase.isCredit ? '' : this.purchase.paymentType,
                    colorVariants: localStorage.getItem('ColorVariants') == 'true' ? true : false,

                    discount: this.purchase.discount,
                    transactionLevelDiscount: this.purchase.transactionLevelDiscount,
                    isDiscountOnTransaction: this.purchase.isDiscountOnTransaction,
                    isFixed: this.purchase.isFixed,
                    isBeforeTax: this.purchase.isBeforeTax,

                    totalAmount: this.purchase.totalAmount,
                    vatAmount: this.purchase.vatAmount,
                    discountAmount: this.purchase.discountAmount,
                    grossAmount: this.purchase.grossAmount,
                };

                purchasePost.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.$https
                    .post("/PurchasePost/SavePurchasePostInformation", purchasePost, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {

                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: this.$t('AddPurchase.Saved'),
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            })
                            if (root.isValid('CanViewPurchaseDraft') || root.isValid('CanViewPurchasePost')) {
                                root.$router.push({
                                    path: '/purchase',
                                    query: {
                                        data: 'Addpurchase'
                                    }
                                })
                            }
                            else {
                                root.$router.go();
                            }

                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: this.$t('AddPurchase.Saved'),
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            if (root.isValid('CanViewPurchaseDraft') || root.isValid('CanViewPurchasePost')) {
                                root.$router.push({
                                    path: '/purchase',
                                    query: {
                                        data: 'Addpurchase'
                                    }
                                })
                            }
                            else {
                                root.$router.go();
                            }
                        }
                        else {
                            root.$swal({
                                title: this.$t('AddPurchase.Error'),
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        root.$swal.fire({
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            text: error.response.data,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                        root.loading = false;
                    })
                    .finally(() => (root.loading = false));
            },
            savePurchasePostPrint: function (isPurchasePost) {
                this.loading = true;
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }

                var purchasePost = {
                    id: this.purchase.id,
                    date: this.purchase.date,
                    registrationNo: this.purchase.registrationNo,
                    supplierId: this.purchase.supplierId,
                    invoiceNo: this.purchase.invoiceNo,
                    invoiceDate: this.purchase.invoiceDate,
                    purchaseOrder: this.purchase.purchaseOrder,
                    wareHouseId: this.purchase.wareHouseId,
                    purchasePostItems: this.purchase.purchaseItems,
                    isPurchasePost: isPurchasePost,
                };
                purchasePost.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.$https
                    .post("/PurchasePost/SavePurchasePostInformation", purchasePost, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {
                        root.loading = false;
                        root.$swal.fire({
                            icon: "success",
                            title: "Saved Successfully",
                            showConfirmButton: false,

                            timer: 800,
                            timerProgressBar: true,
                        });
                        root.printId = response.data.id;
                    }).then(function () {
                        root.$https
                            .get("/PurchasePost/PurchasePostDetail?Id=" + root.printId, {
                                headers: { Authorization: `Bearer ${token}` },
                            })
                            .then(function (response) {
                                if (response.data != null) {

                                    root.printDetails = response.data;
                                    root.printRender++;
                                }
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        root.$swal.fire({
                            icon: "error",
                            title: "Something Went Wrong!",
                            text: error,
                        });

                        root.loading = false;
                    })
                    .finally(() => (root.loading = false));
            },
            savePurchase: function () {

                this.loading = true;
                var root = this;
                var token = "";
                if (this.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                this.purchase.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.$https.post("/Purchase/SavePurchaseInformation", root.purchase, { headers: { Authorization: `Bearer ${token}` }, })
                    .then((response) => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            root.$router.go('addpurchase');
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });

                            if (root.isValid('CanViewPurchaseDraft') || root.isValid('CanViewPurchasePost')) {
                                root.$router.push({
                                    path: '/purchase',
                                    query: {
                                        data: 'Addpurchase'
                                    }
                                })
                            }
                            else {
                                root.$router.go();
                            }

                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.loading = false
                            root.info = response.data.bpi

                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            });
                            if (root.isValid('CanViewPurchaseDraft') || root.isValid('CanViewPurchasePost')) {
                                root.$router.push({
                                    path: '/purchase',
                                    query: {
                                        data: 'Addpurchase'
                                    }
                                })
                            }
                            else {
                                root.$router.go();
                            }
                        }
                        else {
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                        root.$swal.fire({
                            icon: "error",
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            text: error,
                        });

                        root.loading = false;
                    })
                    .finally(() => (root.loading = false));
            },

            goToPurchase: function () {
                if (this.isValid('CanViewPurchaseDraft') || this.isValid('CanViewPurchasePost')) {
                    this.$router.push({
                        path: '/purchase',
                        query: {
                            data: 'Addpurchase'
                        }
                    })
                }
                else {
                    this.$router.go();
                }

            },
        },
        created: function () {

            this.$emit('input', this.$route.name);

            if (localStorage.getItem('IsPurchaseCredit') != 'true') {

                this.purchase.isCredit = false
            }
            else {
                this.purchase.isCredit = true
            }
            if (!this.purchase.isCredit) {
                if (localStorage.getItem('CashAccountId') != null && localStorage.getItem('CashAccountId') != undefined && localStorage.getItem('CashAccountId') != '' && localStorage.getItem('CashAccountId') != 'null' && localStorage.getItem('CashAccountId') != "null" && localStorage.getItem('CashAccountId') != "00000000-0000-0000-0000-000000000000" && localStorage.getItem('CashAccountId') != '00000000-0000-0000-0000-000000000000') {

                    this.purchase.bankCashAccountId = localStorage.getItem('CashAccountId');
                }
            }


            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
            this.CanSelectWarehouse = localStorage.getItem('CanSelectWarehouse') 
            this.internationalPurchase = localStorage.getItem('InternationalPurchase');
            this.purchase.partiallyPurchase = localStorage.getItem('PartiallyPurchase') == 'true' ? true : false;
            this.purchase.autoPurchaseVoucher = localStorage.getItem('AutoPurchaseVoucher') == 'true' ? true : false;
            this.purchase.expenseToGst = localStorage.getItem('ExpenseToGst') == 'true' ? true : false;
            this.defaultVat = localStorage.getItem('DefaultVat');
            //this.purchaseOrder = localStorage.getItem('PurchaseOrder') == 'true' ? true : false;

            if (this.$route.query.data != undefined) {
                var detail = this.$route.query.data;
                this.purchase.actionProcess = detail.actionProcess;
                this.purchase.date = detail.date;
                this.purchase.discountAmount = detail.discountAmount;
                this.purchase.id = detail.id;
                this.purchase.invoiceDate = detail.invoiceDate;
                this.purchase.invoiceFixDiscount = detail.invoiceFixDiscount;
                this.purchase.invoiceNo = detail.invoiceNo;
                this.purchase.isPurchasePost = detail.isPurchasePost;
                this.purchase.isPurchaseReturn = detail.isPurchaseReturn;
                this.purchase.isRaw = detail.isRaw;
                this.purchase.netAmount = detail.netAmount;
                this.purchase.purchaseInvoiceId = detail.purchaseInvoiceId;
                this.purchase.purchaseOrderId = detail.purchaseOrderId;
                this.purchase.goodReceiveNoteId = detail.goodReceiveNoteId;
                this.purchase.purchaseOrderNo = detail.purchaseOrderNo;
                this.purchase.purchaseItems = detail.purchasePostItems;
                this.purchase.registrationNo = detail.registrationNo;
                this.purchase.supplierId = detail.supplierId;
                this.purchase.taxMethod = detail.taxMethod;
                this.purchase.taxRateId = detail.taxRateId;
                this.purchase.wareHouseId = detail.wareHouseId;
                this.purchase.attachmentList = detail.attachmentList;

                this.purchase.bankCashAccountId = detail.bankCashAccountId;
                this.purchase.paymentType = detail.paymentType;
                this.purchase.isCredit = detail.isCredit;
                if (this.purchase.purchaseOrderId != null && this.internationalPurchase == 'true') {
                    this.GetPoData(this.purchase.purchaseOrderId, true);
                }

                this.purchase.discount = detail.discount;
                this.purchase.transactionLevelDiscount = detail.transactionLevelDiscount;
                this.purchase.isDiscountOnTransaction = detail.isDiscountOnTransaction;
                this.purchase.isFixed = detail.isFixed;
                this.purchase.isBeforeTax = detail.isBeforeTax;
                this.discountTypeOption = this.purchase.isDiscountOnTransaction ? 'At Transaction Level' : 'At Line Item Level'

                this.adjustmentSignProp = this.purchase.discount >= 0 ? '+' : '-'
                this.rander++;
            }
            else {
                this.purchase.wareHouseId = localStorage.getItem('WareHouseId');
                this.purchase.taxRateId = localStorage.getItem('TaxRateId');
                this.purchase.taxMethod = localStorage.getItem('taxMethod');
                this.discountTypeOption = this.purchase.isDiscountOnTransaction ? 'At Transaction Level' : 'At Line Item Level'

                this.adjustmentSignProp = this.purchase.discount >= 0 ? '+' : '-';
                this.purchase.date = moment().format("LLL");
            }

    },
    mounted: function () {
        this.language = this.$i18n.locale;
        this.CanSelectWarehouse = localStorage.getItem('CanSelectWarehouse') == 'true' ? true : false;

        this.purchase.allowPreviousFinancialPeriod = localStorage.getItem('AllowPreviousFinancialPeriod') == 'true' ? true : false;
        this.internationalPurchase = localStorage.getItem('InternationalPurchase');

            this.currency = localStorage.getItem('Currency');

            this.paymentTypeOptions = ['Cash', 'Bank'];

            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            if (this.$session.exists()) {

                this.raw = localStorage.getItem('IsProduction');
            }

            this.AutoIncrementCode();

        },
    };
</script>

