<template>
    <div class="row" v-if="isValid('CanViewGoodsReceiveasDraft') ||  isValid('CanAddGoodsReceiveasPost')|| isValid('CanEditGoodsReceiveasDraft')">
        <div class="col-md-12 ml-auto mr-auto">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 v-if="purchase.id === '00000000-0000-0000-0000-000000000000'" class="page-title">
                                    {{ $t('GoodReceive.AddGoodReceive') }}
                                </h4>
                                <h4 v-else class="page-title">{{ $t('GoodReceive.UpdateGoodReceive') }}</h4>
                            </div>
                            <div class="col-auto align-self-center">
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                   class="btn btn-sm btn-outline-danger">
                                    {{ $t('Sale.Close') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="hr-dashed hr-menu mt-0" />
            <div class="row">
                <div class="col-lg-6">
                    <div class="row form-group" v-bind:key="supplierRender">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddAutoPurchaseTemplate.Supplier') }} : <span class="text-danger">*</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8" :key="supplierRender">
                            <supplierdropdown v-model="$v.purchase.supplierId.$model" :disable="purchase.purchaseOrderId!=null && purchase.purchaseOrderId!=''" :paymentTerm="purchase.isCredit" v-bind:values="purchase.supplierId" :status="purchase.isRaw" :key="rander" />
                            <!--<a href="javascript:void(0);" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                               aria-controls="offcanvasRight" class="text-primary">View Supplier Details</a>
                            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
                                 aria-labelledby="offcanvasRightLabel">
                                <div class="offcanvas-header">
                                    <h5 id="offcanvasRightLabel" class="m-0">Supplier Details</h5>
                                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></button>
                                </div>
                                <div class="offcanvas-body">
                                    <div class="row">-->
                                        <!-- <div class="col-lg-12 form-group">
                                <label>{{ $t('AddSaleOrder.Mobile') }}  :</label>
                                <input type="text" class="form-control" v-model="purchase.mobile" />
                            </div>
                            <div class="col-lg-12 form-group">
                                <label>{{ $t('CustomerAddress') }}  :</label>
                                <textarea rows="3" v-model="purchase.customerAddress" class="form-control"> </textarea>
                            </div> -->
                                    <!--</div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span id="ember695" class="tooltip-container text-dashed-underline "> {{ $t('AddAutoPurchaseTemplate.RegistrationNo') }} <span class="text-danger">*</span></span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <input v-model="purchase.registrationNo" disabled class="form-control" type="text">
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">{{ $t('AddAutoPurchaseTemplate.Date') }} <span class="text-danger">*</span></span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <datepicker v-model="purchase.date" />
                        </div>
                    </div>


                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">{{ $t('AddPurchaseOrder.QuotationDate') }}:</span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <datepicker v-model="$v.purchase.invoiceDate.$model" />
                            <span v-if="$v.purchase.invoiceDate.$error"
                                  class="error text-danger">
                            </span>
                        </div>
                    </div>
                    <div class="row form-group" v-if="raw == 'true'">
                        <label class="col-form-label col-lg-4">
                        </label>
                        <div class="inline-fields col-lg-8">
                            <div class="checkbox form-check-inline mx-2">
                                <input type="checkbox" id="inlineCheckbox1" v-model="purchase.isRaw"
                                       @change="ChangeSupplier">
                                <label for="inlineCheckbox1"> {{ $t('AddPurchase.RawProduct') }} </label>
                            </div>

                        </div>
                    </div>
                    <!--<div class="row form-group"
             v-if="isValid('CreditPurchase') && (isValid('CanViewPostOrder') || isValid('CanAddPurchaseOrder'))">
            <label class="col-form-label col-lg-4">
                <span class="tooltip-container text-dashed-underline ">
                    {{ $t('AddPurchase.PurchaseOrder') }} :
                </span>
            </label>
            <div class="inline-fields col-lg-8">
                <purchase-order-dropdown v-on:input="GetPoData(purchase.purchaseOrderId, false)"
                                         v-model="purchase.purchaseOrderId" v-bind:values="purchase.purchaseOrderId" />
            </div>
        </div>-->
                    <!--<div class="row form-group" v-if="!purchase.isCredit">
            <label class="col-form-label col-lg-4">
                <span class="tooltip-container text-dashed-underline ">
                    {{ $t('AddPurchase.PaymentType') }} :<span class="text-danger"> *</span>
                </span>
            </label>
            <div class="inline-fields col-lg-8">
                <multiselect :options="paymentTypeOptions" @select="GetAccount()"
                             v-model="purchase.paymentType" :show-labels="false">
                </multiselect>
            </div>
        </div>-->

                </div>
                <div class="col-lg-6">
                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.PurchaseOrder') }}  :
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <purchase-order-dropdown v-on:input="GetPoData(purchase.purchaseOrderId,false)" v-model="purchase.purchaseOrderId" v-bind:values="purchase.purchaseOrderId" />
                        </div>
                    </div>

                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchaseOrder.SupplierQuotationNumber') }} :
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <input class="form-control" v-bind:disabled="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'"
                                   v-model="$v.purchase.invoiceNo.$model" />
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddPurchase.TaxMethod') }} :<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <multiselect :options="options" v-bind:disabled="purchase.goodReceiveNoteItems.length>0" v-model="purchase.taxMethod" :show-labels="false" v-bind:placeholder="$t('SelectMethod')">
                            </multiselect>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                            <span class="tooltip-container text-dashed-underline ">
                                {{ $t('AddAutoPurchaseTemplate.VAT') }} :<span class="text-danger"> *</span>
                            </span>
                        </label>
                        <div class="inline-fields col-lg-8">
                            <taxratedropdown v-model="purchase.taxRateId" :isDisable="purchase.goodReceiveNoteItems.length>0? true :false" />
                        </div>
                    </div>


                    <div class="row form-group">
                        <label class="col-form-label col-lg-4">
                        </label>
                        <div class="inline-fields col-lg-8">
                            <div class="checkbox form-check-inline mx-2">
                                <input type="checkbox" id="inlineCheckbox1" v-model="purchase.isActive"
                                       @change="ChangeSupplier">
                                <label for="inlineCheckbox1"> {{ $t('AddAutoPurchaseTemplate.Status') }} </label>
                            </div>

                        </div>
                    </div>
                </div>

                <GoodReceiveItem @input="SavePurchaseItems" ref="childComponentRef" :purchaseItems="purchase.goodReceiveNoteItems" :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" :raw="purchase.isRaw" :purchaseOrderId="purchase.purchaseOrderId" :key="randerLineItem" />


                <div class="col-lg-12 invoice-btn-fixed-bottom">
                    <div v-if="!loading && purchase.id == '00000000-0000-0000-0000-000000000000'"
                         class="col-md-12 arabicLanguage">

                        <button class="btn btn-outline-primary me-2"
                                v-on:click="savePurchase('Draft')"
                                v-if="isValid('CanViewGoodsReceiveasDraft')"
                                v-bind:disabled="$v.$invalid || purchase.goodReceiveNoteItems.filter(x => x.totalPiece=='').length > 0 || purchase.goodReceiveNoteItems.filter(x => x.unitPrice=='').length > 0">
                            <i class="far fa-save"></i>  {{ $t('AddPurchaseOrder.SaveAsDraft') }}
                        </button>
                        <button class="btn btn-outline-primary me-2"
                                v-on:click="savePurchase()"
                                v-if="isValid('CanAddGoodsReceiveasPost')"
                                v-bind:disabled="$v.$invalid || purchase.goodReceiveNoteItems.filter(x => x.totalPiece=='').length > 0 || purchase.goodReceiveNoteItems.filter(x => x.unitPrice=='').length > 0">
                            <i class="far fa-save"></i> {{ $t('AddPurchaseOrder.SaveAsPost') }}
                        </button>


                        <button class="btn btn-danger me-2" v-on:click="goToPurchase">
                            {{ $t('AddPurchase.Cancel') }}
                        </button>
                    </div>
                    <div v-if="!loading && purchase.id != '00000000-0000-0000-0000-000000000000'"
                         class="col-md-12 arabicLanguage">

                        <button class="btn btn-outline-primary me-2"
                                v-on:click="savePurchase()"
                                v-if="isValid('CanEditGoodsReceiveasDraft')"
                                v-bind:disabled="$v.$invalid || purchase.goodReceiveNoteItems.filter(x => x.totalPiece=='').length > 0 || purchase.goodReceiveNoteItems.filter(x => x.unitPrice=='').length > 0">
                            <i class="far fa-save"></i> {{ $t('AddPurchaseOrder.UpdateAsDraft') }}
                        </button>
                        <button class="btn btn-outline-primary me-2"
                                v-on:click="savePurchase()"
                                v-if="isValid('CanAddGoodsReceiveasPost')"
                                v-bind:disabled="$v.$invalid || purchase.goodReceiveNoteItems.filter(x => x.totalPiece=='').length > 0 || purchase.goodReceiveNoteItems.filter(x => x.unitPrice=='').length > 0">
                            <i class="far fa-save"></i>  {{ $t('AddPurchaseOrder.UpdateAsPost') }}
                        </button>

                        <button class="btn btn-danger me-2" v-on:click="goToPurchase">
                            {{ $t('AddPurchase.Cancel') }}
                        </button>
                    </div>
                </div>

                <div class="col-lg-12 mt-4 mb-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-8" style="border-right: 1px solid #eee;">
                                    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                        <label>{{ $t('AddPurchaseOrder.TermandCondition') }}:</label>
                                        <textarea class="form-control " rows="3" v-model="purchase.note" />
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group ps-3" v-if="!loading">
                                        <div class="font-xs mb-1"> {{ $t('AddAutoPurchaseTemplate.AttachFiles') }}</div>

                                        <button v-on:click="Attachment()" type="button"
                                                class="btn btn-light btn-square btn-outline-dashed mb-1">
                                            <i class="fas fa-cloud-upload-alt"></i> {{ $t('AddPurchase.Attachment') }}
                                        </button>

                                        <div>
                                            <small class="text-muted">
                                                {{ $t('AddAutoPurchaseTemplate.FileSize') }}
                                            </small>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <bulk-attachment :attachmentList="purchase.attachmentList" :show="isAttachshow" v-if="isAttachshow" @close="attachmentSave" />

                <loading :active.sync="loading" :can-cancel="true" :is-full-page="true"></loading>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>

</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    //import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    import Multiselect from 'vue-multiselect'
    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Multiselect,
        },
        data: function () {
            return {
                accountRender: 0,
                isFifo: false,
                purchaseOrder: false,
                internationalPurchase: '',
                currency: '',
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    purchaseOrderId: "",
                    bankCashAccountId: "",
                    paymentType: "Cash",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    isCredit: false,
                    isPurchaseReturn: false,
                    invoiceDate: "",
                    purchaseOrder: "",
                    wareHouseId: "",
                    goodReceiveNoteItems: [],
                    attachmentList: [],
                    isRaw: false,
                    isPurchasePost: false,
                    taxMethod: '',
                    taxRateId: "00000000-0000-0000-0000-000000000000",
                    actionProcess: [],
                    purchaseAttachments: [],
                    paymentVoucher: [],
                    purchasePostExpense: [],
                    partiallyPurchase: false,
                    autoPurchaseVoucher: false,
                    expenseToGst: false,
                    internationalPurchase: false,
                    dueAmount: 0,
                },

                printId: '00000000-0000-0000-0000-000000000000',
                printDetails: [],
                options: [],
                paymentTypeOptions: [],
                loading: false,
                rander: 0,
                raw: '',
                printRender: 0,
                randerLineItem: 0,
                autoNumber: '',
                language: 'Nothing',
                supplierRender: 0,
                wareRander: 0,
                show: false,
                isAttachshow: false,
                attachment: false,
                action: {
                    id: '00000000-0000-0000-0000-000000000000',
                    purchaseOrderId: '',
                    processId: '',
                    date: '',
                    description: '',
                },
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},

                goodReceiveNoteItems: { required },
            },
        },
        methods: {
            GetPoData: function (id, isEdit) {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                console.log(isEdit);
                var multi = localStorage.getItem('IsMultiUnit') == 'true' ? true : false
                root.$https.get('/Purchase/PurchaseOrderDetail?Id=' + id + '&isMultiUnit=' + multi, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            
                            if (!isEdit) {
                                root.purchase.goodReceiveNoteItems = [];
                                root.purchase.purchaseOrderId = response.data.id;
                                root.purchase.supplierId = response.data.supplierId;
                                root.purchase.taxMethod = response.data.taxMethod;
                                root.purchase.taxRateId = response.data.taxRateId;
                                root.purchase.isCredit = true;

                                response.data.purchaseOrderItems.forEach(function (item) {
                                    if (item.remainingQuantity > 0) {
                                        root.purchase.goodReceiveNoteItems.push({
                                            rowId: item.id,
                                            id: item.id,
                                            batchNo: item.batchNo,
                                            discount: item.discount,
                                            expiryDate: item.expiryDate,
                                            isExpire: item.product.isExpire,
                                            fixDiscount: item.fixDiscount,
                                            product: item.product,
                                            basicUnit: item.unit == null ? '' : item.unit.name,
                                            productId: item.productId,
                                            purchaseId: item.purchaseId,
                                            quantity: item.quantity,
                                            poQuantity: item.quantity,
                                            highQty: item.highQty,
                                            unitPerPack: item.unitPerPack,
                                            taxMethod: item.taxMethod,
                                            taxRateId: item.taxRateId,
                                            serial: item.product.serial,
                                            serialNo: item.serialNo,
                                            guarantee: item.product.guarantee,
                                            guaranteeDate: item.guaranteeDate,
                                            unitPrice: item.unitPrice == 0 ? '0' : item.unitPrice,
                                        });
                                    }
                                });
                                root.rander++;
                                root.randerLineItem++;
                            }


                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            },

            Attachment: function () {
                this.isAttachshow = true;
            },

            attachmentSaved: function (attachment) {
                this.purchase.attachmentList = attachment;
                this.isAttachshow = false;
            },


            getTotalAmount: function () {
                this.totalAmount = this.$refs.childComponentRef.getTotalAmount();
            },
            getDate: function (date) {
                if (date == null || date == undefined) {
                    return "";
                }
                else {
                    return moment(date).format('LLL');
                }
            },
            ChangeSupplier: function () {
                this.supplierRender++;
                this.rander++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Contact/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },
            GotoPage: function (link) {
                this.$router.push({ path: link });
            },
            uploadImage() {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var file = null;

                file = this.$refs.imgupload1.files;

                var fileData = new FormData();
                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }
                root.$https.post('/Company/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.purchase.path = response.data;

                        }
                    },
                        function () {
                            this.loading = false;
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هناك خطأ ما!', 
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },

            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/Purchase/GoodReceiveAutoGenerateNo", {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.purchase.registrationNo = response.data;
                        }
                    });
            },
            SavePurchaseItems: function (goodReceiveNoteItems) {
                this.purchase.goodReceiveNoteItems = goodReceiveNoteItems;
                this.getTotalAmount();
            },

            savePurchase: function (status) {
                this.purchase.approvalStatus = status;
                localStorage.setItem('active', status);

                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.purchase.internationalPurchase = this.internationalPurchase == 'true' ? true : false;
                this.purchase.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.purchase.approvedBy = localStorage.getItem('LoginUserName');
                //root.purchase.date = root.purchase.date + " " + moment().format("hh:mm A");
                this.$https.post('/Purchase/SaveGoodReceiveInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : 'تم الحفظ!',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.go('AddGoodReceive');
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : 'تم الحفظ!',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push({
                                        path: '/GoodReceive',
                                        query: {
                                            data: 'goodReceives'
                                        }
                                    })
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                type: 'error',
                                icon: 'error',
                                title: root.$t('AddPurchaseOrder.Error'),
                                text: error.response.data,
                                confirmButtonClass: "btn btn-danger",
                                showConfirmButton: true,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)

            },
            IsSave: function () {
                this.show = false;
                this.GetProcessType();
            },
            attachmentSave: function () {
                this.isAttachshow = false;
                this.GetAttachment();
                this.GetProcessType();
                this.attachment = false;
                this.GetProcessType();
            },
            paymentSave: function () {
                this.payment = false;
                this.GetPaymentVoucher();
                this.GetProcessType();
            },
            expenseSave: function () {
                this.expense = false;
                this.GetExpenseVoucher();
                this.GetProcessType();
            },
            GetExpenseVoucher: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderExpensePaymentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.purchaseOrderExpenses = response.data;
                        }
                    });
            },
            GetPaymentVoucher: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderPaymentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.paymentVoucher = response.data;
                        }
                    });
            },
            GetAttachment: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderAttachmentList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.purchaseAttachments = response.data;
                        }
                    });
            },
            GetProcessType: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('Purchase/PurchaseOrderActionList?id=' + this.purchase.id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null && response.data != '') {
                            root.purchase.actionProcess = response.data;
                        }
                    });
            },
            goToPurchase: function () {
                
                if (this.isValid('CanViewGoodsReceiveasDraft') || this.isValid('CanViewGoodsReceiveasPost') ) {
                    this.$router.push({
                        path: '/GoodReceive',
                        query: {
                            data: 'goodReceives'
                        }
                    })
                }
                else {
                    this.$router.go();
                }
                
            },
        },
        created: function () {
            this.$emit('input', this.$route.name);
            this.purchase.date = moment().format("LLL");

            if (this.$route.query.data != undefined) {
                this.purchase = this.$route.query.data;
                this.action.purchaseOrderId = this.purchase.id;
                this.advanceAccountId = this.$route.query.data.advanceAccountId;

                this.rander++;
                this.rendered++;
            }
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            this.currency = localStorage.getItem('currency');
            this.internationalPurchase = localStorage.getItem('InternationalPurchase');
            //this.versionAllow = localStorage.getItem('VersionAllow');
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();

                this.daterander++;
            }
        },
    };
</script>
