import modules from "./modules";
import permissionType from "./permissiontype";

const permissions = Object.freeze({
    CanViewUserRole: {
        id: 1,
        permissionName: 'Can View User Role',
        key: 'd231dd15-f541-4e88-846d-4c43b35f0621',
        value: 'CanViewUserRole',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.UserRole.id,
        checked: false
    },
    CanAddUserRole: {
        id: 1,
        permissionName: 'Can Add User Role',
        key: 'ada776bc-a8cb-439b-9079-8d5cbf84d6cc',
        value: 'CanAddUserRole',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.UserRole.id,
        checked: false
    },
    CanEditUserRole: {
        id: 2,
        permissionName: 'Can Edit User Role',
        key: 'be331356-83a6-4bc5-ad5f-fd4118743191',
        value: 'CanEditUserRole',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.UserRole.id,
        checked: false
    },
    CanViewUserPermission: {
        id: 3,
        permissionName: 'Can View User Permission',
        key: 'a1470157-1993-42b8-84f9-57282a0b9f43',
        value: 'CanViewUserPermission',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.UserPermission.id,
        checked: false
    },
    CanAddUserPermission: {
        id: 3,
        permissionName: 'Can Add User Permission',
        key: '2ab9f95c-fdbe-4427-8fb9-dd2ec2208471',
        value: 'CanAddUserPermission',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.UserPermission.id,
        checked: false
    },
    CanEditUserPermission: {
        id: 4,
        permissionName: 'Can Edit User Permission',
        key: 'c5f69d36-b007-4364-b4b7-830e33f7ab24',
        value: 'CanEditUserPermission',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.UserPermission.id,
        checked: false
    },
    CanAddSignUpUser: {
        id: 5,
        permissionName: 'Can Add Sign Up User',
        key: '7e22f75e-3fbe-4434-a208-a211de4b46b6',
        value: 'CanAddSignUpUser',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.SignUp.id,
        checked: false
    },
    CanEditSignUpUser: {
        id: 6,
        permissionName: 'Can Edit Sign Up User',
        key: 'a971e19c-2f7a-4350-9b55-659597cd5180',
        value: 'CanEditSignUpUser',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.SignUp.id,
        checked: false
    },
    CanViewSignUpUser: {
        id: 7,
        permissionName: 'Can View Sign Up User',
        key: '8977389d-d635-4a3d-bd48-83ecf5e421e3',
        value: 'CanViewSignUpUser',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.SignUp.id,
        checked: false
    },
    AddLimitedUser: {
        id: 8,
        permissionName: 'Add Limited User',
        key: '3308c681-5883-4966-be64-e62587abc33a',
        value: 'AddLimitedUser',
        moduleId: modules.SettingsAndPermission.id,
        typeId: permissionType.SignUp.id,
        checked: false
    },

    //Sale Module

    //Customer/Client
    CanImportCustomer: {
        id: 9,
        permissionName: 'Can Import Customer',
        key: '919a8786-2793-4845-b2bc-6e9f269ac5d5',
        value: 'CanImportCustomer',
        moduleId: modules.Sales.id,
        typeId: permissionType.Customer.id,
        checked: false
    },
    CanAddCustomer: {
        id: 10,
        permissionName: 'Can Add Customer',
        key: '99607afa-6270-4f84-8ee8-15f909fc272c',
        value: 'CanAddCustomer',
        moduleId: modules.Sales.id,
        typeId: permissionType.Customer.id,
        checked: false
    },
    CanViewCustomer: {
        id: 11,
        permissionName: 'Can View Customer',
        key: 'ac0bbe8e-f4df-44c7-bff6-7e0b7c596bd0',
        value: 'CanViewCustomer',
        moduleId: modules.Sales.id,
        typeId: permissionType.Customer.id,
        checked: false
    },
    CanEditCustomer: {
        id: 12,
        permissionName: 'Can Edit Customer',
        key: '7bb3407f-78b5-4122-bd43-a9e014879f43',
        value: 'CanEditCustomer',
        moduleId: modules.Sales.id,
        typeId: permissionType.Customer.id,
        checked: false
    },
    AddLimitedCustomer: {
        id: 13,
        permissionName: 'Add Limited Customer',
        key: '7dc50e60-d5a2-419a-b12a-200ac71d7cb6',
        value: '0',
        moduleId: modules.Sales.id,
        typeId: permissionType.Customer.id,
        checked: false
    },
    CustomerDplicateName: {
        id: 13,
        permissionName: 'Customer Dplicate Name',
        key: 'ea2e46e6-00b8-4786-befe-d087a93c8547',
        value: 'CustomerDplicateName',
        moduleId: modules.Sales.id,
        typeId: permissionType.Customer.id,
        checked: false
    },
    /*CanViewSaleRecord: {
        id: 14,
        permissionName: 'Can View Sale Record',
        key: '4c4084b6-7231-48a1-902f-66422fecb2c6',
        value: 'CanViewSaleRecord',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },*/


    // Sale Record
    CanViewHoldInvoices: {
        id: 15,
        permissionName: 'Can View Hold Invoices',
        key: 'fcfe0cb6-2b5c-4f0e-80e3-c2a917afb569',
        value: 'CanViewHoldInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanViewPaidInvoices: {
        id: 16,
        permissionName: 'Can View Paid Invoices',
        key: '01a3f6b1-1753-43a4-bcd1-adb7ce12a763',
        value: 'CanViewPaidInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanViewCreditInvoices: {
        id: 17,
        permissionName: 'Can View Credit Invoices',
        key: 'e3c6fa03-6006-46bb-afa0-e58cfc3400b0',
        value: 'CanViewCreditInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanDuplicateInvoices: {
        id: 18,
        permissionName: 'Can Duplicate Invoices',
        key: '97b4c7fc-4d97-4be9-981f-6472284e033d',
        value: 'CanDuplicateInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanA4Print: {
        id: 19,
        permissionName: 'Can A4 Print',
        key: '49178d4e-854d-410f-a3f1-e76d6b5cdf2f',
        value: 'CanA4Print',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanPosPrint: {
        id: 20,
        permissionName: 'Can Pos Print',
        key: '74a6245e-617f-4d5c-a733-91ba1533928f',
        value: 'CanPosPrint',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanDeleteHoldInvoices: {
        id: 21,
        permissionName: 'Can Delete Hold Invoices',
        key: 'e6e567fc-22b5-408b-87e9-375af125b2db',
        value: 'CanDeleteHoldInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanEditHoldInvoices: {
        id: 22,
        permissionName: 'Can Edit Hold Invoices',
        key: 'e5bb731b-e1c1-4e0e-9c50-c2bd9c29e6be',
        value: 'CanEditHoldInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanViewInvoiceDetail: {
        id: 23,
        permissionName: 'Can View Invoice Detail',
        key: 'c4abec65-3ed5-43b9-8669-2060d49017dc',
        value: 'CanViewInvoiceDetail',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanGenerateSaleRecordPdf: {
        id: 24,
        permissionName: 'Can Generate Sale Record Pdf',
        key: '504a9536-04c3-4529-b264-e231d3d56bbf',
        value: 'CanGenerateSaleRecordPdf',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanGenerateSaleRecordSheet: {
        id: 25,
        permissionName: 'Can Generate SaleRecord Sheet',
        key: 'b23ca28a-ee46-4b94-9499-0d03aaebeafc',
        value: 'CanGenerateSaleRecordSheet',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CanViewDueReceivePay: {
        id: 25,
        permissionName: 'Can View Due Receive Pay',
        key: '7ab30f0f-1752-4d92-9da1-8937d9890fbd',
        value: 'CanViewDueReceivePay',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false
    },
    CreditPaymentOnCash :{
        id: 25,
        permissionName: "Credit Payment On Cash",
        key: "9d8cc86e-95f4-442e-867e-b6c133907ea9",
        value: "CreditPaymentOnCash",
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false,
      },
      SaleHoldSetup :{
        id: 25,
        permissionName: "Sale Hold Setup",
        key: "142fa5e6-0090-4fd5-b008-3244c6f38a3d",
        value: "SaleHoldSetup",
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleRecord.id,
        checked: false,
      },

    //Add Proforma Invoice:
    CanViewProforma: {
        id: 26,
        permissionName: 'Can View Proforma',
        key: '297a4beb-09e8-4350-a995-e8dada6b87f7',
        value: 'CanViewProforma',
        moduleId: modules.Sales.id,
        typeId: permissionType.ProformaInvoice.id,
        checked: false
    },

    CanAddProforma: {
        id: 26,
        permissionName: 'Can Add Proforma',
        key: 'b4c4d8e4-444e-47e1-bea9-a340ef890cad',
        value: 'CanAddProforma',
        moduleId: modules.Sales.id,
        typeId: permissionType.ProformaInvoice.id,
        checked: false
    },

    CanEditProforma: {
        id: 26,
        permissionName: 'Can Edit Proforma',
        key: '50088bef-e950-4ccc-9a43-401f812b1d6c',
        value: 'CanEditProforma',
        moduleId: modules.Sales.id,
        typeId: permissionType.ProformaInvoice.id,
        checked: false
    },

    //Add Invoice:
    CashInvoices: {
        id: 26,
        permissionName: 'Cash Invoices',
        key: '7ba9c8b9-6dc2-4aef-b9d2-1db833b65596',
        value: 'CashInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },

    CashInvoicesForWalkIn: {
        id: 26,
        permissionName: 'Cash Invoices For Walk In',
        key: '1d79b378-4b1a-4d52-a822-be08c1fa15b2',
        value: 'CashInvoicesForWalkIn',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },

    CashInvoicesForCustomer: {
        id: 26,
        permissionName: 'Cash Invoices For Customer',
        key: '2e44f360-d2d3-4a64-8908-60e1a11438d0',
        value: 'CashInvoicesForCustomer',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },

    CreditInvoices: {
        id: 26,
        permissionName: 'Credit Invoices',
        key: '478724c1-f17d-444a-868c-b8f64f17d899',
        value: 'CreditInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanSelectTransporter: {
        id: 26,
        permissionName: 'Can Select Transporter',
        key: '6e9cab4a-e383-44f7-a101-9b48d35a3a2d',
        value: 'CanSelectTransporter',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },

    CanSelectArea: {
        id: 26,
        permissionName: 'Can Select Area',
        key: '0000111b-6ba5-4f07-a360-35cddc87f6b2',
        value: 'CanSelectArea',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },

    CanSelectDispatchNote: {
        id: 26,
        permissionName: 'Can Select Dispatch Note',
        key: '2bb26b7a-276f-46f4-bd3d-10a622327c29',
        value: 'CanSelectDispatchNote',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },

    CanViewCurrentQuantity: {
        id: 26,
        permissionName: 'Can View Current Quantity',
        key: 'b2eb5923-ae13-4804-86e3-b88afd71cb20',
        value: 'CanViewCurrentQuantity',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },

    CanHoldInvoices: {
        id: 26,
        permissionName: 'Can Hold Invoices',
        key: '6809ca5c-61b2-40e4-9e87-553cf159a26f',
        value: 'CanHoldInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },

    CanAddQuickItem: {
        id: 26,
        permissionName: 'Can Add Quick Item',
        key: 'f2246c34-f4ae-48d4-8bba-165ff775220c',
        value: 'CanAddQuickItem',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },

    CanPrintInvoices: {
        id: 26,
        permissionName: 'Can Print Invoices',
        key: '67126f3f-dd60-4452-9221-2844a511de2d',
        value: 'CanPrintInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },


    CanAddAdvanceVoucher: {
        id: 26,
        permissionName: 'Can Add Advance Voucher',
        key: '2f250f9c-1935-4333-aedf-4f89445d2a51',
        value: 'CanAddAdvanceVoucher',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanPayInCredit: {
        id: 26,
        permissionName: 'Can Pay In Credit',
        key: '0f736712-58bd-46aa-9157-c14128a7833a',
        value: 'CanPayInCredit',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    SerialNumber: {
        id: 26,
        permissionName: 'Serial Number',
        key: '6a0502fa-50ca-4183-98b9-623e0c42d9e6',
        value: 'SerialNumber',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddMobile: {
        id: 26,
        permissionName: 'Can Add Mobile',
        key: '5496cb2d-2672-4d55-b8c9-5984bcc328f4',
        value: 'CanAddMobile',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddDueDate: {
        id: 26,
        permissionName: 'Can Add Due Date',
        key: 'ab64c02a-bcb5-4ab0-8786-226e512a388e',
        value: 'CanAddDueDate',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanSelectPriceType: {
        id: 26,
        permissionName: 'Can Select Price Type',
        key: 'b492ca22-214b-4c61-ba0b-d8ac5731e40e',
        value: 'CanSelectPriceType',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddDoctorType: {
        id: 26,
        permissionName: 'Can Add Doctor Type',
        key: 'c0b320ed-f5b1-4020-9965-88005f96ccaf',
        value: 'CanAddDoctorType',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddHospitalName: {
        id: 26,
        permissionName: 'Can Add Hospital Name',
        key: '469bac69-ce6e-4c44-a964-7f0a32db7dfd',
        value: 'CanAddHospitalName',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddPONumber: {
        id: 26,
        permissionName: 'Can Add PO Number',
        key: '068a17cd-7561-465b-ac86-383dde93fcca',
        value: 'CanAddPONumber',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddDeliverDate: {
        id: 26,
        permissionName: 'Can Add Deliver Date',
        key: '57ebc9af-b76b-4994-89c9-0f3c75cecbb9',
        value: 'CanAddDeliverDate',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddCustomerInquiry: {
        id: 26,
        permissionName: 'Can Add Customer Inquiry',
        key: '137c1cb5-2a31-4a01-9cb8-c99b1442e1ba',
        value: 'CanAddCustomerInquiry',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddDeliverAddress: {
        id: 26,
        permissionName: 'Can Add Deliver Address',
        key: '934f57a9-0075-441a-8646-93addc21e61b',
        value: 'CanAddDeliverAddress',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddDoctorName: {
        id: 26,
        permissionName: 'Can Add Doctor Name',
        key: '0ee9c3f9-e215-4ca5-9e25-6eaea42d92ee',
        value: 'CanAddDoctorName',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddBillingAddress: {
        id: 26,
        permissionName: 'Can Add Billing Address',
        key: 'eaeb0c28-f0b7-4b2e-a9ac-f8bcd3d3fed5',
        value: 'CanAddBillingAddress',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddInvoiceFor: {
        id: 26,
        permissionName: 'Can Add Invoice For',
        key: '688e129d-2d38-41b1-b0ce-36d1e0dd8873',
        value: 'CanAddInvoiceFor',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    CanAddSalePerson: {
        id: 26,
        permissionName: 'Can Add Sale Person',
        key: '07b4c853-48f1-4151-a93f-4176b28ed079',
        value: 'CanAddSalePerson',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    ReferredBy: {
        id: 26,
        permissionName: 'Referred By',
        key: 'ec2a4934-7676-4cbd-bf27-e1de693ef945',
        value: 'ReferredBy',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },
    UploadWarrentyLogo: {
        id: 26,
        permissionName: 'Upload Warrenty Logo',
        key: '3030c7d7-ecd7-42d3-a7bd-0e8e657174c2',
        value: 'UploadWarrentyLogo',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddInvoice.id,
        checked: false
    },


    //Touch Screen:
    CanSelectCustomer: {
        id: 26,
        permissionName: 'Can Select Customer',
        key: 'd40d54a4-9af6-4f21-a54a-633d4655463f',
        value: 'CanSelectCustomer',
        moduleId: modules.Sales.id,
        typeId: permissionType.TouchScreen.id,
        checked: false
    },

    CanAddCashVoucher: {
        id: 26,
        permissionName: 'Can Add Cash Voucher',
        key: '	0b6e659e-e2f3-4b9d-acdf-591faeea4917',
        value: 'CanAddCashVoucher',
        moduleId: modules.Sales.id,
        typeId: permissionType.TouchScreen.id,
        checked: false
    },

    CanPrintTouchInvoice: {
        id: 26,
        permissionName: 'Can Print Touch Invoice',
        key: '	04cc84e7-2522-4929-a986-e76f32115ed8',
        value: 'CanPrintTouchInvoice',
        moduleId: modules.Sales.id,
        typeId: permissionType.TouchScreen.id,
        checked: false
    },

    CanAddTouchQuickItem: {
        id: 26,
        permissionName: 'Can Add Touch Quick Item',
        key: '	3245f6e7-e677-4215-8336-2afae29cb262',
        value: 'CanAddTouchQuickItem',
        moduleId: modules.Sales.id,
        typeId: permissionType.TouchScreen.id,
        checked: false
    },

    CanHoldTouchInvoices: {
        id: 26,
        permissionName: 'Can Hold Touch Invoices',
        key: '	84b1e46a-cbd0-4dd2-8be3-aa30a4246744',
        value: 'CanHoldTouchInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.TouchScreen.id,
        checked: false
    },
    TouchInvoiceTemplate1: {
        id: 26,
        permissionName: 'Touch Invoice Template1',
        key: 'f6fb0300-a52c-481c-9d02-f7309acc8547',
        value: 'TouchInvoiceTemplate1',
        moduleId: modules.Sales.id,
        typeId: permissionType.TouchScreen.id,
        checked: false
    },
    TouchInvoiceTemplate2: {
        id: 26,
        permissionName: 'Touch Invoice Template2',
        key: 'a527a2e5-6293-418a-8b26-ea2cf6eeb36a',
        value: 'TouchInvoiceTemplate2',
        moduleId: modules.Sales.id,
        typeId: permissionType.TouchScreen.id,
        checked: false
    },
    TouchInvoiceTemplate3: {
        id: 26,
        permissionName: 'Touch Invoice Template3',
        key: '8356a70f-e97e-4fb9-9898-a97780180c36',
        value: 'TouchInvoiceTemplate3',
        moduleId: modules.Sales.id,
        typeId: permissionType.TouchScreen.id,
        checked: false
    },

    //Sale Return:

    CanAddSaleReturn: {
        id: 26,
        permissionName: 'Can Add Sale Return',
        key: '	4fbd5001-2a53-4fb6-bee9-fa7454250a37',
        value: 'CanAddSaleReturn',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleReturn.id,
        checked: false
    },

    CanViewSaleReturn: {
        id: 26,
        permissionName: 'Can View Sale Return',
        key: '	e31625ee-e4d3-48dc-b024-75aa69a6cbef',
        value: 'CanViewSaleReturn',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleReturn.id,
        checked: false
    },

    CanPrintInvoice: {
        id: 26,
        permissionName: 'Can Print Invoice',
        key: '6c70724f-682a-4181-80ec-d79ce0359be2',
        value: 'CanPrintInvoice',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleReturn.id,
        checked: false
    },

    CanViewDetailSaleReturn: {
        id: 26,
        permissionName: 'Can View Detail Sale Return',
        key: 'd95b78b8-77df-4aa0-9c6a-73d82421421b',
        value: 'CanViewDetailSaleReturn',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleReturn.id,
        checked: false
    },

    CanGenerateSaleReturnPdf: {
        id: 26,
        permissionName: 'Can Generate Sale Return Pdf',
        key: 'f6af517f-6e85-44ef-ac32-060968e84f75',
        value: 'CanGenerateSaleReturnPdf',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleReturn.id,
        checked: false
    },

    CanGenerateSaleReturnSheet: {
        id: 26,
        permissionName: 'Can Generate Sale Return Sheet',
        key: '	ef654198-2ac1-4b32-ad62-0ba18d088d75',
        value: 'CanGenerateSaleReturnSheet',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleReturn.id,
        checked: false
    },

    CanSendSaleReturnEmail: {
        id: 26,
        permissionName: 'Can Send Sale Return Email',
        key: '1ba5133c-c655-4420-88c0-a1977f184df8',
        value: 'CanSendSaleReturnEmail',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleReturn.id,
        checked: false
    },


    //Sale Order:
    CanViewSaleOrder: {
        id: 26,
        permissionName: 'Can View Sale Order',
        key: 'ebd9bbde-585b-4cab-91c5-179682eb12fc',
        value: 'CanViewSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },
    CanAddSaleOrder: {
        id: 26,
        permissionName: 'Can Add Sale Order',
        key: 'e6abfd21-ad05-4904-b02e-fd41698c7c5b',
        value: 'CanAddSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanCloseSaleOrder: {
        id: 26,
        permissionName: 'Can Close Sale Order',
        key: '5a22021a-bee7-4355-b170-8f0a49579fde',
        value: 'CanCloseSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanDraftSaleOrder: {
        id: 26,
        permissionName: 'Can Draft Sale Order',
        key: '0c5f41a8-4cd8-4b42-a08c-e1a48febb8ce',
        value: 'CanDraftSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanDuplicateSaleOrder: {
        id: 26,
        permissionName: 'Can Duplicate Sale Order',
        key: '	9fcc869d-df75-43c7-a780-2884fc1fa858',
        value: 'CanDuplicateSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanPayAdvanceFromView: {
        id: 26,
        permissionName: 'Can Pay Advance From View',
        key: '	270b273d-12c9-4e04-a9e3-734c55d005e5',
        value: 'CanPayAdvanceFromView',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanViewDetailSaleOrder: {
        id: 26,
        permissionName: 'Can View Detail Sale Order',
        key: '	8fe1c567-3862-4d2d-8cf2-05c065cf2434',
        value: 'CanViewDetailSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanPrintSaleOrder: {
        id: 26,
        permissionName: 'Can Print Sale Order',
        key: '	02219b70-8004-4d59-b765-08b014cb9e03',
        value: 'CanPrintSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanEditSaleOrder: {
        id: 26,
        permissionName: 'Can Edit Sale Order',
        key: '44ee8684-6231-4b1d-bdc7-bcd31db4086d',
        value: 'CanEditSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanGenerateSaleOrderPdf: {
        id: 26,
        permissionName: 'Can Generate Sale Order Pdf',
        key: '11dfac13-5796-45b5-a3e4-45d7d07c3516',
        value: 'CanGenerateSaleOrderPdf',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanGenerateSaleOrderSheet: {
        id: 26,
        permissionName: 'Can Generate Sale Order Sheet',
        key: 'f8050b10-9fcf-412e-afa4-921c8d2a0fc8',
        value: 'CanGenerateSaleOrderSheet',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    CanSendSaleOrderEmail: {
        id: 26,
        permissionName: 'Can Send Sale Order Email',
        key: '7935a4e3-8e21-4657-9f1c-729d68e7856d',
        value: 'CanSendSaleOrderEmail',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrder.id,
        checked: false
    },

    //Sale Order Tracking:
    CanViewSaleOrderTracking: {
        id: 26,
        permissionName: 'Can View Sale Order Tracking',
        key: '75bb1762-5fe5-45da-82ca-491a86536eaf',
        value: 'CanViewSaleOrderTracking',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },
    CanAddSaleOrderTracking: {
        id: 26,
        permissionName: 'Can Add Sale Order Tracking',
        key: '481b481c-6b33-4cb0-b2e0-1337f5d28c65',
        value: 'CanAddSaleOrderTracking',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanCloseSaleOrderTracking: {
        id: 26,
        permissionName: 'Can Close Sale Order Tracking',
        key: '51799c18-0218-40d0-bbb2-73bbaf634df8',
        value: 'CanCloseSaleOrderTracking',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanDraftSaleOrderTracking: {
        id: 26,
        permissionName: 'Can Draft Sale Order Tracking',
        key: 'edb23771-fb29-4dda-9697-ca0068515f67',
        value: 'CanDraftSaleOrderTracking',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanDuplicateSaleOrderTracking: {
        id: 26,
        permissionName: 'Can Duplicate Sale Order Tracking',
        key: 'a8c38a2f-eda6-4f05-8cbf-438d99476064',
        value: 'CanDuplicateSaleOrderTracking',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanPayAdvanceFromViewTracking: {
        id: 26,
        permissionName: 'Can Pay Advance From View Tracking',
        key: 'd0d4bad7-096d-404d-91a3-91b912fb81d7',
        value: 'CanPayAdvanceFromViewTracking',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanViewDetailSaleOrderTracking: {
        id: 26,
        permissionName: 'Can View Detail Sale Order Tracking',
        key: 'fbf822ea-6947-49a5-b09c-6f4d5a080369',
        value: 'CanViewDetailSaleOrderTracking',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanPrintSaleOrderTracking: {
        id: 26,
        permissionName: 'Can Print Sale Order Tracking',
        key: 'cee14c08-127e-4d64-94f7-48c1cfa94f98',
        value: 'CanPrintSaleOrderTracking',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanEditSaleOrderTracking: {
        id: 26,
        permissionName: 'Can Edit Sale Order Tracking',
        key: 'ec0a25a4-4b57-4c18-8452-0505a09e7d7e',
        value: 'CanEditSaleOrderTracking',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanGenerateSaleOrderTrackingPdf: {
        id: 26,
        permissionName: 'Can Generate Sale Order Tracking Pdf',
        key: 'cf045467-4603-4061-8c0b-bef98142a7fe',
        value: 'CanGenerateSaleOrderPdf',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanGenerateSaleOrderTrackingSheet: {
        id: 26,
        permissionName: 'Can Generate Sale Order Tracking Sheet',
        key: 'a32ece2a-6393-40a9-9ed1-0c7cb29bc86d',
        value: 'CanGenerateSaleOrderTrackingSheet',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },

    CanSendSaleOrderTrackingEmail: {
        id: 26,
        permissionName: 'Can Send Sale Order Tracking Email',
        key: '56e96476-aa74-40de-8e64-6cf7948116a2',
        value: 'CanSendSaleOrderTrackingEmail',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleOrderTracking.id,
        checked: false
    },
    
    //Quotation Template:

    CanAddQuotationTemplate: {
        id: 26,
        permissionName: 'Can Add Quotation Template',
        key: '648538b4-6a82-4874-9aa4-e95f8d0a69f0',
        value: 'CanAddQuotationTemplate',
        moduleId: modules.Sales.id,
        typeId: permissionType.QuotationTemplate.id,
        checked: false
    },
    CanViewQuotationTemplate: {
        id: 26,
        permissionName: 'Can View Quotation Template',
        key: '648539b4-6a82-4834-9aa4-e95f8d0b69f0',
        value: 'CanViewQuotationTemplate',
        moduleId: modules.Sales.id,
        typeId: permissionType.QuotationTemplate.id,
        checked: false
    },
    CanEditQuotationTemplate: {
        id: 26,
        permissionName: 'Can Edit Quotation Template',
        key: 'a92dbe2d-9599-46f8-9366-396804ff3366',
        value: 'CanEditQuotationTemplate',
        moduleId: modules.Sales.id,
        typeId: permissionType.QuotationTemplate.id,
        checked: false
    },

    //Quotation Service Template:

    CanAddQuotationServiceTemplate: {
        id: 26,
        permissionName: 'Can Add Quotation Service Template',
        key: '506c3e11-2893-41d4-9d05-11498e4d0eeb',
        value: 'CanAddServiceQuotationTemplate',
        moduleId: modules.Sales.id,
        typeId: permissionType.QuotationServiceTemplate.id,
        checked: false
    },
    CanViewQuotationServiceTemplate: {
        id: 26,
        permissionName: 'Can View Quotation Service Template',
        key: '1e191fe6-565b-44c6-a878-dc9310c69a0f',
        value: 'CanViewServiceQuotationTemplate',
        moduleId: modules.Sales.id,
        typeId: permissionType.QuotationServiceTemplate.id,
        checked: false
    },
    CanEditQuotationServiceTemplate: {
        id: 26,
        permissionName: 'Can Edit Quotation Service Template',
        key: 'ec19a7d2-0fd2-421e-ad3b-2c3da4034f1a',
        value: 'CanEditServiceQuotationTemplate',
        moduleId: modules.Sales.id,
        typeId: permissionType.QuotationServiceTemplate.id,
        checked: false
    },



    //Quotation:

    CanAddQuotation: {
        id: 26,
        permissionName: 'Can Add Quotation',
        key: '12cc6757-1e81-49a5-ac08-ae0b05d712f0',
        value: 'CanAddQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.Quotation.id,
        checked: false
    },

    CanViewQuotation: {
        id: 26,
        permissionName: 'Can View Quotation',
        key: '9fa18d11-0d38-4cf7-9526-db4304dd2c41',
        value: 'CanViewQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.Quotation.id,
        checked: false
    },

    CanViewQuotationDetail: {
        id: 26,
        permissionName: 'Can View Quotation Detail',
        key: '2e194051-17d9-4300-8a1e-5d8166535f43',
        value: 'CanViewQuotationDetail',
        moduleId: modules.Sales.id,
        typeId: permissionType.Quotation.id,
        checked: false
    },

    CanPrintQuotation: {
        id: 26,
        permissionName: 'Can Print Quotation',
        key: '66cac10b-daed-4630-a9f5-14a2d95e5cc5',
        value: 'CanPrintQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.Quotation.id,
        checked: false
    },

    CanEditQuotation: {
        id: 26,
        permissionName: 'Can Edit Quotation',
        key: '13d6cdba-a394-4ccc-9a62-a0db6c29f507',
        value: 'CanEditQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.Quotation.id,
        checked: false
    },

    CanDraftQuotation: {
        id: 26,
        permissionName: 'Can Draft Quotation',
        key: '1c188c2b-c9e6-4cab-9e33-2129dde65b5b',
        value: 'CanDraftQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.Quotation.id,
        checked: false
    },

    CanGenerateQuotationPdf: {
        id: 26,
        permissionName: 'Can Generate Quotation Pdf',
        key: '5f937747-2a25-4a08-bfb0-9290c1f3250a',
        value: 'CanGenerateQuotationPdf',
        moduleId: modules.Sales.id,
        typeId: permissionType.Quotation.id,
        checked: false
    },

    CanGenerateQuotationSheet: {
        id: 26,
        permissionName: 'Can Generate Quotation Sheet',
        key: '	bcd4a311-7853-47fd-b272-64d9b95633f4',
        value: 'CanGenerateQuotationSheet',
        moduleId: modules.Sales.id,
        typeId: permissionType.Quotation.id,
        checked: false
    },

    CanSendQuotationEmail: {
        id: 26,
        permissionName: 'Can Send Quotation Email',
        key: 'b52da5f1-d5fb-4321-a885-14d0a77b5ff6',
        value: 'CanSendQuotationEmail',
        moduleId: modules.Sales.id,
        typeId: permissionType.Quotation.id,
        checked: false
    },



    //Invoice Setting:
    CanChooseA4InvoiceType: {
        id: 26,
        permissionName: 'Can Choose A4 Invoice Type',
        key: '0fca1959-6276-4f0d-af0f-58d6b2c3844b',
        value: 'CanChooseA4InvoiceType',
        moduleId: modules.Sales.id,
        typeId: permissionType.InvoiceSetting.id,
        checked: false
    },

    CanChooseThermalInvoiceType: {
        id: 26,
        permissionName: 'Can Choose Thermal Invoice Type',
        key: 'c2a539c6-bd3f-43c2-a263-58627acaf67c',
        value: 'CanChooseThermalInvoiceType',
        moduleId: modules.Sales.id,
        typeId: permissionType.InvoiceSetting.id,
        checked: false
    },

    DefaultTemplate: {
        id: 26,
        permissionName: 'Default Template',
        key: '3bcb1c6c-f228-43b3-9656-792702ce8447',
        value: 'DefaultTemplate',
        moduleId: modules.Sales.id,
        typeId: permissionType.InvoiceSetting.id,
        checked: false
    },

    SATemplate1A4: {
        id: 26,
        permissionName: 'SA – Template 1 A4',
        key: '446d5feb-083b-4877-bcb8-986cafcbc818',
        value: 'SATemplate1A4',
        moduleId: modules.Sales.id,
        typeId: permissionType.InvoiceSetting.id,
        checked: false
    },

    SATemplate2A4: {
        id: 26,
        permissionName: 'SA – Template 2 A4',
        key: '93af833d-32f3-49c7-8bdd-788ce5cabd17',
        value: 'SATemplate2A4',
        moduleId: modules.Sales.id,
        typeId: permissionType.InvoiceSetting.id,
        checked: false
    },

    PKTemplate1Thermal: {
        id: 26,
        permissionName: 'PK – Template 1 Thermal',
        key: '1e4dd1df-5e84-40f5-b085-3f156f95821c',
        value: 'PKTemplate1Thermal',
        moduleId: modules.Sales.id,
        typeId: permissionType.InvoiceSetting.id,
        checked: false
    },

    PKTemplate2Thermal: {
        id: 26,
        permissionName: 'PK – Template 2 Thermal',
        key: 'c1691931-61ec-4700-b72b-8cf465a2802e',
        value: 'PKTemplate2Thermal',
        moduleId: modules.Sales.id,
        typeId: permissionType.InvoiceSetting.id,
        checked: false
    },

    SATemplate1Thermal: {
        id: 26,
        permissionName: 'SA – Template 1 Thermal',
        key: '79d46412-3f65-4a9d-b03d-cb5ed904d16b',
        value: 'SATemplate1Thermal',
        moduleId: modules.Sales.id,
        typeId: permissionType.InvoiceSetting.id,
        checked: false
    },

    SATemplate2Thermal: {
        id: 26,
        permissionName: 'SA – Template 2 Thermal',
        key: '390aac14-b0bf-4755-b187-904c71a65f94',
        value: 'SATemplate2Thermal',
        moduleId: modules.Sales.id,
        typeId: permissionType.InvoiceSetting.id,
        checked: false
    },

    //Customer Pay Receipt:

    CanViewCPR: {
        id: 26,
        permissionName: 'Can View CPR',
        key: '3d0fd1ef-f4b9-48b0-a3c7-4238c9732303',
        value: 'CanViewCPR',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomerPayReceipt.id,
        checked: false
    },

    CanAddCPR: {
        id: 26,
        permissionName: 'Can Add CPR',
        key: '680919fe-d890-4c0c-af47-a0d598ac56ef',
        value: 'CanAddCPR',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomerPayReceipt.id,
        checked: false
    },
    CanPrintCPR: {
        id: 26,
        permissionName: 'Can Print CPR',
        key: '0b78617f-e2a6-413d-9b20-9d99ea62a855',
        value: 'CanPrintCPR',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomerPayReceipt.id,
        checked: false
    },

    CanDraftCPR: {
        id: 26,
        permissionName: 'Can Draft CPR',
        key: '	5176341c-6fe5-4dfd-99cc-e6c76e40031d',
        value: 'CanDraftCPR',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomerPayReceipt.id,
        checked: false
    },

    CanEditCPR: {
        id: 26,
        permissionName: 'Can Edit CPR',
        key: 'c15632db-c8ec-4f68-8fd0-0fc563a3ef87',
        value: 'CanEditCPR',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomerPayReceipt.id,
        checked: false
    },

    CanRejectCPR: {
        id: 26,
        permissionName: 'Can Reject CPR',
        key: 'bca088ae-9f83-40b2-997c-90f6134ced41',
        value: 'CanRejectCPR',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomerPayReceipt.id,
        checked: false
    },

    CanViewDetailCPR: {
        id: 26,
        permissionName: 'Can View Detail CPR',
        key: '1a9eee81-245d-45f1-9c85-f766ea7c118e',
        value: 'CanViewDetailCPR',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomerPayReceipt.id,
        checked: false
    },
    CanAddCPRAttachment: {
        id: 26,
        permissionName: 'Can Add CPR Attachment',
        key: 'b5e009df-9a2e-4276-a49e-0684b531d37e',
        value: 'CanAddCPRAttachment',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomerPayReceipt.id,
        checked: false
    },

    // Sale Service Record
    // Sale Record
    CanViewHoldServiceInvoices: {
        id: 15,
        permissionName: 'Can View Hold Service Invoices',
        key: '1175b58f-ade2-4480-9e2b-a8d4404d589c',
        value: 'CanViewHoldServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanViewPaidServiceInvoices: {
        id: 16,
        permissionName: 'Can View Paid Service Invoices',
        key: '16f24799-dbd5-4592-b666-0991c682e3ac',
        value: 'CanViewPaidServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanViewCreditServiceInvoices: {
        id: 17,
        permissionName: 'Can View Credit Service Invoices',
        key: 'ad63d435-c4af-4aba-8598-361a77e1cc55',
        value: 'CanViewCreditServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanDuplicateServiceInvoices: {
        id: 18,
        permissionName: 'Can Duplicate Service Invoices',
        key: 'e0298329-afa6-406a-afd9-9f8a322456b1',
        value: 'CanDuplicateServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanA4ServicePrint: {
        id: 19,
        permissionName: 'Can A4 Service Print',
        key: '19a33ced-f69e-44ef-a90c-2b5295225f45',
        value: 'CanA4ServicePrint',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanPosServicePrint: {
        id: 20,
        permissionName: 'Can Pos Service Print',
        key: '0c7f3f6a-b80c-424d-b055-f760bd07c63f',
        value: 'CanPosServicePrint',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanDeleteHoldServiceInvoices: {
        id: 21,
        permissionName: 'Can Delete Hold Service Invoices',
        key: '13b1fe68-fd59-415a-8c31-f1046edccf7d',
        value: 'CanDeleteHoldServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanEditHoldServiceInvoices: {
        id: 22,
        permissionName: 'Can Edit Hold Service Invoices',
        key: '62542812-61c0-46fe-9618-7aa12964d752',
        value: 'CanEditHoldServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanViewServiceInvoiceDetail: {
        id: 23,
        permissionName: 'Can View Service Invoice Detail',
        key: 'f02a307c-82dd-4dcb-930c-290ca8c27fb8',
        value: 'CanViewServiceInvoiceDetail',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanGenerateSaleServicePdf: {
        id: 24,
        permissionName: 'Can Generate Sale Servic ePdf',
        key: '11867369-fa23-4554-b630-d6e8a67b82e9',
        value: 'CanGenerateSaleServicePdf',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanGenerateSaleServiceSheet: {
        id: 25,
        permissionName: 'Can Generate Sale Service Sheet',
        key: '1c3c5597-e4bc-4322-b1d1-23bcae1a753a',
        value: 'CanGenerateSaleServiceSheet',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },
    CanSendSaleServiceEmail: {
        id: 26,
        permissionName: 'Can Send Sale Service Email',
        key: '875cf62d-2330-4ae5-97a3-e0e443b240e1',
        value: 'CanSendSaleServiceEmail',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceRecord.id,
        checked: false
    },

    //Add Service Invoice:
    IsWarrantyCheck: {
        id: 26,
        permissionName: 'Is Warranty Check',
        key: '636f09f0-0a75-4748-835d-c6432fd837ff',
        value: 'IsWarrantyCheck',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },
    CashServiceInvoices: {
        id: 26,
        permissionName: 'Cash Service Invoices',
        key: 'f8be0a10-6157-4eff-8656-aa66713c204e',
        value: 'CashServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CashServiceInvoicesForWalkIn: {
        id: 26,
        permissionName: 'Cash Service Invoices For Walk In',
        key: '8e0f32f3-36d4-4ace-a585-c1074d5749f7',
        value: 'CashServiceInvoicesForWalkIn',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CashServiceInvoicesForCustomer: {
        id: 26,
        permissionName: 'Cash Service Invoices For Customer',
        key: '01f34f83-c861-40cc-b0fe-de0bb78e706c',
        value: 'CashServiceInvoicesForCustomer',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CreditServiceInvoices: {
        id: 26,
        permissionName: 'Credit Service Invoices',
        key: 'd98e3eec-6bef-4985-9abc-c2b0175e5129',
        value: 'CreditServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CanSelectServiceWarehouse: {
        id: 26,
        permissionName: 'Can Select Service Warehouse',
        key: '8459463c-9da9-4478-a191-3519e229c568',
        value: 'CanSelectServiceWarehouse',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CanSelectServiceTransporter: {
        id: 26,
        permissionName: 'Can Select Service Transporter',
        key: '296fdc8d-4123-42c3-af39-0fee8bf40d5f',
        value: 'CanSelectServiceTransporter',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CanSelectServiceArea: {
        id: 26,
        permissionName: 'Can Select Service Area',
        key: 'd1132022-405e-485b-9b03-7d1da35c5a31',
        value: 'CanSelectServiceArea',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CanSelectServiceDispatchNote: {
        id: 26,
        permissionName: 'Can Select Service Dispatch Note',
        key: 'b884d685-6ee7-4f35-80be-3070b3ce73bb',
        value: 'CanSelectServiceDispatchNote',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CanHoldServiceInvoices: {
        id: 26,
        permissionName: 'Can Hold Service Invoices',
        key: '36921cc9-1b59-4c41-86a9-c2c6bb28d9c6',
        value: 'CanHoldServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CanAddServiceQuickItem: {
        id: 26,
        permissionName: 'Can Add Service Quick Item',
        key: '742ace0c-b666-4458-8bf6-c468fd5df5b7',
        value: 'CanAddServiceQuickItem',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    CanPrintServiceInvoices: {
        id: 26,
        permissionName: 'Can Print Service Invoices',
        key: 'ddfea397-8708-4d4b-a042-cb67545d7393',
        value: 'CanPrintServiceInvoices',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },


    CanAddServiceAdvanceVoucher: {
        id: 26,
        permissionName: 'Can Add Service Advance Voucher',
        key: 'a0d92450-bbb0-4f71-bf33-014ab8ad871a',
        value: 'CanAddServiceAdvanceVoucher',
        moduleId: modules.Sales.id,
        typeId: permissionType.AddServiceInvoice.id,
        checked: false
    },

    //Service Sale Order
    CanViewServiceSaleOrder: {
        id: 26,
        permissionName: 'Can View Service Sale Order',
        key: '944db131-b71d-4c03-a4c9-aedd9546989b',
        value: 'CanViewServiceSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },
    CanAddServiceSaleOrder: {
        id: 26,
        permissionName: 'Can Add Service Sale Order',
        key: '61313e0a-d07d-4acf-bd48-f0f8f264bb4f',
        value: 'CanAddServiceSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanCloseServiceSaleOrder: {
        id: 26,
        permissionName: 'Can Close Service Sale Order',
        key: '6d87dc04-e069-4d3a-b965-53b82c1a0bd0',
        value: 'CanCloseServiceSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanDraftServiceSaleOrder: {
        id: 26,
        permissionName: 'Can Draft Service Sale Order',
        key: 'eedae269-e237-460f-907a-e66e879c87ae',
        value: 'CanDraftServiceSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanDuplicateServiceSaleOrder: {
        id: 26,
        permissionName: 'Can Duplicate Service Sale Order',
        key: '0080ea5c-c53f-46c7-8d4b-7e3d48f0c373',
        value: 'CanDuplicateServiceSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanServicePayAdvanceFromView: {
        id: 26,
        permissionName: 'Can Service Pay Advance From View',
        key: '46f574df-6e6a-422f-b164-c7be6350ccbc',
        value: 'CanServicePayAdvanceFromView',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanViewDetailServiceSaleOrder: {
        id: 26,
        permissionName: 'Can View Detail Service Sale Order',
        key: 'a2862860-ba1a-4209-a491-0510e33e4852',
        value: 'CanViewDetailServiceSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanPrintServiceSaleOrder: {
        id: 26,
        permissionName: 'Can Print Service Sale Order',
        key: '792a0c07-d100-4169-8feb-c7f166ca0393',
        value: 'CanPrintServiceSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanEditServiceSaleOrder: {
        id: 26,
        permissionName: 'Can Edit Service Sale Order',
        key: 'fb0490e7-9b9f-4648-aff0-b8d99748033f',
        value: 'CanEditServiceSaleOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanGenerateServiceSaleOrderPdf: {
        id: 26,
        permissionName: 'Can Generate Service Sale Order Pdf',
        key: '73fc90cb-a08f-4930-9f07-f6084693bf45',
        value: 'CanGenerateServiceSaleOrderPdf',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanGenerateServiceSaleOrderSheet: {
        id: 26,
        permissionName: 'Can Generate Service Sale Order Sheet',
        key: 'bfd47601-4309-4fee-be36-86bcf49dd730',
        value: 'CanGenerateServiceSaleOrderSheet',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    CanSendServiceSaleOrderEmail: {
        id: 26,
        permissionName: 'Can Send Service Sale Order Email',
        key: 'ec25376a-fbb7-47ca-a1a0-c2a1911b1ea6',
        value: 'CanSendServiceSaleOrderEmail',
        moduleId: modules.Sales.id,
        typeId: permissionType.SaleServiceOrder.id,
        checked: false
    },

    //Service Quotation:

    CanAddServiceQuotation: {
        id: 26,
        permissionName: 'Can Add Service Quotation',
        key: '2136c593-8233-4692-b6d5-7d40fc9c949f',
        value: 'CanAddServiceQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.ServiceQuotation.id,
        checked: false
    },

    CanViewServiceQuotation: {
        id: 26,
        permissionName: 'Can View Service Quotation',
        key: '7902cec2-45fc-40de-84e4-423e0ae3cafa',
        value: 'CanViewServiceQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.ServiceQuotation.id,
        checked: false
    },

    CanViewServiceQuotationDetail: {
        id: 26,
        permissionName: 'Can View Service Quotation Detail',
        key: 'f10a6a66-07fe-419f-a6f9-98a35337b443',
        value: 'CanViewServiceQuotationDetail',
        moduleId: modules.Sales.id,
        typeId: permissionType.ServiceQuotation.id,
        checked: false
    },

    CanPrintServiceQuotation: {
        id: 26,
        permissionName: 'Can Print Service Quotation',
        key: '5f5fb336-d88c-4e0f-b075-5c068b0c40a2',
        value: 'CanPrintServiceQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.ServiceQuotation.id,
        checked: false
    },

    CanEditServiceQuotation: {
        id: 26,
        permissionName: 'Can Edit Service Quotation',
        key: '50058856-5d41-43cc-8a72-bfa176df7811',
        value: 'CanEditServiceQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.ServiceQuotation.id,
        checked: false
    },

    CanDraftServiceQuotation: {
        id: 26,
        permissionName: 'Can Draft Service Quotation',
        key: 'b559bc13-413a-4272-adf9-6c936af5a5cf',
        value: 'CanDraftServiceQuotation',
        moduleId: modules.Sales.id,
        typeId: permissionType.ServiceQuotation.id,
        checked: false
    },

    CanGenerateServiceQuotationPdf: {
        id: 26,
        permissionName: 'Can Generate Service Quotation Pdf',
        key: 'a39aa68f-e2b7-4442-a9ac-855754dfe3ac',
        value: 'CanGenerateServiceQuotationPdf',
        moduleId: modules.Sales.id,
        typeId: permissionType.ServiceQuotation.id,
        checked: false
    },

    CanGenerateServiceQuotationSheet: {
        id: 26,
        permissionName: 'Can Generate Service Quotation Sheet',
        key: 'a91b87be-2c56-4ff8-914f-2628cca326da',
        value: 'CanGenerateServiceQuotationSheet',
        moduleId: modules.Sales.id,
        typeId: permissionType.ServiceQuotation.id,
        checked: false
    },

    CanSendServiceQuotationEmail: {
        id: 26,
        permissionName: 'Can Send Service Quotation Email',
        key: '90e36bba-b253-4aee-92ba-5a0c2f4553f5',
        value: 'CanSendServiceQuotationEmail',
        moduleId: modules.Sales.id,
        typeId: permissionType.ServiceQuotation.id,
        checked: false
    },


    //Stuffing Location

    CanAddStuffingLocation: {
        id: 26,
        permissionName: 'Can Add Stuffing Location',
        key: 'e29c2dc2-90e8-42c9-8f4a-948d6004993a',
        value: 'CanAddStuffingLocation',
        moduleId: modules.Sales.id,
        typeId: permissionType.StuffingLocation.id,
        checked: false
    },
    CanEditStuffingLocation: {
        id: 26,
        permissionName: 'Can Edit Stuffing Location',
        key: '7b197eb3-ef2a-48fe-89d6-bf5dbab60e13',
        value: 'CanEditStuffingLocation',
        moduleId: modules.Sales.id,
        typeId: permissionType.StuffingLocation.id,
        checked: false
    },
    CanViewStuffingLocation: {
        id: 26,
        permissionName: 'Can View Stuffing Location',
        key: 'b9e36aa0-1ca7-41e6-859a-c7115bc6bba2',
        value: 'CanViewStuffingLocation',
        moduleId: modules.Sales.id,
        typeId: permissionType.StuffingLocation.id,
        checked: false
    },



    //Part Of Loading

    CanAddPartOfLoading: {
        id: 26,
        permissionName: 'Can Add Part Of Loading',
        key: '3a0411d5-0d4b-4481-ad84-7a2e274f170b',
        value: 'CanAddPartOfLoading',
        moduleId: modules.Sales.id,
        typeId: permissionType.PartOfLoading.id,
        checked: false
    },
    CanEditPartOfLoading: {
        id: 26,
        permissionName: 'Can Edit Part Of Loading',
        key: 'b4d76bc1-8c65-4e87-b802-fec85ba54eab',
        value: 'CanEditPartOfLoading',
        moduleId: modules.Sales.id,
        typeId: permissionType.PartOfLoading.id,
        checked: false
    },
    CanViewPartOfLoading: {
        id: 26,
        permissionName: 'Can View Part Of Loading',
        key: '0ee2abff-bbb1-4f9d-87ac-9f5040118a72',
        value: 'CanViewPartOfLoading',
        moduleId: modules.Sales.id,
        typeId: permissionType.PartOfLoading.id,
        checked: false
    },



    //Part Of Destination

    CanAddPartOfDestination: {
        id: 26,
        permissionName: 'Can Add Part Of Destination',
        key: '93e7bc3f-8e9d-4187-835b-0116e179cfaf',
        value: 'CanAddPartOfDestination',
        moduleId: modules.Sales.id,
        typeId: permissionType.PartOfDestination.id,
        checked: false
    },
    CanEditPartOfDestination: {
        id: 26,
        permissionName: 'Can Edit Part Of Destination',
        key: '4b789485-756a-4ff8-8521-20df20a50060',
        value: 'CanEditPartOfDestination',
        moduleId: modules.Sales.id,
        typeId: permissionType.PartOfDestination.id,
        checked: false
    },
    CanViewPartOfDestination: {
        id: 26,
        permissionName: 'Can View Part Of Destination',
        key: 'abd09caf-a19d-45fd-8c5c-b3b140d0cb0a',
        value: 'CanViewPartOfDestination',
        moduleId: modules.Sales.id,
        typeId: permissionType.PartOfDestination.id,
        checked: false
    },



    //Order Type

    CanAddOrderType: {
        id: 26,
        permissionName: 'Can Add Order Type',
        key: 'b84ab17a-8d30-49cb-b44c-0713b8e579ee',
        value: 'CanAddOrderType',
        moduleId: modules.Sales.id,
        typeId: permissionType.OrderType.id,
        checked: false
    },
    CanEditOrderType: {
        id: 26,
        permissionName: 'Can Edit Order Type',
        key: '3dcf43ea-281d-4766-878d-f49b046ef8bf',
        value: 'CanEditOrderType',
        moduleId: modules.Sales.id,
        typeId: permissionType.OrderType.id,
        checked: false
    },
    CanViewOrderType: {
        id: 26,
        permissionName: 'Can View Order Type',
        key: 'd62115f3-f715-441b-9de8-04b69baa60a0',
        value: 'CanViewOrderType',
        moduleId: modules.Sales.id,
        typeId: permissionType.OrderType.id,
        checked: false
    },




    //Service

    CanAddService: {
        id: 26,
        permissionName: 'Can Add Service',
        key: '31644445-f416-404d-8849-55cf71533409',
        value: 'CanAddService',
        moduleId: modules.Sales.id,
        typeId: permissionType.Service.id,
        checked: false
    },
    CanEditService: {
        id: 26,
        permissionName: 'Can Edit Service',
        key: '51896110-3bba-4ba0-a2b1-b6aef5388afc',
        value: 'CanEditService',
        moduleId: modules.Sales.id,
        typeId: permissionType.Service.id,
        checked: false
    },
    CanViewService: {
        id: 26,
        permissionName: 'Can View Service',
        key: '2fa44b08-fcc1-4a88-9379-36d74c96ed1d',
        value: 'CanViewService',
        moduleId: modules.Sales.id,
        typeId: permissionType.Service.id,
        checked: false
    },



    //Incoterms

    CanAddIncoterms: {
        id: 26,
        permissionName: 'Can Add Incoterms',
        key: '429b36eb-1f95-42eb-bdba-a63f3f554cb2',
        value: 'CanAddIncoterms',
        moduleId: modules.Sales.id,
        typeId: permissionType.Incoterms.id,
        checked: false
    },
    CanEditIncoterms: {
        id: 26,
        permissionName: 'Can Edit Incoterms',
        key: 'b5f589b6-4ddf-4c21-9924-ae5620bf1539',
        value: 'CanEditIncoterms',
        moduleId: modules.Sales.id,
        typeId: permissionType.Incoterms.id,
        checked: false
    },
    CanViewIncoterms: {
        id: 26,
        permissionName: 'Can View Incoterms',
        key: '86866f99-df76-4ed5-8133-f424a8149633',
        value: 'CanViewIncoterms',
        moduleId: modules.Sales.id,
        typeId: permissionType.Incoterms.id,
        checked: false
    },



    //Commodity

    CanAddCommodity: {
        id: 26,
        permissionName: 'Can Add Commodity',
        key: '449193f1-3f49-4f8d-924c-6d7f77ae66b3',
        value: 'CanAddCommodity',
        moduleId: modules.Sales.id,
        typeId: permissionType.Commodity.id,
        checked: false
    },
    CanEditCommodity: {
        id: 26,
        permissionName: 'Can Edit Commodity',
        key: 'a532a689-a21e-46b3-ba46-73f4393f1413',
        value: 'CanEditCommodity',
        moduleId: modules.Sales.id,
        typeId: permissionType.Commodity.id,
        checked: false
    },
    CanViewCommodity: {
        id: 26,
        permissionName: 'Can View Commodity',
        key: 'cfb26d9e-af1b-4fb6-b911-e72d41c5c422',
        value: 'CanViewCommodity',
        moduleId: modules.Sales.id,
        typeId: permissionType.Commodity.id,
        checked: false
    },



    //Carrier

    CanAddCarrier: {
        id: 26,
        permissionName: 'Can Add Carrier',
        key: '381335e8-d236-44d7-9f0a-3271277d6068',
        value: 'CanAddCarrier',
        moduleId: modules.Sales.id,
        typeId: permissionType.Carrier.id,
        checked: false
    },
    CanEditCarrier: {
        id: 26,
        permissionName: 'Can Edit Carrier',
        key: 'f1cbecc4-8bf3-4325-be3e-1305f2481af3',
        value: 'CanEditCarrier',
        moduleId: modules.Sales.id,
        typeId: permissionType.Carrier.id,
        checked: false
    },
    CanViewCarrier: {
        id: 26,
        permissionName: 'Can View Carrier',
        key: '515d595b-be72-46cc-9ad0-6bec215dd94d',
        value: 'CanViewCarrier',
        moduleId: modules.Sales.id,
        typeId: permissionType.Carrier.id,
        checked: false
    },




    //ExportExw

    CanAddExportExw: {
        id: 26,
        permissionName: 'Can Add ExportExw',
        key: '5a60ab42-ae8b-488e-a5a0-5546ea9c29a8',
        value: 'CanAddExportExw',
        moduleId: modules.Sales.id,
        typeId: permissionType.ExportExw.id,
        checked: false
    },
    CanEditExportExw: {
        id: 26,
        permissionName: 'Can Edit ExportExw',
        key: 'f1755e4c-ff85-4cf3-ae99-aec7c15f148f',
        value: 'CanEditExportExw',
        moduleId: modules.Sales.id,
        typeId: permissionType.ExportExw.id,
        checked: false
    },
    CanViewExportExw: {
        id: 26,
        permissionName: 'Can View ExportExw',
        key: '195dce55-08ee-4399-8ad2-645d96d969ad',
        value: 'CanViewExportExw',
        moduleId: modules.Sales.id,
        typeId: permissionType.ExportExw.id,
        checked: false
    },




    //ImportFob

    CanAddImportFob: {
        id: 26,
        permissionName: 'Can Add ImportFob',
        key: '293579d9-10d0-4ee7-b836-599e6929ba21',
        value: 'CanAddImportFob',
        moduleId: modules.Sales.id,
        typeId: permissionType.ImportFOB.id,
        checked: false
    },
    CanEditImportFob: {
        id: 26,
        permissionName: 'Can Edit ImportFob',
        key: '1dc302fc-ff64-4fd7-93d4-81e4962b31a9',
        value: 'CanEditImportFob',
        moduleId: modules.Sales.id,
        typeId: permissionType.ImportFOB.id,
        checked: false
    },
    CanViewImportFob: {
        id: 26,
        permissionName: 'Can View ImportFob',
        key: 'be24c827-5eb6-451a-82ba-3dd69ad2ad53',
        value: 'CanViewImportFob',
        moduleId: modules.Sales.id,
        typeId: permissionType.ImportFOB.id,
        checked: false
    },




    //QuantityContainer

    CanAddQuantityContainer: {
        id: 26,
        permissionName: 'Can Add Quantity Container',
        key: 'ea53d9e1-ba3c-4ff5-b1ff-99321754683b',
        value: 'CanAddQuantityContainer',
        moduleId: modules.Sales.id,
        typeId: permissionType.QuantityContainer.id,
        checked: false
    },
    CanEditQuantityContainer: {
        id: 26,
        permissionName: 'Can Edit Quantity Container',
        key: '57a97dc1-2960-4b9e-8f1d-7d85a0418aac',
        value: 'CanEditQuantityContainer',
        moduleId: modules.Sales.id,
        typeId: permissionType.QuantityContainer.id,
        checked: false
    },
    CanViewQuantityContainer: {
        id: 26,
        permissionName: 'Can View Quantity Container',
        key: '2421bc4a-6fa3-455b-965b-690725a4619e',
        value: 'CanViewQuantityContainer',
        moduleId: modules.Sales.id,
        typeId: permissionType.QuantityContainer.id,
        checked: false
    },

    //Custom Setting
    SimpleSaleInvoice: {
        id: 26,
        permissionName: 'Simple Sale Invoice',
        key: '8836edc3-2112-40cc-927c-5ce4d1a223e1',
        value: 'SimpleSaleInvoice',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },
    SaleServiceInvoice: {
        id: 26,
        permissionName: 'Sale Service Invoice',
        key: '063dc416-1b25-4ea8-a8e9-0119d7d9cad0',
        value: 'SaleServiceInvoice',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },
    CustomerPurchaseOrder: {
        id: 26,
        permissionName: 'Customer Purchase Order',
        key: '8d0041b6-1710-49e7-a93b-4ef333defb75',
        value: 'CustomerPurchaseOrder',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },
    CustomerInquiry: {
        id: 26,
        permissionName: 'Customer Inquiry',
        key: 'f5da452f-be45-4fc6-af5a-5c4897db05c9',
        value: 'CustomerInquiry',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },
    SaleReport: {
        id: 26,
        permissionName: 'Sale Report',
        key: '93e0c318-4633-4093-b6b0-37937a3c5839',
        value: 'SaleReport',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },
    SaleServiceReport: {
        id: 26,
        permissionName: 'Sale Service Report',
        key: 'a3aa08a8-ac6c-4293-9aab-c643a69d5933',
        value: 'SaleServiceReport',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },
    SaleServiceReturn: {
        id: 26,
        permissionName: 'Sale Service Return',
        key: '738d3c12-b4f0-4bfe-927c-8182f0fce03e',
        value: 'SaleServiceReturn',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },
    DeliveryManagement: {
        id: 26,
        permissionName: 'Delivery Management',
        key: 'cd74080b-8b98-4b3a-b72c-c3f64bceba44',
        value: 'DeliveryManagement',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },
    BillingManagement: {
        id: 26,
        permissionName: 'Billing Management',
        key: '9faf9aea-640c-40c6-b07f-cd5582e558f3',
        value: 'BillingManagement',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },
    ProposalManagement: {
        id: 26,
        permissionName: 'Proposal Management',
        key: 'e8e2102d-6e17-42d4-af27-ec186ccd1025',
        value: 'ProposalManagement',
        moduleId: modules.Sales.id,
        typeId: permissionType.CustomSetting.id,
        checked: false
    },

    //Purchase Invoice:

    CashPurchase: {
        id: 26,
        permissionName: 'Cash Purchase',
        key: 'd6543752-d951-4ade-af84-3d49a840bec3',
        value: 'CashPurchase',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CreditPurchase: {
        id: 26,
        permissionName: 'Credit Purchase',
        key: '50793b87-78d8-4b5f-bf2a-83ab051d17bf',
        value: 'CreditPurchase',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanAddPurchaseInvoice: {
        id: 26,
        permissionName: 'Can Add Purchase Invoice',
        key: 'd41295fe-68ff-4eb1-8efd-6e590ce3313b',
        value: 'CanAddPurchaseInvoice',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanViewPurchaseDetail: {
        id: 26,
        permissionName: 'Can View Purchase Detail',
        key: '7bbd5e09-fa98-4b21-956e-07030a5a3a1b',
        value: 'CanViewPurchaseDetail',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanViewPurchaseDraft: {
        id: 26,
        permissionName: 'Can View Purchase Draft',
        key: '10d40d4d-633b-4790-b7b2-ccb6fb2c41f3',
        value: 'CanViewPurchaseDraft',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanViewPurchasePost: {
        id: 26,
        permissionName: 'Can View Purchase Post',
        key: '24df72ba-7e7e-4dd2-bab9-90741d536f29',
        value: 'CanViewPurchasePost',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanPrintPurchaseInvoice: {
        id: 26,
        permissionName: 'Can Print Purchase Invoice',
        key: '899348a9-163d-4201-a695-78fc03b24a11',
        value: 'CanPrintPurchaseInvoice',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanDeletePurchaseInvoice: {
        id: 26,
        permissionName: 'Can Delete Purchase Invoice',
        key: 'dc1fb962-076c-4c01-8907-bc9aca04bd1a',
        value: 'CanDeletePurchaseInvoice',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanEditPurchaseInvoice: {
        id: 26,
        permissionName: 'Can Edit Purchase Invoice',
        key: '2c95f65c-b6a6-44dd-ac2a-de3c86ff9b8e',
        value: 'CanEditPurchaseInvoice',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanPurchaseInvoiceCosting: {
        id: 26,
        permissionName: 'Can Purchase Invoice Costing',
        key: '7a0b78e3-860a-44df-ac4b-e90107f4d154',
        value: 'CanPurchaseInvoiceCosting',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanSendPIEmail: {
        id: 26,
        permissionName: 'Can Send PI Email',
        key: 'c15123a6-67d9-4817-9e52-f7698ce430e8',
        value: 'CanSendPIEmail',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanGeneratePIPdf: {
        id: 26,
        permissionName: 'Can Generate PI Pdf',
        key: '23f92745-320f-472b-bf04-5a6bfc8d62ea',
        value: 'CanGeneratePIPdf',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanGeneratePISheet: {
        id: 26,
        permissionName: 'Can Generate PI Sheet',
        key: '0a09ae48-bdc1-4621-a56e-9f35583bbda3',
        value: 'CanGeneratePISheet',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanViewPIAttachment: {
        id: 26,
        permissionName: 'Can View PI Attachment',
        key: '911d736f-6f78-4b7a-a058-2b9d529f91e1',
        value: 'CanViewPIAttachment',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },
    CanDownloadPIAttachment: {
        id: 26,
        permissionName: 'Can Download PI Attachment',
        key: 'dba88fa1-8e05-4a61-ac9b-a424ad828f40',
        value: 'CanDownloadPIAttachment',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanViewPIActions: {
        id: 26,
        permissionName: 'Can View PI Actions',
        key: 'f25632c5-f15e-42ff-b77b-97988cd046f6',
        value: 'CanViewPIActions',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanViewPIPayments: {
        id: 26,
        permissionName: 'Can View PI Payments',
        key: 'adead3dc-a721-4c68-b8f3-f97b72eee670',
        value: 'CanViewPIPayments',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanViewPIExpenses: {
        id: 26,
        permissionName: 'Can View PI Expenses',
        key: '2c736a89-410d-48c5-bec0-da9bdcaab2bc',
        value: 'CanViewPIExpenses',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },

    CanDeletePIExpenses: {
        id: 26,
        permissionName: 'Can Delete PI Expenses',
        key: '4755b043-d4d4-4f54-a66f-72789d9cbafc',
        value: 'CanDeletePIExpenses',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },





    GoodReceives: {
        id: 26,
        permissionName: 'Good Receives',
        key: '76f839e8-6bd6-4e67-9fe2-d13ad50ade4b',
        value: 'GoodReceives',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },


    PurchaseReport: {
        id: 26,
        permissionName: 'Purchase Report',
        key: 'e00a9128-8e6d-43df-a08f-6639a5879ad1',
        value: 'PurchaseReport',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },


    PurchaseDeliveryManagement: {
        id: 26,
        permissionName: 'Purchase Delivery Management',
        key: '497a9eb4-1c63-4931-8df2-3107269061c7',
        value: 'PurchaseDeliveryManagement',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },


    PurchaseBillingManagement: {
        id: 26,
        permissionName: 'Purchase Billing Management',
        key: 'b38af91f-0476-459d-a207-20d45a17b9d9',
        value: 'PurchaseBillingManagement',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false
    },
    CreditNote:{
        id: 26,
        permissionName: "Credit Note",
        key: "99299386-c9d1-4382-bfad-af598593e5fb",
        value: "CreditNote",
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseInvoice.id,
        checked: false,
      },



    //Purchase Order:

    CanViewDraftOrder: {
        id: 26,
        permissionName: 'Can View Draft Order',
        key: '2efb99a7-6947-46ec-9013-e1c94ab3a792',
        value: 'CanViewDraftOrder',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanViewPostOrder: {
        id: 26,
        permissionName: 'Can View Post Order',
        key: 'b8bd07c7-c54f-433f-81e6-c0cb5a759224',
        value: 'CanViewPostOrder',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanViewInProcessOrder: {
        id: 26,
        permissionName: 'Can View InProcess Order',
        key: '6410382f-68d5-4350-9ea9-8873dfb87e4c',
        value: 'CanViewInProcessOrder',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanAddPurchaseOrder: {
        id: 26,
        permissionName: 'Can Add Purchase Order',
        key: '15324706-2472-4f78-9809-3b1d31fdfefd',
        value: 'CanAddPurchaseOrder',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanEditPurchaseOrder: {
        id: 26,
        permissionName: 'Can Edit Purchase Order',
        key: '5114e236-0ee9-427d-b98c-2412867f533b',
        value: 'CanEditPurchaseOrder',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanViewOrderDetail: {
        id: 26,
        permissionName: 'Can View Order Detail',
        key: '046812bf-478a-49ad-bd38-ab38c077d2e6',
        value: 'CanViewOrderDetail',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanUploadAttachment: {
        id: 26,
        permissionName: 'Can Upload Attachment',
        key: '7f96ce85-d573-4572-ad85-1ff950146e03',
        value: 'CanUploadAttachment',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanDownloadAttachment: {
        id: 26,
        permissionName: 'Can Download Attachment',
        key: 'b67d19ed-0df9-4518-a0b2-22880fa6df1d',
        value: 'CanDownloadAttachment',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanAddAdvancePayment: {
        id: 26,
        permissionName: 'Can Add Advance Payment',
        key: 'e2ae6f6a-1552-4744-aaf9-e14ae237ec4b',
        value: 'CanAddAdvancePayment',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanViewAdvancePayment: {
        id: 26,
        permissionName: 'Can View Advance Payment',
        key: '30dbfc5f-9a2b-4b0f-b1c1-a43667115a58',
        value: 'CanViewAdvancePayment',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanViewDetailAdvancePayment: {
        id: 26,
        permissionName: 'Can View Detail Advance Payment',
        key: 'be7a804f-387a-4bf8-bdbf-f708fc44720a',
        value: 'CanViewDetailAdvancePayment',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanAddOrderAction: {
        id: 26,
        permissionName: 'Can Add Order Action',
        key: '9a4220e9-7248-4911-aa74-c6df9dc0f15f',
        value: 'CanAddOrderAction',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanViewOrderAction: {
        id: 26,
        permissionName: 'Can View Order Action',
        key: '4095b294-e9a7-429b-83fb-9b1eb0ddc956',
        value: 'CanViewOrderAction',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanViewOrderExpense: {
        id: 26,
        permissionName: 'Can View Order Expense',
        key: 'f62514b6-e3ce-4329-bd20-f3e790a52e13',
        value: 'CanViewOrderExpense',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanAddOrderExpense: {
        id: 26,
        permissionName: 'Can Add Order Expense',
        key: '2a320d72-2ee2-4e23-8910-0de998f742bd',
        value: 'CanAddOrderExpense',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanViewDetailOrderExpense: {
        id: 26,
        permissionName: 'Can View Detail Order Expense',
        key: 'fb3b8f37-63a7-425d-9a1a-66f76e397197',
        value: 'CanViewDetailOrderExpense',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanAllowOrderVersion: {
        id: 26,
        permissionName: 'Can Allow Order Version',
        key: '10d810f7-b7f9-47d2-8d03-d782319a5e86',
        value: 'CanAllowOrderVersion',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanViewPreviousVersion: {
        id: 26,
        permissionName: 'Can View Previous Version',
        key: '33147813-5f58-413a-94da-4491ea56be66',
        value: 'CanViewPreviousVersion',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanAdvancePaymentFromList: {
        id: 26,
        permissionName: 'Can Advance Payment From List',
        key: '1512c1c7-0128-4eda-9c21-ea7361cdb32c',
        value: 'CanAdvancePaymentFromList',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },

    CanCloseOrder: {
        id: 26,
        permissionName: 'Can Close Order',
        key: '4a79637b-4d11-4bd0-9ce5-f3f95ce64f92',
        value: 'CanCloseOrder',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseOrder.id,
        checked: false
    },






    //Auto Purchase Template:
    CanViewAutoTemplate: {
        id: 26,
        permissionName: 'Can View Auto Template',
        key: '6bb27705-a83a-4461-9059-2008b099581f',
        value: 'CanViewAutoTemplate',
        moduleId: modules.Purchase.id,
        typeId: permissionType.AutoPurchaseTemplate.id,
        checked: false
    },

    CanViewDetailAutoTemplate: {
        id: 26,
        permissionName: 'Can View Detail Auto Template',
        key: '9a7a30be-6c3e-433e-a965-e575a14b6ea3',
        value: 'CanViewDetailAutoTemplate',
        moduleId: modules.Purchase.id,
        typeId: permissionType.AutoPurchaseTemplate.id,
        checked: false
    },

    CanAddAutoTemplate: {
        id: 26,
        permissionName: 'Can Add Auto Template',
        key: '99017f60-a8b2-40f8-863a-09d2c8a660cb',
        value: 'CanAddAutoTemplate',
        moduleId: modules.Purchase.id,
        typeId: permissionType.AutoPurchaseTemplate.id,
        checked: false
    },

    CanPrintAutoTemplate: {
        id: 26,
        permissionName: 'Can Print Auto Template',
        key: 'c7b6247c-cee3-4c08-8d67-3635e5c97836',
        value: 'CanPrintAutoTemplate',
        moduleId: modules.Purchase.id,
        typeId: permissionType.AutoPurchaseTemplate.id,
        checked: false
    },

    CanEditAutoTemplate: {
        id: 26,
        permissionName: 'Can Edit Auto Template',
        key: '547f44b9-d4e3-43bf-bf68-bfe43747bf65',
        value: 'CanEditAutoTemplate',
        moduleId: modules.Purchase.id,
        typeId: permissionType.AutoPurchaseTemplate.id,
        checked: false
    },

    //Purchase Return:
    CanViewPurchaseReturn: {
        id: 26,
        permissionName: 'Can View Purchase Return',
        key: '6da31bd0-7cf7-4bf3-bc57-565b5935e0eb',
        value: 'CanViewPurchaseReturn',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseReturn.id,
        checked: false
    },

    CanViewDetailPurchaseReturn: {
        id: 26,
        permissionName: 'Can View Detail Purchase Return',
        key: '3aa537b4-1496-42c5-b95e-7fb7fed6f000',
        value: 'CanViewDetailPurchaseReturn',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseReturn.id,
        checked: false
    },

    CanAddPurchaseReturn: {
        id: 26,
        permissionName: 'Can Add Purchase Return',
        key: '3f232769-69eb-48e5-80cf-47b9aab4f15c',
        value: 'CanAddPurchaseReturn',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseReturn.id,
        checked: false
    },

    CanViewReturnHistory: {
        id: 26,
        permissionName: 'Can View Return History',
        key: 'a193ca1e-5a08-4320-9cf0-d15606dc5627',
        value: 'CanViewReturnHistory',
        moduleId: modules.Purchase.id,
        typeId: permissionType.PurchaseReturn.id,
        checked: false
    },


    //Supplier:

    CanImportSupplier: {
        id: 26,
        permissionName: 'Can Import Supplier',
        key: 'c7251546-5457-4977-bb83-994170e65812',
        value: 'CanImportSupplier',
        moduleId: modules.Purchase.id,
        typeId: permissionType.Supplier.id,
        checked: false
    },

    CanAddSupplier: {
        id: 26,
        permissionName: 'Can Add Supplier',
        key: '6e9a8736-c9c4-4135-a9b0-ef40302dbc61',
        value: 'CanAddSupplier',
        moduleId: modules.Purchase.id,
        typeId: permissionType.Supplier.id,
        checked: false
    },

    CanViewSupplier: {
        id: 26,
        permissionName: 'Can View Supplier',
        key: '9a8d460c-500d-415d-8532-f4c033c926d8',
        value: 'CanViewSupplier',
        moduleId: modules.Purchase.id,
        typeId: permissionType.Supplier.id,
        checked: false
    },

    CanEditSupplier: {
        id: 26,
        permissionName: 'Can Edit Supplier',
        key: '8625d4ff-6238-4d85-84eb-58171d7e40c7',
        value: 'CanEditSupplier',
        moduleId: modules.Purchase.id,
        typeId: permissionType.Supplier.id,
        checked: false
    },

    LimitedSupplier: {
        id: 26,
        permissionName: 'Limited Supplier',
        key: '3d1f65f1-3f72-4898-a175-1b6ab42b2b9d',
        value: '0',
        moduleId: modules.Purchase.id,
        typeId: permissionType.Supplier.id,
        checked: false
    },


    //Supplier Pay Receipt:

    CanViewSPR: {
        id: 26,
        permissionName: 'Can View SPR',
        key: '19b6b410-66f0-4c1f-9e5e-c976d9b62b86',
        value: 'CanViewSPR',
        moduleId: modules.Purchase.id,
        typeId: permissionType.SupplierPayReceipt.id,
        checked: false
    },


    CanAddSPR: {
        id: 26,
        permissionName: 'Can Add SPR',
        key: '421b8f4a-a8b1-4d98-b90b-72ddc4c807f6',
        value: 'CanAddSPR',
        moduleId: modules.Purchase.id,
        typeId: permissionType.SupplierPayReceipt.id,
        checked: false
    },

    CanPrintSPR: {
        id: 26,
        permissionName: 'Can Print SPR',
        key: '071327a3-a171-4f7b-b3e0-95cf884cf67f',
        value: 'CanPrintSPR',
        moduleId: modules.Purchase.id,
        typeId: permissionType.SupplierPayReceipt.id,
        checked: false
    },

    CanDraftSPR: {
        id: 26,
        permissionName: 'Can Draft SPR',
        key: 'f7d1f967-ce9c-402b-b475-2653ee843342',
        value: 'CanDraftSPR',
        moduleId: modules.Purchase.id,
        typeId: permissionType.SupplierPayReceipt.id,
        checked: false
    },

    CanEditSPR: {
        id: 26,
        permissionName: 'Can Edit SPR',
        key: '5019416f-c9e6-40a9-8129-1e018217cd9b',
        value: 'CanEditSPR',
        moduleId: modules.Purchase.id,
        typeId: permissionType.SupplierPayReceipt.id,
        checked: false
    },

    CanRejectSPR: {
        id: 26,
        permissionName: 'Can Reject SPR',
        key: 'd6cb0157-434e-4d04-af0a-a046312948f9',
        value: 'CanRejectSPR',
        moduleId: modules.Purchase.id,
        typeId: permissionType.SupplierPayReceipt.id,
        checked: false
    },

    CanViewDetailSPR: {
        id: 26,
        permissionName: 'Can View Detail SPR',
        key: 'e242787d-5ef9-4c76-b275-607a826ceec7',
        value: 'CanViewDetailSPR',
        moduleId: modules.Purchase.id,
        typeId: permissionType.SupplierPayReceipt.id,
        checked: false
    },

    CanAddSPRAttachment: {
        id: 26,
        permissionName: 'Can Add SPR Attachment',
        key: '4836a489-6ac2-41c6-8ef1-bab51c9bafc2',
        value: 'CanAddAttachment',
        moduleId: modules.Purchase.id,
        typeId: permissionType.SupplierPayReceipt.id,
        checked: false
    },
    // Goods Receive

    CanAddGoodsReceiveAsDraft: {
        id: 26,
        permissionName: 'Can Add Goods Receive as Draft',
        key: '5012416f-b9e6-40a9-8129-1e058217cd9b',
        value: 'CanAddGoodsReceiveAsDraft',
        moduleId: modules.Purchase.id,
        typeId: permissionType.GoodsReceive.id,
        checked: false
    },

    CanAddGoodsReceiveasPost: {
        id: 26,
        permissionName: 'Can Add Goods Receive as Post',
        key: '6212476f-b9e6-40a9-8129-1a058217da9b',
        value: 'CanAddGoodsReceiveasPost',
        moduleId: modules.Purchase.id,
        typeId: permissionType.GoodsReceive.id,
        checked: false
    },

    CanEditGoodsReceiveasDraft: {
        id: 26,
        permissionName: 'Can Edit Goods Receive as Draft',
        key: 'd6cb0157-234e-4e94-af0a-a046312948f9',
        value: 'CanEditGoodsReceiveasDraft',
        moduleId: modules.Purchase.id,
        typeId: permissionType.GoodsReceive.id,
        checked: false
    },
    CanEditGoodsReceiveasPost: {
        id: 26,
        permissionName: 'Can Edit Goods Receive as Post',
        key: 'f7cb1158-254e-4e94-af0a-a046912948f9',
        value: 'CanEditGoodsReceiveasPost',
        moduleId: modules.Purchase.id,
        typeId: permissionType.GoodsReceive.id,
        checked: false
    },

    CanViewGoodsReceiveasDraft: {
        id: 26,
        permissionName: 'Can View Goods Receive as Draft',
        key: 'e242787d-5ea9-4c76-b276-670a826ceec7',
        value: 'CanViewGoodsReceiveasDraft',
        moduleId: modules.Purchase.id,
        typeId: permissionType.GoodsReceive.id,
        checked: false
    },
    CanViewGoodsReceiveasPost: {
        id: 26,
        permissionName: 'Can View Goods Receive as Post',
        key: 'f642787d-9ea9-9c76-e286-670a826ceec7',
        value: 'CanViewGoodsReceiveasPost',
        moduleId: modules.Purchase.id,
        typeId: permissionType.GoodsReceive.id,
        checked: false
    },
    PurchaseOrderToGoodsReceive: {
        id: 26,
        permissionName: 'Purchase Order To Goods Receive',
        key: '7013416g-c9e6-48a9-8129-1e058217cd9a',
        value: 'PurchaseOrderToGoodsReceive',
        moduleId: modules.Purchase.id,
        typeId: permissionType.GoodsReceive.id,
        checked: false
    },
    CanDeleteGoodsReceive: {
        id: 26,
        permissionName: 'Can Delete Goods Receive',
        key: '4836a189-6ac2-41c6-9ef1-abb52c9bafc2',
        value: 'CanDeleteGoodsReceive',
        moduleId: modules.Purchase.id,
        typeId: permissionType.GoodsReceive.id,
        checked: false
    },



    //Accounting Module:


    //Petty Cash

    CanViewPettyCash: {
        id: 26,
        permissionName: 'Can View Petty Cash',
        key: '2903892e-79cf-48bc-a686-70140321be60',
        value: 'CanViewPettyCash',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PettyCash.id,
        checked: false
    },
    CanPrintPettyCashTemplate1: {
        id: 26,
        permissionName: 'Can Print Petty Cash Template 1',
        key: 'ef90a515-331a-4745-ab15-f5d3d220267d',
        value: 'CanPrintPettyCashTemplate1',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PettyCash.id,
        checked: false
    },
    CanPrintPettyCashTemplate2: {
        id: 26,
        permissionName: 'Can Print Petty Cash Template 2',
        key: 'daeb8d0e-1191-400a-b78f-45bbd4ebb9da',
        value: 'CanPrintPettyCashTemplate2',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PettyCash.id,
        checked: false
    },
    CanDraftPettyCash: {
        id: 26,
        permissionName: 'Can Draft Petty Cash',
        key: 'a6ceeb3d-d757-4f6b-af8f-c9d4fe276b22',
        value: 'CanDraftPettyCash',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PettyCash.id,
        checked: false
    },
    CanEditPettyCash: {
        id: 26,
        permissionName: 'Can Edit Petty Cash',
        key: '13432663-32e9-46a6-9dea-8eacb19579b5',
        value: 'CanEditPettyCash',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PettyCash.id,
        checked: false
    },
    CanRejectPettyCash: {
        id: 26,
        permissionName: 'Can Reject Petty Cash',
        key: '7715b9b9-f25f-4a67-8a15-09b05249d25a',
        value: 'CanRejectPettyCash',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PettyCash.id,
        checked: false
    },
    CanViewDetailPettyCash: {
        id: 26,
        permissionName: 'Can View Detail Petty Cash',
        key: '886703e2-2477-45a4-9b95-4c64fef895f9',
        value: 'CanViewDetailPettyCash',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PettyCash.id,
        checked: false
    },
    CanAddPettyCashAttachment: {
        id: 26,
        permissionName: 'Can Add Petty Cash Attachment',
        key: '6b2b7f0a-1099-4a7b-86e0-ac43ce6aa6b6',
        value: 'CanAddPettyCashAttachment',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PettyCash.id,
        checked: false
    },
    CanAddPettyCash: {
        id: 26,
        permissionName: 'Can Add Petty Cash',
        key: '5a6ec10c-e703-443d-89d9-0572d58dd126',
        value: 'CanAddPettyCash',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PettyCash.id,
        checked: false
    },

    //Journal Voucher
    CanViewJV: {
        id: 26,
        permissionName: 'Can View JV',
        key: '6ae5311b-0116-401d-a673-de975dc1076b',
        value: 'CanViewJV',
        moduleId: modules.Accounting.id,
        typeId: permissionType.JournalVoucher.id,
        checked: false
    },
    CanDraftJV: {
        id: 26,
        permissionName: 'Can Draft JV',
        key: 'c5af7dea-5a07-4656-bb63-240e02701fd5',
        value: 'CanDraftJV',
        moduleId: modules.Accounting.id,
        typeId: permissionType.JournalVoucher.id,
        checked: false
    },
    CanEditJV: {
        id: 26,
        permissionName: 'Can Edit JV',
        key: '7c963256-ffa9-4ea5-9a9f-47d13c7844fa',
        value: 'CanEditJV',
        moduleId: modules.Accounting.id,
        typeId: permissionType.JournalVoucher.id,
        checked: false
    },
    CanViewDetailJV: {
        id: 26,
        permissionName: 'Can View Detail JV',
        key: '17544f55-0c1a-46fd-a72b-25d02200ff08',
        value: 'CanViewDetailJV',
        moduleId: modules.Accounting.id,
        typeId: permissionType.JournalVoucher.id,
        checked: false
    },
    CanAddJV: {
        id: 26,
        permissionName: 'Can Add JV',
        key: '11cd3abc-44ac-433a-9c66-cd644721ceab',
        value: 'CanAddJV',
        moduleId: modules.Accounting.id,
        typeId: permissionType.JournalVoucher.id,
        checked: false
    },

    //Opening Cash
    CanViewOC: {
        id: 26,
        permissionName: 'Can View OC',
        key: 'e7389195-5702-4870-b7ca-5beb06feaf89',
        value: 'CanViewOC',
        moduleId: modules.Accounting.id,
        typeId: permissionType.OpeningCash.id,
        checked: false
    },
    CanDraftOC: {
        id: 26,
        permissionName: 'Can Draft OC',
        key: 'd7f15879-0d68-4655-8f68-222f3b9eaf64',
        value: 'CanDraftOC',
        moduleId: modules.Accounting.id,
        typeId: permissionType.OpeningCash.id,
        checked: false
    },
    CanEditOC: {
        id: 26,
        permissionName: 'Can Edit OC',
        key: '29b39c5d-739c-4b02-b95c-644bbd65e2a9',
        value: 'CanEditOC',
        moduleId: modules.Accounting.id,
        typeId: permissionType.OpeningCash.id,
        checked: false
    },
    CanViewDetailOC: {
        id: 26,
        permissionName: 'Can View Detail OC',
        key: 'a551a2b8-99dc-4d38-976e-f00e693f0fe4',
        value: 'CanViewDetailOC',
        moduleId: modules.Accounting.id,
        typeId: permissionType.OpeningCash.id,
        checked: false
    },
    CanAddOC: {
        id: 26,
        permissionName: 'Can Add OC',
        key: '75de95da-9f38-4464-8d8c-fd77afec18b9',
        value: 'CanAddOC',
        moduleId: modules.Accounting.id,
        typeId: permissionType.OpeningCash.id,
        checked: false
    },

    //Temporary Cash Request
    CanViewTCRequest: {
        id: 26,
        permissionName: 'Can View Temporary Cash Request',
        key: '018b5036-1f6c-47d6-ac91-ae22f35e0ba2',
        value: 'CanViewTCRequest',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashRequest.id,
        checked: false
    },
    CanDraftTCRequest: {
        id: 26,
        permissionName: 'Can Draft Temporary Cash Request',
        key: '20633997-26a4-4fee-a726-4fa21fcd7eed',
        value: 'CanDraftTCRequest',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashRequest.id,
        checked: false
    },
    CanEditTCRequest: {
        id: 26,
        permissionName: 'Can Edit Temporary Cash Request',
        key: '63301337-decb-423f-8682-042c69c3bf96',
        value: 'CanEditTCRequest',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashRequest.id,
        checked: false
    },
    CanViewDetailTCRequest: {
        id: 26,
        permissionName: 'Can View Detail Temporary Cash Request',
        key: 'd3af4e69-375d-42a9-8658-2a2ac3859af7',
        value: 'CanViewDetailTCRequest',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashRequest.id,
        checked: false
    },
    CanAddTCRequest: {
        id: 26,
        permissionName: 'Can Add Temporary Cash Request',
        key: '717e3f0a-ebeb-4ac3-91a7-2403d52da99d',
        value: 'CanAddTCRequest',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashRequest.id,
        checked: false
    },
    CanPrintTCRequest: {
        id: 26,
        permissionName: 'Can Print Temporary Cash Request',
        key: 'fa8cb0d4-32ef-43c0-979f-255385d9a0d4',
        value: 'CanPrintTCRequest',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashRequest.id,
        checked: false
    },
    CanAttacmentTCRequest: {
        id: 26,
        permissionName: 'Can Attachment Temporary Cash Request',
        key: '36dbdf23-72ba-43b7-94b4-c1bb34726346',
        value: 'CanAttacmentTCRequest',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashRequest.id,
        checked: false
    },


    //Temporary Cash Issue
    CanViewTCIssue: {
        id: 26,
        permissionName: 'Can View Temporary Cash Issue',
        key: '7e541f77-f217-4aa4-aecf-c845015b48e2',
        value: 'CanViewTCIssue',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashIssue.id,
        checked: false
    },
    CanDraftTCIssue: {
        id: 26,
        permissionName: 'Can Draft Temporary Cash Issue',
        key: '311eabc8-98ac-4394-bc2f-70deb8f412e8',
        value: 'CanDraftTCIssue',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashIssue.id,
        checked: false
    },
    CanEditTCIssue: {
        id: 26,
        permissionName: 'Can Edit Temporary Cash Issue',
        key: 'a08253e4-85b5-4c5c-b21a-ab32d8114627',
        value: 'CanEditTCIssue',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashIssue.id,
        checked: false
    },
    CanViewDetailTCIssue: {
        id: 26,
        permissionName: 'Can View Detail Temporary Cash Issue',
        key: '32dfcaaf-1a06-4fd5-9359-324fa41b688b',
        value: 'CanViewDetailTCIssue',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashIssue.id,
        checked: false
    },
    CanAddTCIssue: {
        id: 26,
        permissionName: 'Can Add Temporary Cash Issue',
        key: '566bb493-419b-4e80-b90d-ec2431974d2a',
        value: 'CanAddTCIssue',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashIssue.id,
        checked: false
    },
    CanPrintTCIssue: {
        id: 26,
        permissionName: 'Can Print Temporary Cash Issue',
        key: 'b07ecd7f-818c-41d1-aae5-2013db15c746',
        value: 'CanPrintTCIssue',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashIssue.id,
        checked: false
    },
    CanAttacmentTCIssue: {
        id: 26,
        permissionName: 'Can Attachment Temporary Cash Issue',
        key: 'a17a8c44-f64c-444c-bdee-f1070223d2de',
        value: 'CanAttacmentTCIssue',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashIssue.id,
        checked: false
    },

    //Temporary Cash Allocation
    CanViewTCAllocation: {
        id: 26,
        permissionName: 'Can View Temporary Cash Allocation',
        key: '5d776de3-bb6f-4f5c-bf42-93ce399d430f',
        value: 'CanViewTCAllocation',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashAllocation.id,
        checked: false
    },
    CanDraftTCAllocation: {
        id: 26,
        permissionName: 'Can Draft Temporary Cash Allocation',
        key: '915db528-2ae0-453f-9478-8c60bdfdfd31',
        value: 'CanDraftTCAllocation',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashAllocation.id,
        checked: false
    },
    CanEditTCAllocation: {
        id: 26,
        permissionName: 'Can Edit Temporary Cash Allocation',
        key: '6f6e46d8-6b23-4f93-b753-c30a4ff45572',
        value: 'CanEditTCAllocation',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashAllocation.id,
        checked: false
    },
    CanViewDetailTCAllocation: {
        id: 26,
        permissionName: 'Can View Detail Temporary Cash Allocation',
        key: '535625bf-caaf-47e7-a2f6-d1e59d80ad0e',
        value: 'CanViewDetailTCAllocation',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashAllocation.id,
        checked: false
    },
    CanAddTCAllocation: {
        id: 26,
        permissionName: 'Can Add Temporary Cash Allocation',
        key: 'b2e3ad95-60ce-4dcf-afc5-5dd1d3169078',
        value: 'CanAddTCAllocation',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashAllocation.id,
        checked: false
    },
    CanPrintTCAllocation: {
        id: 26,
        permissionName: 'Can Print Temporary Cash Allocation',
        key: '699bbe4a-13e5-4e64-9126-e17e402767aa',
        value: 'CanPrintTCAllocation',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashAllocation.id,
        checked: false
    },
    CanAttacmentTCAllocation: {
        id: 26,
        permissionName: 'Can Attachment Temporary Cash Allocation',
        key: '6189a8d5-7c12-4e8d-b0df-4349c6da21fb',
        value: 'CanAttacmentTCAllocation',
        moduleId: modules.Accounting.id,
        typeId: permissionType.TemporaryCashAllocation.id,
        checked: false
    },







    //Currency
    CanViewCurrency: {
        id: 26,
        permissionName: 'Can View Currency',
        key: '7fc860fa-031d-4912-85ce-506d4bf6ce33',
        value: 'CanViewCurrency',
        moduleId: modules.Accounting.id,
        typeId: permissionType.Currency.id,
        checked: false
    },
    CanEditCurrency: {
        id: 26,
        permissionName: 'Can Edit Currency',
        key: 'f15696ee-9366-430f-b390-3b521e759a4d',
        value: 'CanEditCurrency',
        moduleId: modules.Accounting.id,
        typeId: permissionType.Currency.id,
        checked: false
    },
    CanAddCurrency: {
        id: 26,
        permissionName: 'Can Add Currency',
        key: '63150c6e-fdbb-453d-907f-fc61e7fd121c',
        value: 'CanAddCurrency',
        moduleId: modules.Accounting.id,
        typeId: permissionType.Currency.id,
        checked: false
    },

    //Bank
    CanViewBank: {
        id: 26,
        permissionName: 'Can View Bank',
        key: 'f6ccd07a-2788-4df0-a247-9b4115909056',
        value: 'CanViewBank',
        moduleId: modules.Accounting.id,
        typeId: permissionType.Bank.id,
        checked: false
    },
    CanEditBank: {
        id: 26,
        permissionName: 'Can Edit Bank',
        key: '344b8791-1b5d-4d84-a9ea-874f3842c639',
        value: 'CanEditBank',
        moduleId: modules.Accounting.id,
        typeId: permissionType.Bank.id,
        checked: false
    },
    CanAddBank: {
        id: 26,
        permissionName: 'Can Add Bank',
        key: '6a19f3b9-b075-475b-a475-76a11d609ae8',
        value: 'CanAddBank',
        moduleId: modules.Accounting.id,
        typeId: permissionType.Bank.id,
        checked: false
    },


    //Monthly Cost
    CanAddMonthlyCost: {
        id: 26,
        permissionName: 'Can Add Monthly Cost',
        key: '6d123392-1419-4216-b6a0-e222a22af609',
        value: 'CanAddMonthlyCost',
        moduleId: modules.Accounting.id,
        typeId: permissionType.MonthlyCost.id,
        checked: false
    },

    //Chart of Account
    CanViewCOA: {
        id: 26,
        permissionName: 'Can View COA',
        key: '9fd4508d-576b-4709-a4d7-0b7836af1111',
        value: 'CanViewCOA',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ChartOfAccount.id,
        checked: false
    },
    CanEditCOA: {
        id: 26,
        permissionName: 'Can Edit COA',
        key: 'cad8f568-a8e9-4e97-9bb4-e53ccc9c83df',
        value: 'CanEditCOA',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ChartOfAccount.id,
        checked: false
    },
    CanAddCOA: {
        id: 26,
        permissionName: 'Can Add COA',
        key: 'f80c0094-60e2-4c96-9c73-05e539c32fb2',
        value: 'CanAddCOA',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ChartOfAccount.id,
        checked: false
    },

    CashManagement: {
        id: 26,
        permissionName: 'Cash Management',
        key: '03c116d9-4b71-484a-9b23-e78ef7580094',
        value: 'CashManagement',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ChartOfAccount.id,
        checked: false
    },
    AccountGatePass: {
        id: 26,
        permissionName: 'Account Gate Pass',
        key: '7beadb1f-75f1-4524-a121-846abcf9e01b',
        value: 'AccountGatePass',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ChartOfAccount.id,
        checked: false
    },
    AccountReport: {
        id: 26,
        permissionName: 'Account Report',
        key: 'd89419f2-5e6b-4ffa-b705-1f6fed51a6b0',
        value: 'AccountReport',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ChartOfAccount.id,
        checked: false
    },
    CashierManagement: {
        id: 26,
        permissionName: 'Cashier Management',
        key: '012d5807-6727-494a-98e7-e6ef69431bc6',
        value: 'CashierManagement',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ChartOfAccount.id,
        checked: false
    },
    RecurringInvoices: {
        id: 26,
        permissionName: 'Recurring Invoices',
        key: '7fcaf81d-38ce-4adc-930f-967003f0f413',
        value: 'RecurringInvoices',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ChartOfAccount.id,
        checked: false
    },
    RecurringPayments: {
        id: 26,
        permissionName: 'Recurring Payments',
        key: '2ad9dd4e-7e1c-49d1-ac33-f387ba72b8d8',
        value: 'RecurringPayments',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ChartOfAccount.id,
        checked: false
    },



    //Payment Option
    CanViewPaymentOption: {
        id: 26,
        permissionName: 'Can View Payment Option',
        key: '9f81ba11-bfcc-464d-9918-943604ea493a',
        value: 'CanViewPaymentOption',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PaymentOptions.id,
        checked: false
    },
    CanEditPaymentOption: {
        id: 26,
        permissionName: 'Can Edit Payment Option',
        key: '57a9b499-d4e1-4d81-a500-705a5a712695',
        value: 'CanEditPaymentOption',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PaymentOptions.id,
        checked: false
    },
    CanAddPaymentOption: {
        id: 26,
        permissionName: 'Can Add Payment Option',
        key: '037f0270-897f-42ad-8928-1154ccc851de',
        value: 'CanAddPaymentOption',
        moduleId: modules.Accounting.id,
        typeId: permissionType.PaymentOptions.id,
        checked: false
    },

    //Denomination Setup
    CanViewDenomination: {
        id: 26,
        permissionName: 'Can View Denomination',
        key: 'f4a962ee-e004-4a82-a10a-68f4dc48437b',
        value: 'CanViewDenomination',
        moduleId: modules.Accounting.id,
        typeId: permissionType.DenominationSetup.id,
        checked: false
    },
    CanEditDenomination: {
        id: 26,
        permissionName: 'Can Edit Denomination',
        key: 'a2ca0b6d-3601-4a44-9bec-5c8fd549a869',
        value: 'CanEditDenomination',
        moduleId: modules.Accounting.id,
        typeId: permissionType.DenominationSetup.id,
        checked: false
    },
    CanAddDenomination: {
        id: 26,
        permissionName: 'Can Add Denomination',
        key: '18527374-a4c1-4dcf-b201-d321d4c3030e',
        value: 'CanAddDenomination',
        moduleId: modules.Accounting.id,
        typeId: permissionType.DenominationSetup.id,
        checked: false
    },

    //Vat Rate
    CanViewVatRate: {
        id: 26,
        permissionName: 'Can View Vat Rate',
        key: '47d7976d-3344-49f5-8393-219b3e14c100',
        value: 'CanViewVatRate',
        moduleId: modules.Accounting.id,
        typeId: permissionType.VatRate.id,
        checked: false
    },
    CanEditVatRate: {
        id: 26,
        permissionName: 'Can Edit Vat Rate',
        key: 'f90c5c58-2468-40d9-bc3b-4ebe19b9a853',
        value: 'CanEditVatRate',
        moduleId: modules.Accounting.id,
        typeId: permissionType.VatRate.id,
        checked: false
    },
    CanAddVatRate: {
        id: 26,
        permissionName: 'Can Add Vat Rate',
        key: '5a1194b9-e866-4b10-a524-313020b057f6',
        value: 'CanAddVatRate',
        moduleId: modules.Accounting.id,
        typeId: permissionType.VatRate.id,
        checked: false
    },

    //Expense Type
    CanViewExpenseType: {
        id: 26,
        permissionName: 'Can View Expense Type',
        key: '4fbaae26-345b-4134-afb8-2db5764810be',
        value: 'CanViewExpenseType',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ExpenseType.id,
        checked: false
    },
    CanEditExpenseType: {
        id: 26,
        permissionName: 'Can Edit Expense Type',
        key: '4285b4f4-128f-483f-a981-65cdd7a95927',
        value: 'CanEditExpenseType',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ExpenseType.id,
        checked: false
    },
    CanAddExpenseType: {
        id: 26,
        permissionName: 'Can Add Expense Type',
        key: '56ec386b-0287-4613-9bf1-4a391b0f091b',
        value: 'CanAddExpenseType',
        moduleId: modules.Accounting.id,
        typeId: permissionType.ExpenseType.id,
        checked: false
    },

    //Financial Year
    CanViewFinancialYear: {
        id: 26,
        permissionName: 'Can View Financial Year',
        key: 'b720d357-7a3c-4e0d-9b0a-ff4132ebfc98',
        value: 'CanViewFinancialYear',
        moduleId: modules.Accounting.id,
        typeId: permissionType.FinancialYear.id,
        checked: false
    },
    CanCreateFinancialYear: {
        id: 26,
        permissionName: 'Can Create Financial Year',
        key: 'e17024e7-3a5f-4127-898b-9174a0517d3c',
        value: 'CanCreateFinancialYear',
        moduleId: modules.Accounting.id,
        typeId: permissionType.FinancialYear.id,
        checked: false
    },

    //Reporting Module:
    CanViewStockReport: {
        id: 26,
        permissionName: 'Can View Stock Report',
        key: 'f69dc42e-483c-4622-ad23-19b6f678e2b2',
        value: 'CanViewStockReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewSaleInvoiceReport: {
        id: 26,
        permissionName: 'Can View Sale Invoice Report',
        key: 'e8cc1f70-b5b1-4954-b348-bbcbb9f6730b',
        value: 'CanViewSaleInvoiceReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewPurchaseInvoiceReport: {
        id: 26,
        permissionName: 'Can View Purchase Invoice Report',
        key: 'ba56bbc0-df15-45ae-b2c1-9eef04a9ea63',
        value: 'CanViewPurchaseInvoiceReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewInventoryReport: {
        id: 26,
        permissionName: 'Can View Inventory Report',
        key: '571f8852-aacf-444f-8253-f4df11bd8bc3',
        value: 'CanViewInventoryReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewProductInventoryReport: {
        id: 26,
        permissionName: 'Can View Product Inventory Report',
        key: 'bc2f1021-26ca-484f-ad11-a538cbf50034',
        value: 'CanViewProductInventoryReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewTrialBalance: {
        id: 26,
        permissionName: 'Can View Trial Balance',
        key: 'edf50485-ae99-4ceb-ae08-df98e92b3d37',
        value: 'CanViewTrialBalance',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewBalanceSheetReport: {
        id: 26,
        permissionName: 'Can View Balance Sheet Report',
        key: '2980b46f-9357-4361-8be0-c048560df9be',
        value: 'CanViewBalanceSheetReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewIncomeStatementReport: {
        id: 26,
        permissionName: 'Can View Income Statement Report',
        key: '88413034-3299-4d2a-8ce4-7fc3493187b4',
        value: 'CanViewIncomeStatementReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewCustomerLedger: {
        id: 26,
        permissionName: 'Can View Customer Ledger',
        key: '11c249c7-85b9-49ba-814d-00ad8f003ec4',
        value: 'CanViewCustomerLedger',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewSupplierLedger: {
        id: 26,
        permissionName: 'Can View Supplier Ledger',
        key: 'b89dfc34-9284-49c2-9331-ab54ea6af09b',
        value: 'CanViewSupplieLedger',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewStockValueReport: {
        id: 26,
        permissionName: 'Can View Stock Value Report',
        key: '57f2f331-810e-488c-94ff-b04cd921a8f8',
        value: 'CanViewStockValueReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewStockAverageValue: {
        id: 26,
        permissionName: 'Can View Stock Average Value',
        key: 'c7562f39-7f63-46fc-a3ab-f389d6ada1d6',
        value: 'CanViewStockAverageValue',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewTransactionTypeStock: {
        id: 26,
        permissionName: 'Can View Transaction Type Stock',
        key: '02875c0b-a76d-4768-96a5-bf09ed1a3478',
        value: 'CanViewTransactionTypeStock',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewCustomerWiseProductsSale: {
        id: 26,
        permissionName: 'Can View Customer Wise Products Sale',
        key: 'd4e84f63-28ec-4163-80d5-8ba32efac973',
        value: 'CanViewCustomerWiseProductsSale',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewCustomersWiseProductSale: {
        id: 26,
        permissionName: 'Can View Customers Wise Product Sale',
        key: 'e07dc8df-e151-4ed7-89c0-3fc8b03268a3',
        value: 'CanViewCustomersWiseProductSale',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewSupplierWiseProductsPurchase: {
        id: 26,
        permissionName: 'Can View Supplier Wise Products Purchase',
        key: '5a26e7af-0a54-459a-9331-3ce627185afb',
        value: 'CanViewSupplierWiseProductsPurchase',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewSuppliersWiseProductPurchase: {
        id: 26,
        permissionName: 'Can View Suppliers Wise Product Purchase',
        key: 'a69f2aa6-11c0-4966-9411-bca6ef781a6c',
        value: 'CanViewSuppliersWiseProductPurchase',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewCustomerDiscountProducts: {
        id: 26,
        permissionName: 'Can View Customer Discount Products',
        key: '895ded44-106d-48de-8dc8-d11a5d06db6e',
        value: 'CanViewCustomerDiscountProducts',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewSupplierDiscountProducts: {
        id: 26,
        permissionName: 'Can View Supplier Discount Products',
        key: '14a02533-5595-446f-99b9-33b9d7d29981',
        value: 'CanViewSupplierDiscountProducts',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewProductDiscountCustomer: {
        id: 26,
        permissionName: 'Can View Product Discount Customer',
        key: 'd7597e3f-a5af-4a42-adfe-c357461720fa',
        value: 'CanViewProductDiscountCustomer',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewProductDiscountSupplier: {
        id: 26,
        permissionName: 'Can View Product Discount Supplier',
        key: 'e37536af-339d-4487-9fe5-883ed9b1fd2a',
        value: 'CanViewProductDiscountSupplier',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewFreeOfCostPurchase: {
        id: 26,
        permissionName: 'Can View Free Of Cost Purchase',
        key: '0627cb0b-d3df-44d5-9d12-9917cc2cc9fa',
        value: 'CanViewFreeOfCostPurchase',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewFreeOfCostSale: {
        id: 26,
        permissionName: 'Can View Free Of Cost Sale',
        key: 'f34c9282-adb9-40f0-9db2-450e92800108',
        value: 'CanViewFreeOfCostSale',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewAccountLedger: {
        id: 26,
        permissionName: 'Can View Account Ledger',
        key: '7f66ffd0-5b24-4e0e-b233-5cba9491ce14',
        value: 'CanViewAccountLedger',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewBanTransaction: {
        id: 26,
        permissionName: 'Can View Ban Transaction',
        key: 'fb556fc5-183d-4ac0-8a4b-0b561daec75b',
        value: 'CanViewBanTransaction',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewCustomerBalance: {
        id: 26,
        permissionName: 'Can View Customer Balance',
        key: 'ac313b52-ead8-46bb-869d-4c8eeabcc777',
        value: 'CanViewCustomerBalance',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewSupplierBalance: {
        id: 26,
        permissionName: 'Can View Supplier Balance',
        key: '13c1f57e-d05d-4498-9b36-f4f9b49c0a44',
        value: 'CanViewSupplierBalance',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewVatPayableReport: {
        id: 26,
        permissionName: 'Can View Vat Payable Report',
        key: '8bac4c17-58c3-4d17-906a-037c8352656a',
        value: 'CanViewVatPayableReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewDayWiseTransactions: {
        id: 26,
        permissionName: 'Can View DayWise Transactions',
        key: '30e15025-3e89-4253-9608-f746a31b570d',
        value: 'CanViewDayWiseTransactions',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewDayWiseReport: {
        id: 26,
        permissionName: 'Can View DayWise Report',
        key: '16a25182-f2f2-4722-958d-9ee865fab3a2',
        value: 'CanViewDayWiseReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanViewTCAllocationReport: {
        id: 26,
        permissionName: 'Can View Temporary Cash Allocation Report',
        key: 'fdfdcda2-f570-4fb3-92b8-6445fa5f099e',
        value: 'CanViewTCAllocationReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintStockReport: {
        id: 26,
        permissionName: 'Can Print Stock Report',
        key: 'a4fa64ab-b284-4fc9-bfbb-f8645074bc77',
        value: 'CanPrintStockReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintSaleInvoiceReport: {
        id: 26,
        permissionName: 'Can Print Sale Invoice Report',
        key: '1b2aa63b-7162-49cb-a7db-39f7a6cf59e3',
        value: 'CanPrintSaleInvoiceReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintPurchaseInvoiceReport: {
        id: 26,
        permissionName: 'Can Print Purchase Invoice Report',
        key: 'd6156d8d-e055-4341-b969-0939fee91d93',
        value: 'CanPrintPurchaseInvoiceReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintInventoryReport: {
        id: 26,
        permissionName: 'Can Print Inventory Report',
        key: '77a154c2-4c93-49f3-a615-80deac56b388',
        value: 'CanPrintInventoryReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintProductInventoryReport: {
        id: 26,
        permissionName: 'Can Print Product Inventory Report',
        key: '84d72c6b-1444-4320-a0bf-cef45ae396a0',
        value: 'CanPrintProductInventoryReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintTrialBalance: {
        id: 26,
        permissionName: 'Can Print Trial Balance',
        key: '344549f0-54e0-41e2-9c5e-70af6550749c',
        value: 'CanPrintTrialBalance',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintBalanceSheetReport: {
        id: 26,
        permissionName: 'Can Print Balance Sheet Report',
        key: 'd6cd0ef8-79d1-4136-a54b-2b12bccf687b',
        value: 'CanPrintBalanceSheetReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintIncomeStatementReport: {
        id: 26,
        permissionName: 'Can Print Income Statement Report',
        key: 'ecdcf64d-4cea-4fb9-bbfb-fab4bdcab55c',
        value: 'CanPrintIncomeStatementReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintCustomerLedger: {
        id: 26,
        permissionName: 'Can Print Customer Ledger',
        key: 'b13a8c24-27cd-4f7c-863e-f2f1766c6911',
        value: 'CanPrintCustomerLedger',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintSupplierLedger: {
        id: 26,
        permissionName: 'Can Print Supplier Ledger',
        key: '7ed77bc5-87db-4c08-b0f1-7285c3b676b0',
        value: 'CanPrintSupplieLedger',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintStockValueReport: {
        id: 26,
        permissionName: 'Can Print Stock Value Report',
        key: '45bd5690-8e50-40d3-bda3-d8f950decb6f',
        value: 'CanPrintStockValueReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintStockAverageValue: {
        id: 26,
        permissionName: 'Can Print Stock Average Value',
        key: '90848d48-570b-47a3-8450-c623e735b69f',
        value: 'CanPrintStockAverageValue',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintTransactionTypeStock: {
        id: 26,
        permissionName: 'Can Print Transaction Type Stock',
        key: '7947bcf1-cc2f-4160-9be9-47b8600477de',
        value: 'CanPrintTransactionTypeStock',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintCustomerWiseProductsSale: {
        id: 26,
        permissionName: 'Can Print Customer Wise Products Sale',
        key: 'f42694ba-609d-4590-9d2e-788d3f8e4d87',
        value: 'CanPrintCustomerWiseProductsSale',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintCustomersWiseProductSale: {
        id: 26,
        permissionName: 'Can Print Customers Wise Product Sale',
        key: 'c612a135-989b-4af6-a2a6-6c897f72836b',
        value: 'CanPrintCustomersWiseProductSale',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintSupplierWiseProductsPurchase: {
        id: 26,
        permissionName: 'Can Print Supplier Wise Products Purchase',
        key: '03d16b7d-f866-4c17-b6bd-558721f14121',
        value: 'CanPrintSupplierWiseProductsPurchase',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintSuppliersWiseProductPurchase: {
        id: 26,
        permissionName: 'Can Print Suppliers Wise Product Purchase',
        key: '0e8137af-67aa-47ed-a25a-229abbc28342',
        value: 'CanPrintSuppliersWiseProductPurchase',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintCustomerDiscountProducts: {
        id: 26,
        permissionName: 'Can Print Customer Discount Products',
        key: '3ea7ccc2-688a-43bd-8621-ecb4ce2d98c9',
        value: 'CanPrintCustomerDiscountProducts',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintSupplierDiscountProducts: {
        id: 26,
        permissionName: 'Can Print Supplier Discount Products',
        key: '65f09c23-15c9-4470-80c7-4cc082c60ae4',
        value: 'CanPrintSupplierDiscountProducts',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintProductDiscountCustomer: {
        id: 26,
        permissionName: 'Can Print Product Discount Customer',
        key: 'ad0e76ff-e11b-4814-8f46-3016e4307565',
        value: 'CanPrintProductDiscountCustomer',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintProductDiscountSupplier: {
        id: 26,
        permissionName: 'Can Print Product Discount Supplier',
        key: '02814a6a-a20e-421d-8ad4-408d9c0cbf3e',
        value: 'CanPrintProductDiscountSupplier',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintFreeOfCostPurchase: {
        id: 26,
        permissionName: 'Can Print Free Of Cost Purchase',
        key: 'e77319e2-d51b-4589-bdea-7d8b602467cd',
        value: 'CanPrintFreeOfCostPurchase',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintFreeOfCostSale: {
        id: 26,
        permissionName: 'Can Print Free Of Cost Sale',
        key: '36be4e23-3535-43b9-a3c6-694bb5086544',
        value: 'CanPrintFreeOfCostSale',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintAccountLedger: {
        id: 26,
        permissionName: 'Can Print Account Ledger',
        key: '95141885-8268-498d-aacd-75207785bef0',
        value: 'CanPrintAccountLedger',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintBanTransaction: {
        id: 26,
        permissionName: 'Can Print Ban Transaction',
        key: '243fdaf8-49da-4e93-b816-0afdb018c40f',
        value: 'CanPrintBanTransaction',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintCustomerBalance: {
        id: 26,
        permissionName: 'Can Print Customer Balance',
        key: 'd9ca5c3a-ff17-41bb-825b-98ab37a3a30e',
        value: 'CanPrintCustomerBalance',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintSupplierBalance: {
        id: 26,
        permissionName: 'Can Print Supplier Balance',
        key: '3c72e89d-552a-4346-aad3-3e7c7e284139',
        value: 'CanPrintSupplierBalance',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintVatPayableReport: {
        id: 26,
        permissionName: 'Can Print Vat Payable Report',
        key: '151bdbdc-b126-470d-baff-3d7fbae53685',
        value: 'CanPrintVatPayableReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintDayWiseTransactions: {
        id: 26,
        permissionName: 'Can Print DayWise Transactions',
        key: 'cd58c8f1-e073-41ac-8875-03d97256ef2f',
        value: 'CanPrintDayWiseTransactions',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintDayWiseReport: {
        id: 26,
        permissionName: 'Can Print DayWise Report',
        key: '6d737204-cd57-40ed-9606-1088599590ad',
        value: 'CanPrintDayWiseReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    CanPrintTCAllocationReport: {
        id: 26,
        permissionName: 'Can Print Temporary Cash Allocation Report',
        key: '55660bbd-37f4-43d5-9ad4-13bf500b3d9f',
        value: 'CanPrintTCAllocationReport',
        moduleId: modules.Reporting.id,
        typeId: permissionType.Reporting.id,
        checked: false
    },
    //Daily Expenses
    CanDraftExpense: {
        id: 26,
        permissionName: 'Can Draft Expense',
        key: 'c49011d6-627f-4084-b367-14813457c8c3',
        value: 'CanDraftExpense',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanViewExpense: {
        id: 26,
        permissionName: 'Can View Expense',
        key: '57a67ad4-87b4-4933-a9ff-c98dba9ea860',
        value: 'CanViewExpense',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanAddExpense: {
        id: 26,
        permissionName: 'Can Add Expense',
        key: '936573f6-de67-475a-bdff-cb89eb3531ca',
        value: 'CanAddExpense',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanEditExpense: {
        id: 26,
        permissionName: 'Can Edit Expense',
        key: '762b7f63-087c-4158-b3d8-a0f8dcd9ee1e',
        value: 'CanEditExpense',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanViewDetailExpense: {
        id: 26,
        permissionName: 'Can View Detail Expense',
        key: '9de9940c-f4b5-47ba-84c1-8b08d795a76e',
        value: 'CanViewDetailExpense',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanPrintExpense: {
        id: 26,
        permissionName: 'Can Print Expense',
        key: '76dce5cf-eb22-470d-80ba-79fe97080fa5',
        value: 'CanPrintExpense',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanDeleteExpense: {
        id: 26,
        permissionName: 'Can Delete Expense',
        key: '839a0062-66c0-45c9-970d-332354c6dafe',
        value: 'CanDeleteExpense',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanGenerateExpenseDetailPdf: {
        id: 26,
        permissionName: 'Can Generate Expense Detail Pdf',
        key: '591e6bfc-0913-448b-8fed-968b14ca8969',
        value: 'CanGenerateExpenseDetailPdf',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanGenerateExpenseDetailSheet: {
        id: 26,
        permissionName: 'Can Generate Expense Detail Sheet',
        key: '7a5e2a55-2bca-41ed-922c-c6e64dc27fb7',
        value: 'CanGenerateExpenseDetailSheet',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanSendExpenseDetailEmail: {
        id: 26,
        permissionName: 'Can Send Expense Detail Email',
        key: '921f61f7-0ebd-43a8-9ffa-a870a48e67e1',
        value: 'CanSendExpenseDetailEmail',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },
    CanUploadExpenseAttachment: {
        id: 26,
        permissionName: 'Can Upload Expense Attachment',
        key: 'ab057c4b-b0d9-4359-9eba-c7108cc7a5ad',
        value: 'CanUploadExpenseAttachment',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },

    ExpenseSetup: {
        id: 26,
        permissionName: 'Expense Setup',
        key: '9b5f8ebc-c7ae-4944-8f9e-84636e4f43d0',
        value: 'ExpenseSetup',
        moduleId: modules.Expenses.id,
        typeId: permissionType.DailyExpense.id,
        checked: false
    },

    //Expenses Bill
    CanDraftExpenseBill: {
        id: 26,
        permissionName: 'Can Draft Expense Bill',
        key: '3d3ed785-40f5-4ba6-857c-e427d83f0cbe',
        value: 'CanDraftExpenseBill',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },
    CanViewExpenseBill: {
        id: 26,
        permissionName: 'Can View Expense Bill',
        key: 'a62c9b18-6f86-4bda-ba90-f7b1a44c3208',
        value: 'CanViewExpenseBill',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },
    CanAddExpenseBill: {
        id: 26,
        permissionName: 'Can Add Expense Bill',
        key: '4d9cfc62-f51d-4146-86b0-ff73e2e2f274',
        value: 'CanAddExpenseBill',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },
    CanEditExpenseBill: {
        id: 26,
        permissionName: 'Can Edit Expense Bill',
        key: '1add0ae5-b92d-48d1-b220-78b5c47817ba',
        value: 'CanEditExpenseBill',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },
    CanViewDetailExpenseBill: {
        id: 26,
        permissionName: 'Can View Detail Expense Bill',
        key: 'a7db4a40-a2d5-46a8-ba94-e96164aa36d3',
        value: 'CanViewDetailExpenseBill',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },
    CanPrintExpenseBill: {
        id: 26,
        permissionName: 'Can Print Expense Bill',
        key: 'c7b9fed0-f375-4446-bb77-d0d3ef32d1ca',
        value: 'CanPrintExpenseBill',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },
    CanGenerateDetailPdfBill: {
        id: 26,
        permissionName: 'Can Generate Detail Pdf Bill',
        key: '8863e491-b834-4c54-8a5d-3931fcf2d8df',
        value: 'CanGenerateDetailPdfBill',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },
    CanGenerateDetailSheetBill: {
        id: 26,
        permissionName: 'Can Generate Detail Sheet Bill',
        key: 'cfdaa186-4b03-4db1-b193-9ed9861e39ed',
        value: 'CanGenerateDetailSheetBill',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },
    CanSendDetailBillEmail: {
        id: 26,
        permissionName: 'Can Send Detail Bill Email',
        key: 'c1f29b1c-65aa-453b-872a-98a386f6e65b',
        value: 'CanSendDetailBillEmail',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },
    CanPayExpenseBill: {
        id: 26,
        permissionName: 'Can Pay Expense Bill',
        key: '1e56c02d-e7b8-4221-80d9-fef275e33d72',
        value: 'CanPayExpenseBill',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },

    CanUploadExpenseBillAttachment: {
        id: 26,
        permissionName: 'Can Upload Expense Bill Attachment',
        key: '3f2fe1ea-1d92-407d-a8e1-ed694f4ebdf0',
        value: 'CanUploadExpenseBillAttachment',
        moduleId: modules.Expenses.id,
        typeId: permissionType.ExpenseBill.id,
        checked: false
    },



    //Ware House Management Module:
    //Ware House
    CanViewWareHouse: {
        id: 26,
        permissionName: 'Can View WareHouse',
        key: '8f2d0286-8d5f-4113-9067-9feca64529b5',
        value: 'CanViewWareHouse',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.WareHouse.id,
        checked: false
    },
    CanAddWareHouse: {
        id: 26,
        permissionName: 'Can Add WareHouse',
        key: '5f8e4727-01e9-4c11-9eca-90cf11582784',
        value: 'CanAddWareHouse',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.WareHouse.id,
        checked: false
    },
    CanEditWareHouse: {
        id: 26,
        permissionName: 'Can Edit WareHouse',
        key: '5588bebf-ff19-470d-94ee-00c22cec9ef2',
        value: 'CanEditWareHouse',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.WareHouse.id,
        checked: false
    },

    WarehouseGatePass: {
        id: 26,
        permissionName: 'Warehouse GatePass',
        key: '7a92aa38-b33a-4006-8837-36278264fd5b',
        value: 'WarehouseGatePass',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.WareHouse.id,
        checked: false
    },
    WareHouseReport: {
        id: 26,
        permissionName: 'WareHouse Report',
        key: '949294e3-1789-4b31-a68b-7b99f0643d3e',
        value: 'WareHouseReport',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.WareHouse.id,
        checked: false
    },

    //Stock Adj Out
    CanViewStockOut: {
        id: 26,
        permissionName: 'Can View StockOut',
        key: '532b0990-d77c-4fae-888e-8aee077f5494',
        value: 'CanViewStockOut',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockOut.id,
        checked: false
    },
    CanAddStockOut: {
        id: 26,
        permissionName: 'Can Add StockOut',
        key: 'c128d712-0195-4fc8-82db-cf35b65b2236',
        value: 'CanAddStockOut',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockOut.id,
        checked: false
    },
    CanEditStockOut: {
        id: 26,
        permissionName: 'Can Edit StockOut',
        key: 'ec8a60d8-b1a5-469d-a5ab-831111e39e49',
        value: 'CanEditStockOut',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockOut.id,
        checked: false
    },
    CanDraftStockOut: {
        id: 26,
        permissionName: 'Can Draft StockOut',
        key: '6ba7996e-e6d8-4cc9-831c-6777774c0a5c',
        value: 'CanDraftStockOut',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockOut.id,
        checked: false
    },
    CanPrintStockOut: {
        id: 26,
        permissionName: 'Can Print StockOut',
        key: 'ca3aad18-bf79-45f1-8562-cbf6e335d7ff',
        value: 'CanPrintStockOut',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockOut.id,
        checked: false
    },
    CanViewDetailStockOut: {
        id: 26,
        permissionName: 'Can View Detail StockOut',
        key: 'e4604934-566d-49fc-9b21-4e894bc2c710',
        value: 'CanViewDetailStockOut',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockOut.id,
        checked: false
    },
    CanGeneratePdfStockOut: {
        id: 26,
        permissionName: 'Can Generate Pdf StockOut',
        key: '46386617-888b-43f0-8e3e-07f8fe274bfa',
        value: 'CanGeneratePdfStockOut',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockOut.id,
        checked: false
    },
    CanGenerateSheetStockOut: {
        id: 26,
        permissionName: 'Can Generate Sheet StockOut',
        key: '630854b0-f819-442b-8d89-6cfc61f54ca2',
        value: 'CanGenerateSheetStockOut',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockOut.id,
        checked: false
    },
    CanSendEmailStockOut: {
        id: 26,
        permissionName: 'Can Send Email StockOut',
        key: '5bec486a-7968-4d59-95c4-9d9a74b71abe',
        value: 'CanSendEmailStockOut',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockOut.id,
        checked: false
    },

    //Stock Adj In
    CanViewStockIn: {
        id: 26,
        permissionName: 'Can View StockIn',
        key: 'a66bf166-b49e-4c47-bb19-14722c3729b8',
        value: 'CanViewStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },
    CanAddStockIn: {
        id: 26,
        permissionName: 'Can Add StockIn',
        key: '6ad27860-7292-46d8-95af-9b337da25355',
        value: 'CanAddStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },
    CanEditStockIn: {
        id: 26,
        permissionName: 'Can Edit StockIn',
        key: '106d8100-6227-4a3f-ad17-c555edba5fa0',
        value: 'CanEditStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },
    CanDraftStockIn: {
        id: 26,
        permissionName: 'Can Draft StockIn',
        key: 'f957f148-dbe6-4a89-bb6a-c561c307f896',
        value: 'CanDraftStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },
    CanPrintStockIn: {
        id: 26,
        permissionName: 'Can Print StockIn',
        key: 'bd05da88-9f0f-4464-9ecb-3496261a051f',
        value: 'CanPrintStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },
    CanViewDetailStockIn: {
        id: 26,
        permissionName: 'Can View Detail StockIn',
        key: '1f006a8c-8f14-4cf9-84d6-8a35f629fded',
        value: 'CanViewDetailStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },
    CanGeneratePdfStockIn: {
        id: 26,
        permissionName: 'Can Generate Pdf StockIn',
        key: '2545a9c7-e153-4de0-a05f-b8a943f7db0e',
        value: 'CanGeneratePdfStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },
    CanGenerateSheetStockIn: {
        id: 26,
        permissionName: 'Can Generate Sheet StockIn',
        key: '5323b1c6-84af-4555-987f-cc35b722be63',
        value: 'CanGenerateSheetStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },
    CanSendEmailStockIn: {
        id: 26,
        permissionName: 'Can Send Email StockIn',
        key: 'f61c03a2-7e0f-4e23-8e6e-5fb3f1283232',
        value: 'CanSendEmailStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },
    CanImportStockIn: {
        id: 26,
        permissionName: 'Can Import StockIn',
        key: '7da91595-125a-4596-9e9b-c3eec0145f8c',
        value: 'CanImportStockIn',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockIn.id,
        checked: false
    },

    //Stock Transfer
    CanViewStockTransfer: {
        id: 26,
        permissionName: 'Can View StockTransfer',
        key: 'e9cf7554-b11c-4450-be05-be8345874bfa',
        value: 'CanViewStockTransfer',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockTransfer.id,
        checked: false
    },
    CanAddStockTransfer: {
        id: 26,
        permissionName: 'Can Add StockTransfer',
        key: 'a8f80dd0-3dfc-4d49-80dd-8c9a64fbf03f',
        value: 'CanAddStockTransfer',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockTransfer.id,
        checked: false
    },
    CanEditStockTransfer: {
        id: 26,
        permissionName: 'Can Edit StockTransfer',
        key: 'f8f80dd0-3dfc-4d49-802e-8d9e64bbf04f',
        value: 'CanEditStockTransfer',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockTransfer.id,
        checked: false
    },
    CanDraftStockTransfer: {
        id: 26,
        permissionName: 'Can Draft StockTransfer',
        key: 'e8f80ee0-3ffc-4d49-80ad-8c9a64fbf04f',
        value: 'CanDraftStockTransfer',
        moduleId: modules.WareHouseManagement.id,
        typeId: permissionType.StockTransfer.id,
        checked: false
    },


    //Inventory Module:
    //Product
    CanViewProduct: {
        id: 26,
        permissionName: 'Can View Product',
        key: 'b2b94b4e-c7ed-48c4-8130-ebaee083c75d',
        value: 'CanViewProduct',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Product.id,
        checked: false
    },
    CanEditProduct: {
        id: 26,
        permissionName: 'Can Edit Product',
        key: '095db9f9-d046-4809-853c-157d5dacd1bd',
        value: 'CanEditProduct',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Product.id,
        checked: false
    },
    CanAddProduct: {
        id: 26,
        permissionName: 'Can Add Product',
        key: '4b45ae4b-285b-4e5f-82cb-b063e1240fae',
        value: 'CanAddProduct',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Product.id,
        checked: false
    },

    //Category
    CanViewCategory: {
        id: 26,
        permissionName: 'Can View Category',
        key: '683a2134-40eb-4c2f-8421-edd2d0354d7e',
        value: 'CanViewCategory',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Category.id,
        checked: false
    },
    CanEditCategory: {
        id: 26,
        permissionName: 'Can Edit Category',
        key: '6bd32b7e-1fb1-47f6-bab0-b48f3dd94405',
        value: 'CanEditCategory',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Category.id,
        checked: false
    },
    CanAddCategory: {
        id: 26,
        permissionName: 'Can Add Category',
        key: 'b6e4099d-e38f-487b-8fc0-f349ea180b6a',
        value: 'CanAddCategory',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Category.id,
        checked: false
    },

    //SubCategory
    CanViewSubCategory: {
        id: 26,
        permissionName: 'Can View SubCategory',
        key: '4d2ec446-ceca-47df-b816-0fb03f608924',
        value: 'CanViewSubCategory',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.SubCategory.id,
        checked: false
    },
    CanEditSubCategory: {
        id: 26,
        permissionName: 'Can Edit SubCategory',
        key: '89431f18-6eb0-4e7d-8017-b22160febc61',
        value: 'CanEditSubCategory',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.SubCategory.id,
        checked: false
    },
    CanAddSubCategory: {
        id: 26,
        permissionName: 'Can Add SubCategory',
        key: 'cdde0995-3b74-4e32-b912-7c80eaa17092',
        value: 'CanAddSubCategory',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.SubCategory.id,
        checked: false
    },

    //Brand
    CanViewBrand: {
        id: 26,
        permissionName: 'Can View Brand',
        key: '159656c8-8225-4f24-b2e2-cbad88416cd7',
        value: 'CanViewBrand',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Brand.id,
        checked: false
    },
    CanEditBrand: {
        id: 26,
        permissionName: 'Can Edit Brand',
        key: '232ff14e-390a-459c-ad42-a71db9cdd01c',
        value: 'CanEditBrand',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Brand.id,
        checked: false
    },
    CanAddBrand: {
        id: 26,
        permissionName: 'Can Add Brand',
        key: 'be584fdf-51bf-41c7-b99f-795158169bce',
        value: 'CanAddBrand',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Brand.id,
        checked: false
    },

    //Origin
    CanViewOrigin: {
        id: 26,
        permissionName: 'Can View Origin',
        key: '576254ae-4294-4902-8d1e-bea2d31290b3',
        value: 'CanViewOrigin',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Origin.id,
        checked: false
    },
    CanEditOrigin: {
        id: 26,
        permissionName: 'Can Edit Origin',
        key: 'a076d9fd-2e16-417f-bd3e-a4a61d7fd3dd',
        value: 'CanEditOrigin',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Origin.id,
        checked: false
    },
    CanAddOrigin: {
        id: 26,
        permissionName: 'Can Add Origin',
        key: '332c20b9-4b58-473a-a04d-e19f2677fd96',
        value: 'CanAddOrigin',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Origin.id,
        checked: false
    },

    //Size
    CanViewSize: {
        id: 26,
        permissionName: 'Can View Size',
        key: 'ccec968b-1813-4be1-8863-4e75436207cc',
        value: 'CanViewSize',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Size.id,
        checked: false
    },
    CanEditSize: {
        id: 26,
        permissionName: 'Can Edit Size',
        key: '8b2d55c0-7552-4691-8d6e-439796194e44',
        value: 'CanEditSize',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Size.id,
        checked: false
    },
    CanAddSize: {
        id: 26,
        permissionName: 'Can Add Size',
        key: '8a853845-88ce-44dc-a323-adca67366e0a',
        value: 'CanAddSize',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Size.id,
        checked: false
    },

    //Color
    CanViewColor: {
        id: 26,
        permissionName: 'Can View Color',
        key: '8f16807a-7513-4e40-91e7-9a49a8732713',
        value: 'CanViewColor',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Color.id,
        checked: false
    },
    CanEditColor: {
        id: 26,
        permissionName: 'Can Edit Color',
        key: 'b9b98218-1174-40cb-aa29-1f179fd01422',
        value: 'CanEditColor',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Color.id,
        checked: false
    },
    CanAddColor: {
        id: 26,
        permissionName: 'Can Add Color',
        key: '24d9f78a-a464-4fdf-8e9a-6a794c5f9477',
        value: 'CanAddColor',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Color.id,
        checked: false
    },

    //Unit
    CanViewUnit: {
        id: 26,
        permissionName: 'Can View Unit',
        key: 'c2342e05-24ae-4a29-b4dd-115f5fa993f3',
        value: 'CanViewUnit',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Unit.id,
        checked: false
    },
    CanEditUnit: {
        id: 26,
        permissionName: 'Can Edit Unit',
        key: '6b85432d-5b01-4065-a68e-4578e96799e6',
        value: 'CanEditUnit',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Unit.id,
        checked: false
    },
    CanAddUnit: {
        id: 26,
        permissionName: 'Can Add Unit',
        key: 'fb8b12e0-f164-4c0a-9c56-131be488ffba',
        value: 'CanAddUnit',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Unit.id,
        checked: false
    },

    //WarrantyType
    CanViewWarrantyType: {
        id: 26,
        permissionName: 'Can View Warranty Type',
        key: '54eb4ed9-f2e5-426b-9687-f38132861c00',
        value: 'CanViewWarrantyType',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.WarrantyType.id,
        checked: false
    },
    CanAddWarrantyType: {
        id: 26,
        permissionName: 'Can Add Warranty Type',
        key: '83e60b90-5c94-48fe-89e6-6cbf7dd5b90d',
        value: 'CanAddWarrantyType',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.WarrantyType.id,
        checked: false
    },
    CanEditWarrantyType: {
        id: 26,
        permissionName: 'Can Edit Warranty Type',
        key: '32a34b39-b732-4e34-acd3-92ca3bfb57e6',
        value: 'CanEditWarrantyType',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.WarrantyType.id,
        checked: false
    },

    //Item
    CanViewItem: {
        id: 26,
        permissionName: 'Can View Item',
        key: '998d9f96-88f7-4983-a96c-5f1cba5f89df',
        value: 'CanViewItem',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    CanEditItem: {
        id: 26,
        permissionName: 'Can Edit Item',
        key: 'a8b7a2a2-4980-4d61-8181-9de5182b96fe',
        value: 'CanEditItem',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    CanAddItem: {
        id: 26,
        permissionName: 'Can Add Item',
        key: '5e65e6f0-5196-42e7-89ad-f794ad297495',
        value: 'CanAddItem',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    CanViewDetailItem: {
        id: 26,
        permissionName: 'Can View Detail Item',
        key: '404ab129-ac50-42ae-b096-663e1b60a84c',
        value: 'CanViewDetailItem',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    CanViewCurrentStock: {
        id: 26,
        permissionName: 'Can View Current Stock',
        key: '354f18bc-9bb4-4b78-aa9a-097ae5ceb659',
        value: 'CanViewCurrentStock',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    CanFilterItem: {
        id: 26,
        permissionName: 'Can Filter Item',
        key: '2ea1120e-fa2d-4fca-9758-9eb1b300d2ac',
        value: 'CanFilterItem',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    CanImportProduct: {
        id: 26,
        permissionName: 'Can Import Product',
        key: 'a9c54658-6aee-410b-a79e-84e4972a304a',
        value: 'CanImportProduct',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    CanAddLimitedProduct: {
        id: 26,
        permissionName: 'Can Add Limited Product',
        key: '48f9cf99-6077-4ac5-a78e-24d864251ddb',
        value: 'CanAddLimitedProduct',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    QuickItemRegistration: {
        id: 26,
        permissionName: 'Quick Item Registration',
        key: 'bc2ca0a7-2aaa-4be5-a9e8-cf6eeec83a89',
        value: 'QuickItemRegistration',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },



    InventoryReports: {
        id: 26,
        permissionName: 'Inventory Reports',
        key: '144410e0-2785-4c9f-89eb-194f99aa7ddb',
        value: 'InventoryReports',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    PriceManagement: {
        id: 26,
        permissionName: 'Price Management',
        key: '039a544e-e675-4370-9e21-b0eaee4a47bb',
        value: 'PriceManagement',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },
    InventoryCountManagement: {
        id: 26,
        permissionName: 'Inventory Count Management',
        key: 'a59c25d7-f5e9-4e0d-aab1-f36dde96f171',
        value: 'InventoryCountManagement',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.Item.id,
        checked: false
    },

    //Inventory Count List
    CanViewInventoryCount: {
        id: 26,
        permissionName: 'Can View Inventory Count',
        key: '3c589186-afe5-4ef3-8b9b-3d1364946ae1',
        value: 'CanViewInventoryCount',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.InventoryCount.id,
        checked: false
    },
    CanEditInventoryCount: {
        id: 26,
        permissionName: 'Can Edit Inventory Count',
        key: '003c2b95-8471-4c1a-95d9-2a5d7188b30d',
        value: 'CanEditInventoryCount',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.InventoryCount.id,
        checked: false
    },
    CanAddInventoryCount: {
        id: 26,
        permissionName: 'Can Add Inventory Count',
        key: '4dcf94f7-b6f0-49ff-9848-038a483893fe',
        value: 'CanAddInventoryCount',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.InventoryCount.id,
        checked: false
    },
    CanViewDetailInventoryCount: {
        id: 26,
        permissionName: 'Can View Detail Inventory Count',
        key: 'a9de8ca6-d7e7-4a07-90df-b984765838b4',
        value: 'CanViewDetailInventoryCount',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.InventoryCount.id,
        checked: false
    },
    CanPrintInventoryCount: {
        id: 26,
        permissionName: 'Can Print Inventory Count',
        key: '70ca644a-5681-4bf4-aea1-5d8ff1f4c366',
        value: 'CanPrintInventoryCount',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.InventoryCount.id,
        checked: false
    },






    //Promotion Offer
    CanViewPromotionOffer: {
        id: 26,
        permissionName: 'Can View Promotion Offer',
        key: 'e648e1b6-7bcf-4d7e-b7b0-eef032429019',
        value: 'CanViewPromotionOffer',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.PromotionOffer.id,
        checked: false
    },
    CanAddPromotionOffer: {
        id: 26,
        permissionName: 'Can Add Promotion Offer',
        key: 'd0e8b1b4-2dae-4411-96dd-474bca982bc2',
        value: 'CanAddPromotionOffer',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.PromotionOffer.id,
        checked: false
    },
    CanEditPromotionOffer: {
        id: 26,
        permissionName: 'Can Edit Promotion Offer',
        key: '0bebcabd-b79f-48e1-99fd-a5c9caeebd36',
        value: 'CanEditPromotionOffer',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.PromotionOffer.id,
        checked: false
    },
    PromotionOfferSetup: {
        id: 26,
        permissionName: 'Promotion Offer Setup',
        key: 'e6efb09f-b19c-4db8-a105-2e98d9a484f6',
        value: 'PromotionOfferSetup',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.PromotionOffer.id,
        checked: false
    },
    PromotionOfferReport: {
        id: 26,
        permissionName: 'Promotion Offer Report',
        key: '1eb42d0b-99cb-402b-8954-b119d4da9fb7',
        value: 'PromotionOfferReport',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.PromotionOffer.id,
        checked: false
    },

    //Bundle Offer
    CanViewBundleOffer: {
        id: 26,
        permissionName: 'Can View Bundle Offer',
        key: '34c4b2d8-0c76-45d6-b314-62d4c3344f0b',
        value: 'CanViewBundleOffer',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.BundleOffer.id,
        checked: false
    },
    CanAddBundleOffer: {
        id: 26,
        permissionName: 'Can Add Bundle Offer',
        key: 'f2cce71d-2150-47a4-899f-d83e05f046f4',
        value: 'CanAddBundleOffer',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.BundleOffer.id,
        checked: false
    },
    CanEditBundleOffer: {
        id: 26,
        permissionName: 'Can Edit Bundle Offer',
        key: '1279c3d8-187f-4b63-b89f-15f46c850f63',
        value: 'CanEditBundleOffer',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.BundleOffer.id,
        checked: false
    },
    //Barcode Setup
    CanPrintRackBarcode: {
        id: 26,
        permissionName: 'Can Print Rack Barcode',
        key: '85e1233b-2882-45e7-8b09-32d232f61b88',
        value: 'CanPrintRackBarcode',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.BarcodeSetup.id,
        checked: false
    },
    CanPrintItemBarcode: {
        id: 26,
        permissionName: 'Can Print Item Barcode',
        key: 'db44b9bb-44ba-483c-b5fd-8f8ede017a0c',
        value: 'CanPrintItemBarcode',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.BarcodeSetup.id,
        checked: false
    },

    NewModules: {
        id: 26,
        permissionName: 'New Modules',
        key: '63e232ab-cd6b-4c87-9e05-25ce6d1ed858',
        value: 'NewModules',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.BarcodeSetup.id,
        checked: false
    },

    InventoryItemLookUp: {
        id: 26,
        permissionName: 'Inventory Item LookUp',
        key: '55df1c4c-3d65-4033-8f7f-d1c49ad5f0f3',
        value: 'InventoryItemLookUp',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.BarcodeSetup.id,
        checked: false
    },
    EmailManagement: {
        id: 26,
        permissionName: 'Email Management',
        key: '712de576-abd3-4b7d-b4e1-f57f93d8a2e0',
        value: 'EmailManagement',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.BarcodeSetup.id,
        checked: false
    },
    SmsManagement: {
        id: 26,
        permissionName: 'Sms Management',
        key: '7bf7b94b-9705-44b1-9c70-53a8a8df866f',
        value: 'SmsManagement',
        moduleId: modules.ProductAndInventoryManagement.id,
        typeId: permissionType.BarcodeSetup.id,
        checked: false
    },

    //Logistic Module:
    //Transporter
    CanAddTransporter: {
        id: 26,
        permissionName: 'Can Add Transporter',
        key: '052e3bd2-13b7-48cc-b6c7-3514cadcc10d',
        value: 'CanAddTransporter',
        moduleId: modules.Logistic.id,
        typeId: permissionType.Transporter.id,
        checked: false
    },
    CanEditTransporter: {
        id: 26,
        permissionName: 'Can Edit Transporter',
        key: 'ccabafd4-f064-49af-b7f8-8a8c26c8c74a',
        value: 'CanEditTransporter',
        moduleId: modules.Logistic.id,
        typeId: permissionType.Transporter.id,
        checked: false
    },
    CanViewTransporter: {
        id: 26,
        permissionName: 'Can View Transporter',
        key: '91a9d951-ddf4-4a2c-935b-48d426f5c3de',
        value: 'CanViewTransporter',
        moduleId: modules.Logistic.id,
        typeId: permissionType.Transporter.id,
        checked: false
    },

    //Clearance Agent
    CanAddClearanceAgent: {
        id: 26,
        permissionName: 'Can Add Clearance Agent',
        key: '245d7394-d666-42cd-8df3-874a2be1bd39',
        value: 'CanAddClearanceAgent',
        moduleId: modules.Logistic.id,
        typeId: permissionType.ClearanceAgent.id,
        checked: false
    },
    CanEditClearanceAgent: {
        id: 26,
        permissionName: 'Can Edit Clearance Agent',
        key: '0d64bd5e-98b2-408c-9ee0-0600b52e23d6',
        value: 'CanEditClearanceAgent',
        moduleId: modules.Logistic.id,
        typeId: permissionType.ClearanceAgent.id,
        checked: false
    },
    CanViewClearanceAgent: {
        id: 26,
        permissionName: 'Can View Clearance Agent',
        key: 'c3e28469-1726-444c-9b96-9fbd1ca840ef',
        value: 'CanViewClearanceAgent',
        moduleId: modules.Logistic.id,
        typeId: permissionType.ClearanceAgent.id,
        checked: false
    },

    //Shipping Liner
    CanAddShippingLiner: {
        id: 26,
        permissionName: 'Can Add Shipping Liner',
        key: '5061552b-69d9-46dc-bfdd-d4cdc7a98241',
        value: 'CanAddShippingLiner',
        moduleId: modules.Logistic.id,
        typeId: permissionType.ShippingLiner.id,
        checked: false
    },
    CanEditShippingLiner: {
        id: 26,
        permissionName: 'Can Edit Shipping Liner',
        key: '013668f4-321b-430a-a206-822a12ccf9dc',
        value: 'CanEditShippingLiner',
        moduleId: modules.Logistic.id,
        typeId: permissionType.ShippingLiner.id,
        checked: false
    },
    CanViewShippingLiner: {
        id: 26,
        permissionName: 'Can View Shipping Liner',
        key: '457f7828-5c43-4150-9e87-bb25dd2fb56d',
        value: 'CanViewShippingLiner',
        moduleId: modules.Logistic.id,
        typeId: permissionType.ShippingLiner.id,
        checked: false
    },



    //Setup And Configuration Module:
    //Company Info
    CanUpdateCompanyInfo: {
        id: 26,
        permissionName: 'Can Update CompanyInfo',
        key: 'a0934dbc-be9d-4e77-b155-4a95b7ac62bd',
        value: 'CanUpdateCompanyInfo',
        moduleId: modules.SetupsAndConfiguration.id,
        typeId: permissionType.CompanyInfo.id,
        checked: false
    },

    //Back Up And Restore
    CanBackUpData: {
        id: 26,
        permissionName: 'Can BackUp Data',
        key: '8c4e6f08-a843-44e6-a7ae-8b654452d769',
        value: 'CanBackUpData',
        moduleId: modules.SetupsAndConfiguration.id,
        typeId: permissionType.BackUpAndRestore.id,
        checked: false
    },
    CanRestoreData: {
        id: 26,
        permissionName: 'Can Restore Data',
        key: 'd86e8a34-77e8-47e9-adf6-28baaf6d58b5',
        value: 'CanRestoreData',
        moduleId: modules.SetupsAndConfiguration.id,
        typeId: permissionType.BackUpAndRestore.id,
        checked: false
    },

    //Synchronization
    CanPushRecord: {
        id: 26,
        permissionName: 'Can Push Record',
        key: 'f6efdb9b-1099-47ee-aa4d-078567d9ce80',
        value: 'CanPushRecord',
        moduleId: modules.SetupsAndConfiguration.id,
        typeId: permissionType.Synchronization.id,
        checked: false
    },
    CanPullRecord: {
        id: 26,
        permissionName: 'Can Pull Record',
        key: 'a6ee9c10-99d5-4c67-ade7-ca777622cabe',
        value: 'CanPullRecord',
        moduleId: modules.SetupsAndConfiguration.id,
        typeId: permissionType.Synchronization.id,
        checked: false
    },

    //Reset Database
    CanFlushDatabase: {
        id: 26,
        permissionName: 'Can Flush Database',
        key: 'f8b09e82-6038-4c1c-8edb-7a75dca2a3f0',
        value: 'CanFlushDatabase',
        moduleId: modules.SetupsAndConfiguration.id,
        typeId: permissionType.ResetDatabase.id,
        checked: false
    },
    CanResetDatabase: {
        id: 26,
        permissionName: 'Can Reset Database',
        key: '0d1a3f51-e547-4fdb-8aff-abbb8bfc1e2f',
        value: 'CanResetDatabase',
        moduleId: modules.SetupsAndConfiguration.id,
        typeId: permissionType.ResetDatabase.id,
        checked: false
    },


    //Day Start Module:


    //System Pos Terminal
    CanAddPosTerminal: {
        id: 26,
        permissionName: 'Can Add Pos Terminal',
        key: '4f661bce-890d-47e9-a519-a0176e1098a8',
        value: 'CanAddPosTerminal',
        moduleId: modules.DayStart.id,
        typeId: permissionType.SystemPosTerminal.id,
        checked: false
    },
    CanEditPosTerminal: {
        id: 26,
        permissionName: 'Can Edit Pos Terminal',
        key: '455e82ca-2e89-4c38-975c-baa38e8627e4',
        value: 'CanEditPosTerminal',
        moduleId: modules.DayStart.id,
        typeId: permissionType.SystemPosTerminal.id,
        checked: false
    },
    CanViewPosTerminal: {
        id: 26,
        permissionName: 'Can View Pos Terminal',
        key: '3e1ba60d-1a6b-4347-9ae0-89c9458f131a',
        value: 'CanViewPosTerminal',
        moduleId: modules.DayStart.id,
        typeId: permissionType.SystemPosTerminal.id,
        checked: false
    },

    //System Terminal
    CanAddTerminal: {
        id: 26,
        permissionName: 'Can Add Terminal',
        key: 'b5883064-f6a7-4f2b-ba20-c9173ef64944',
        value: 'CanAddTerminal',
        moduleId: modules.DayStart.id,
        typeId: permissionType.SystemTerminal.id,
        checked: false
    },
    CanEditTerminal: {
        id: 26,
        permissionName: 'Can Edit Terminal',
        key: 'f397234b-4b4d-4ea8-9d94-7cf1edb1981c',
        value: 'CanEditTerminal',
        moduleId: modules.DayStart.id,
        typeId: permissionType.SystemTerminal.id,
        checked: false
    },
    CanViewTerminal: {
        id: 26,
        permissionName: 'Can View Terminal',
        key: '4c2a27f9-2c06-4acb-9b3e-2af63c8d0f99',
        value: 'CanViewTerminal',
        moduleId: modules.DayStart.id,
        typeId: permissionType.SystemTerminal.id,
        checked: false
    },
    //Start Operation
    StartDay: {
        id: 26,
        permissionName: 'Start Day',
        key: 'b6594dad-1e83-4c3b-aa43-dec60264bf84',
        value: 'StartDay',
        moduleId: modules.DayStart.id,
        typeId: permissionType.StartOperation.id,
        checked: false
    },
    CloseDay: {
        id: 26,
        permissionName: 'Close Day',
        key: '4fe47214-030b-4a16-bf1b-2f109d9a3f13',
        value: 'CloseDay',
        moduleId: modules.DayStart.id,
        typeId: permissionType.StartOperation.id,
        checked: false
    },
    CanViewDashboard: {
        id: 76,
        permissionName: "Can View Dashboards",
        key: "a06635ba-6653-4ad3-91dc-033c65b8280e",
        value: "CanViewDashboard",
          moduleId: modules.Dashboard.id,
          typeId: permissionType.Dashboard.id,
        checked: false,
      },
    
 
    

    StartOperationSetup: {
        id: 26,
        permissionName: 'Start Operation Setup',
        key: 'cb3b5fab-0af5-4dde-a911-2226b91187ea',
        value: 'StartOperationSetup',
        moduleId: modules.DayStart.id,
        typeId: permissionType.StartOperation.id,
        checked: false
    },

    StartOperationReport: {
        id: 26,
        permissionName: 'Start Operation Report',
        key: 'e9c31b48-e761-4b77-b7c0-d963ec0533eb',
        value: 'StartOperationReport',
        moduleId: modules.DayStart.id,
        typeId: permissionType.StartOperation.id,
        checked: false
    },

    //Production Module:
    //Production Recipes
    CanViewProductionRecipe: {
        id: 26,
        permissionName: 'Can View Production Recipe',
        key: 'b290deb7-4a1f-4de6-b4a7-83e068d23a09',
        value: 'CanViewProductionRecipe',
        moduleId: modules.ManufacturingAndProduction.id,
        typeId: permissionType.ProductionRecipes.id,
        checked: false
    },
    CanAddProductionRecipe: {
        id: 26,
        permissionName: 'Can Add Production Recipe',
        key: '94d04633-f385-4822-bdc0-5738c2ef9f64',
        value: 'CanAddProductionRecipe',
        moduleId: modules.ManufacturingAndProduction.id,
        typeId: permissionType.ProductionRecipes.id,
        checked: false
    },

    //Production Batch
    CanViewProductionBatch: {
        id: 26,
        permissionName: 'Can View Production Batch',
        key: '13cce48c-dce6-4ab5-ba71-64a12b1d420d',
        value: 'CanViewProductionBatch',
        moduleId: modules.ManufacturingAndProduction.id,
        typeId: permissionType.ProductionBatch.id,
        checked: false
    },
    CanAddProductionBatch: {
        id: 26,
        permissionName: 'Can Add Production Batch',
        key: 'bf570bf0-c284-4fc1-a829-9cf35e144f88',
        value: 'CanAddProductionBatch',
        moduleId: modules.ManufacturingAndProduction.id,
        typeId: permissionType.ProductionBatch.id,
        checked: false
    },

    //Dispatch Note
    CanViewDispatchNote: {
        id: 26,
        permissionName: 'Can View Dispatch Note',
        key: '5569f86b-c453-4eb1-b87e-4c74b3e17949',
        value: 'CanViewDispatchNote',
        moduleId: modules.ManufacturingAndProduction.id,
        typeId: permissionType.DispatchNote.id,
        checked: false
    },
    CanAddDispatchNote: {
        id: 26,
        permissionName: 'Can Add Dispatch Note',
        key: '0044ea0b-72b6-4102-b45c-ba7e05d6479d',
        value: 'CanAddDispatchNote',
        moduleId: modules.ManufacturingAndProduction.id,
        typeId: permissionType.DispatchNote.id,
        checked: false
    },

    // Location Level Effect
    

    //Hr and Payroll


    //Is Pricing


    //Allowance Type
    CanViewAllowanceType: {
    id: 26,
    permissionName: 'Can View Allowance Type',
    key: 'e149b3da-3f01-4c9f-a7fb-1b8a34b8bf57',
    value: 'CanViewAllowanceType',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.AllowanceType.id,
    checked: false
},

    CanAddAllowanceType: {
    id: 26,
    permissionName: 'Can Add Allowance Type',
    key: '91489e87-a72a-4a56-bd1f-c91d73bfbe8a',
    value: 'CanAddAllowanceType',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.AllowanceType.id,
    checked: false
},
    CanEditAllowanceType: {
    id: 26,
    permissionName: 'Can Edit Allowance Type',
    key: '6cb29efd-d235-428f-a97f-0b8d83caac37',
    value: 'CanEditAllowanceType',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.AllowanceType.id,
    checked: false
},

    //Allowance
    CanViewAllowance: {
    id: 26,
    permissionName: 'Can View Allowance',
    key: '11e2ee41-9130-4a3a-b7bf-7c7fd3572b01',
    value: 'CanViewAllowance',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.Allowance.id,
    checked: false
},

    CanAddAllowance: {
    id: 26,
    permissionName: 'Can Add Allowance',
    key: '5b6b2c97-c183-4b60-8a7f-42aa153105b0',
    value: 'CanAddAllowance',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.Allowance.id,
    checked: false
},
    CanEditAllowance: {
    id: 26,
    permissionName: 'Can Edit Allowance',
    key: '31ac42ab-55f8-42a3-9ad7-0d8dedfceca6',
    value: 'CanEditAllowance',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.Allowance.id,
    checked: false
},

    //Deductions
    CanViewDeduction: {
    id: 26,
    permissionName: 'Can View Deduction',
    key: 'dfba62ac-f4b7-41d4-a294-afffa177df7f',
    value: 'CanViewDeduction',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.Deduction.id,
    checked: false
},
    CanAddDeduction: {
    id: 26,
    permissionName: 'Can Add Deduction',
    key: '26206f37-d29d-4901-9ba3-98163ecf8797',
    value: 'CanAddDeduction',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.Deduction.id,
    checked: false
},
    CanEditDeduction: {
    id: 26,
    permissionName: 'Can Edit Deduction',
    key: '534a018a-bae9-46fc-98b1-fde3ec412f8f',
    value: 'CanEditDeduction',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.Deduction.id,
    checked: false
},

    //Contribution
    CanViewContribution: {
    id: 26,
    permissionName: 'Can View Contribution',
    key: '0709c235-8ec2-4cd2-83ab-25edebfc8f5e',
    value: 'CanViewContribution',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.Contribution.id,
    checked: false
},
    CanAddContribution: {
    id: 26,
    permissionName: 'Can Add Contribution',
    key: '840ec308-566b-429f-b0b1-bb1b2a43837e',
    value: 'CanAddContribution',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.Contribution.id,
    checked: false
},
    CanEditContribution: {
    id: 26,
    permissionName: 'Can Edit Contribution',
    key: '438cf9d4-b4c5-4fc6-9781-f088bf0150ec',
    value: 'CanEditContribution',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.Contribution.id,
    checked: false
},

    //PayRoll Schedule
    CanViewPayRollSchedule: {
    id: 26,
    permissionName: 'Can View PayRoll Schedule',
    key: 'b38b3a11-97ab-40ac-bd88-99b1208be5f7',
    value: 'CanViewPayRollSchedule',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.PayRollSchedule.id,
    checked: false
},
    CanAddPayRollSchedule: {
    id: 26,
    permissionName: 'Can Add PayRoll Schedule',
    key: 'fd559572-9426-4ca1-ac41-1c23076745d3',
    value: 'CanAddPayRollSchedule',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.PayRollSchedule.id,
    checked: false
},
    CanEditPayRollSchedule: {
    id: 26,
    permissionName: 'Can Edit PayRoll Schedule',
    key: '4f56d31d-8d90-49c4-bb8d-a633bc37d8c5',
    value: 'CanEditPayRollSchedule',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.PayRollSchedule.id,
    checked: false
},

    //Salery Template
    CanViewSaleryTemplate: {
    id: 26,
    permissionName: 'Can View Salery Template',
    key: 'bd260b43-2ca5-4328-9778-908ea8c08d68',
    value: 'CanViewSaleryTemplate',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.SalaryTemplate.id,
    checked: false
},
    CanAddSaleryTemplate: {
    id: 26,
    permissionName: 'Can Add Salery Template',
    key: '7fc5271c-0c44-4f1d-aa93-eb2898df7b2e',
    value: 'CanAddSaleryTemplate',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.SalaryTemplate.id,
    checked: false
},
    CanEditSaleryTemplate: {
    id: 26,
    permissionName: 'Can Edit Salery Template',
    key: 'dff19f3c-60ac-429a-be88-7b691a2704c7',
    value: 'CanEditSaleryTemplate',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.SalaryTemplate.id,
    checked: false
},

    // Employee Salary
    CanViewEmployeeSalary: {
    id: 26,
    permissionName: 'Can View Employee Salary',
    key: '46a94e11-3c29-4901-b2ae-0c7c60e6aef3',
    value: 'CanViewEmployeeSalary',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeSalary.id,
    checked: false
},
    CanAddEmployeeSalary: {
    id: 26,
    permissionName: 'Can Add Employee Salary',
    key: 'a4512c3b-cdf5-42e5-96e2-aec5c9877de6',
    value: 'CanAddEmployeeSalary',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeSalary.id,
    checked: false
},
    CanEditEmployeeSalary: {
    id: 26,
    permissionName: 'Can Edit Employee Salary',
    key: '3882c194-df63-4bce-9a8a-42db0da84938',
    value: 'CanEditEmployeeSalary',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeSalary.id,
    checked: false
},



    //Loan Payment
    CanViewLoanPayment: {
    id: 26,
    permissionName: 'Can View Loan Payment',
    key: 'abca36d0-0c8d-4d5d-8b04-23f87262078f',
    value: 'CanViewLoanPayment',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.LoanPayment.id,
    checked: false
},
    CanAddLoanPayment: {
    id: 26,
    permissionName: 'Can Add Loan Payment',
    key: '6b0e9228-981e-4180-b60c-f8dcff01df34',
    value: 'CanAddLoanPayment',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.LoanPayment.id,
    checked: false
},
    CanEditLoanPayment: {
    id: 26,
    permissionName: 'Can Edit Loan Payment',
    key: '5e630880-bacd-40bf-a8c3-bcc37a7c7d0b',
    value: 'CanEditLoanPayment',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.LoanPayment.id,
    checked: false
},

    //Salary Tax Slab
    CanViewSalaryTaxSlab: {
    id: 26,
    permissionName: 'Can View Salary Tax Slab',
    key: '67633b48-4186-4dde-8fe0-349c6e6c19dd',
    value: 'CanViewSalaryTaxSlab',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.SalaryTaxSlab.id,
    checked: false
},
    CanAddSalaryTaxSlab: {
    id: 26,
    permissionName: 'Can Add Salary Tax Slab',
    key: '1e1fbee2-f4a3-4c62-bbb0-d6fecc05c689',
    value: 'CanAddSalaryTaxSlab',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.SalaryTaxSlab.id,
    checked: false
},
    CanEditSalaryTaxSlab: {
    id: 26,
    permissionName: 'Can Edit Salary Tax Slab',
    key: 'd13fca78-e5ba-49b6-9823-71a95beb1e6b',
    value: 'CanEditSalaryTaxSlab',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.SalaryTaxSlab.id,
    checked: false
},

    //Manual Attendance
    CanViewManualAttendance: {
    id: 26,
    permissionName: 'Can View Manual Attendance',
    key: '867f6828-61ae-4245-a32c-e5e37a751b0f',
    value: 'CanViewManualAttendance',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.ManualAttendance.id,
    checked: false
},
    CanAddManualAttendance: {
    id: 26,
    permissionName: 'Can Add Manual Attendance',
    key: '3a55deac-8b92-4ad1-9c24-22598b5af09e',
    value: 'CanAddManualAttendance',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.ManualAttendance.id,
    checked: false
},
    CanAddTodayAttendance: {
    id: 26,
    permissionName: 'Can Add Today Attendance',
    key: 'b77ad36e-1cfc-47a1-93a5-05d8f8e8e4f5',
    value: 'CanAddTodayAttendance',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.ManualAttendance.id,
    checked: false
},
    CanViewAttendanceReport: {
    id: 26,
    permissionName: 'Can View Attendance Report',
    key: 'ef4ea67c-3ad5-4693-9cd3-e0333159a0ad',
    value: 'CanViewAttendanceReport',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.ManualAttendance.id,
    checked: false
},
    CanCheckOutAllTodayAttendence: {
    id: 26,
    permissionName: 'Can CheckOut All Today Attendence',
    key: '02733324-3f5c-46b1-873e-2061f2864263',
    value: 'CanCheckOutAllTodayAttendence',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.ManualAttendance.id,
    checked: false
},
    CanAddHolidaySetup: {
    id: 26,
    permissionName: 'Can Add Holiday Setup',
    key: 'ca2ff4b3-f49a-41fd-8aae-3a7c15c4aa9f',
    value: 'CanAddHolidaySetup',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.ManualAttendance.id,
    checked: false
},
    //Run Payroll
    CanViewRunPayroll: {
    id: 26,
    permissionName: 'Can View Run Payroll',
    key: '8dd76f49-fe78-4a46-8ea8-09b7941ed949',
    value: 'CanViewRunPayroll',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.RunPayroll.id,
    checked: false
},
    CanViewBankRunPayroll: {
    id: 26,
    permissionName: 'Can View Bank Run Payroll',
    key: '7a0880d5-d53a-4fe1-aad8-992ab2becef3',
    value: 'CanViewBankRunPayroll',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.RunPayroll.id,
    checked: false
},
    CanViewCashRunPayroll: {
    id: 26,
    permissionName: 'Can View Cash Run Payroll',
    key: '90ad21d7-e699-4bb6-92c4-bf03eb1534a6',
    value: 'CanViewCashRunPayroll',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.RunPayroll.id,
    checked: false
},
    CanAddRunPayroll: {
    id: 26,
    permissionName: 'Can Add Run Payroll',
    key: '34939d19-20ee-48e0-815f-4f1e5529dc86',
    value: 'CanAddRunPayroll',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.RunPayroll.id,
    checked: false
},
    CanDraftRunPayroll: {
    id: 26,
    permissionName: 'Can Draft Run Payroll',
    key: '22ee3a9b-9f16-45be-af3e-3d4c44b3ae7d',
    value: 'CanDraftRunPayroll',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.RunPayroll.id,
    checked: false
},
    CanEditOpenRunPayroll: {
    id: 26,
    permissionName: 'Can Edit Open Run Payroll',
    key: '184cd96a-3953-4662-a58d-646b8f391ea7',
    value: 'CanEditOpenRunPayroll',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.RunPayroll.id,
    checked: false
},
    CanEditCloseRunPayroll: {
    id: 26,
    permissionName: 'Can Edit Close Run Payroll',
    key: '89560edb-41a4-4f6f-8281-d82cfaa39a4b',
    value: 'CanEditCloseRunPayroll',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.RunPayroll.id,
    checked: false
},
    CanDownloadPdfRunPayroll: {
    id: 26,
    permissionName: 'Can Download Pdf Run Payroll',
    key: '4f8b8283-2b62-4cad-a334-ed0bca9d2896',
    value: 'CanDownloadPdfRunPayroll',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.RunPayroll.id,
    checked: false
},
    CanDownloadCsvRunPayroll: {
    id: 26,
    permissionName: 'Can Download Csv Run Payroll',
    key: 'b674848e-9f9a-4b50-89ed-3ee9284c37b5',
    value: 'CanDownloadCsvRunPayroll',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.RunPayroll.id,
    checked: false
},


    //Employee Registration
    CanViewEmployeeReg: {
    id: 26,
    permissionName: 'Can View Employee Registration',
    key: '7e84025d-99f7-45cb-b86a-4e4f13cf96c9',
    value: 'CanViewEmployeeReg',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},
    CanAddEmployeeReg: {
    id: 26,
    permissionName: 'Can Add Employee Registration',
    key: '0d5a10e1-7bcf-4cd0-a70e-b74e198d540a',
    value: 'CanAddEmployeeReg',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},
    CanEditEmployeeReg: {
    id: 26,
    permissionName: 'Can Edit Employee Registration',
    key: 'cda00ac5-863c-4ba5-8dbc-ad88789b8d25',
    value: 'CanEditEmployeeReg',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},
    HRSetup: {
    id: 26,
    permissionName: 'HR Setup',
    key: '814afb08-233e-4cce-b33d-06ac294a2528',
    value: 'HRSetup',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},
    HRReport: {
    id: 26,
    permissionName: 'HR Report',
    key: '95ea7559-1d0a-4c77-9f94-c4c1d8abdd92',
    value: 'HRReport',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},
    AttendanceManagement: {
    id: 26,
    permissionName: 'Attendance Management',
    key: 'dd9c4d5c-fec3-4e2f-8ab9-e4ce66516846',
    value: 'AttendanceManagement',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},
    EmployeePortal: {
    id: 26,
    permissionName: 'Employee Portal',
    key: 'ea6bebf3-749e-4d92-9759-1adee287aab7',
    value: 'EmployeePortal',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},
    EmployeeTasks: {
    id: 26,
    permissionName: 'Employee Tasks',
    key: 'eda5cfce-1f54-4f8d-9fac-33004570eb17',
    value: 'EmployeeTasks',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},
    AssetsManagementI: {
    id: 26,
    permissionName: 'Assets Management I',
    key: 'e28dd677-0bea-46a4-b9fd-ad4d57bb984a',
    value: 'AssetsManagementI',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},
    DocumentManagement: {
    id: 26,
    permissionName: 'Document Management',
    key: 'faab9cac-6bdf-4667-b511-5253ab3a141e',
    value: 'DocumentManagement',
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.EmployeeRegistration.id,
    checked: false
},

  //Leaves Management
  LeaveManagement: {
    id: 26,
    permissionName: "Leave Management",
    key: "58a7c75f-37c3-488e-bd3f-93ea21672e3d",
    value: "LeaveManagement",
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.LeavesManagement.id,
    checked: false,
  },
  EmployeePortalActivation: {
    id: 26,
    permissionName: "Employee Portal Activation",
    key: "cf998570-f757-4c7f-aa24-593dc0608fd0",
    value: "EmployeePortalActivation",
    moduleId: modules.HrAndPayRoll.id,
    typeId: permissionType.LeavesManagement.id,
    checked: false,
  },






    // Cheques And Guarantees
    CanViewCheque: {
    id: 26,
    permissionName: 'Can View Cheque',
    key: 'a86bbae3-21e1-4f4e-ba02-53516606aa65',
    value: 'CanViewCheque',
    moduleId: modules.Accounting.id,
    typeId: permissionType.ChequesAndGuarantees.id,
    checked: false
},
    CanEditCheque: {
    id: 26,
    permissionName: 'Can Edit Cheque',
    key: '2ee93650-4f5b-4619-96ff-1788400f7100',
    value: 'CanEditCheque',
    moduleId: modules.Accounting.id,
    typeId: permissionType.ChequesAndGuarantees.id,
    checked: false
},
    CanAddCheque: {
    id: 26,
    permissionName: 'Can Add Cheque',
    key: '6e82e702-9a16-4266-bedb-7ed09a6284b1',
    value: 'CanAddCheque',
    moduleId: modules.Accounting.id,
    typeId: permissionType.ChequesAndGuarantees.id,
    checked: false
},
    CanBlockCheque: {
    id: 26,
    permissionName: 'Can Block Cheque',
    key: '34d91630-9e79-466d-b8f1-e622c814bc14',
    value: 'CanBlockCheque',
    moduleId: modules.Accounting.id,
    typeId: permissionType.ChequesAndGuarantees.id,
    checked: false
},
    CanViewChequeDashboard: {
    id: 26,
    permissionName: 'Can View Cheque Dashboard',
    key: '71d7227f-5b55-4d3e-9594-c02d4de45753',
    value: 'CanViewChequeDashboard',
    moduleId: modules.Accounting.id,
    typeId: permissionType.ChequesAndGuarantees.id,
    checked: false
},




    //Inquiry Management Module

    //Inquiry Process
    CanViewInquiryProcess: {
    id: 26,
    permissionName: 'Can View Inquiry Process',
    key: 'c47866ba-586b-4a8d-9832-3e5428d0eb7c',
    value: 'CanViewInquiryProcess',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquiryProcess.id,
    checked: false
},
    CanAddInquiryProcess: {
    id: 26,
    permissionName: 'Can Add Inquiry Process',
    key: 'df3df514-95b9-4b30-839c-4e1599bda879',
    value: 'CanAddInquiryProcess',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquiryProcess.id,
    checked: false
},
    CanEditInquiryProcess: {
    id: 26,
    permissionName: 'Can Edit Inquiry Process',
    key: '2ca28a7f-fb95-4d12-bb88-1da5ee1cc5b2',
    value: 'CanEditInquiryProcess',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquiryProcess.id,
    checked: false
},

// Delivery Note
CanViewDeliveryNote: {
    id: 26,
    permissionName: "Can View Delivery Note",
    key: "6ef30fbe-eee7-46e0-96ef-3d3dbb2c4f97",
    value: "CanViewDeliveryNote",
    moduleId: modules.Sales.id,
    typeId: permissionType.DeliveryNote.id,
    checked: false,
  },
  CanAddDeliveryNote: {
    id: 26,
    permissionName: "Can Add Delivery Note",
    key: "b526a20d-fe2e-4bf1-9084-ac02682a237d",
    value: "CanAddDeliveryNote",
    moduleId: modules.Sales.id,
    typeId: permissionType.DeliveryNote.id,
    checked: false,
  },
  CanEditDeliveryNote: {
    id: 26,
    permissionName: "Can Edit Delivery Note",
    key: "4453d4a1-cedf-4b43-afc4-4cbe9330a479",
    value: "CanEditDeliveryNote",
    moduleId: modules.Sales.id,
    typeId: permissionType.DeliveryNote.id,
    checked: false,
  },
  DeliveryNoteToSale: {
    id: 26,
    permissionName: "Delivery Note To Sale",
    key: "cb8fd10c-b38c-4bd8-94f3-7dc25ba74729",
    value: "DeliveryNoteToSale",
    moduleId: modules.Sales.id,
    typeId: permissionType.AddInvoice.id,
    checked: false,
  },
  SaleToDeliveryNote: {
    id: 25,
    permissionName: "Sale To Delivery Note",
    key: "fc86918a-cd75-4210-9c75-e0afa4e71c45",
    value: "SaleToDeliveryNote",
    moduleId: modules.Sales.id,
    typeId: permissionType.SaleRecord.id,
    checked: false,
  },
  DebitNote :{
    id: 25,
    permissionName: "Debit Note",
    key: "f2d3b422-2bda-4d4c-9c2e-6fb7bcd77e71",
    value: "DebitNote",
    moduleId: modules.Sales.id,
    typeId: permissionType.SaleRecord.id,
    checked: false,
  },
  AddCustomerPO :{
    id: 25,
    permissionName: "Add Customer PO",
    key: "36d8ad1c-ad1d-4da4-a1b9-b1ae7da07cee",
    value: "AddCustomerPO",
    moduleId: modules.Sales.id,
    typeId: permissionType.SaleRecord.id,
    checked: false,
  },
  ViewCustomerPO :{
    id: 25,
    permissionName: "View Customer PO",
    key: "9b2da55c-1566-4b9d-bfce-657d780e8878",
    value: "ViewCustomerPO",
    moduleId: modules.Sales.id,
    typeId: permissionType.SaleRecord.id,
    checked: false,
  },

    // Prising On Sale
    CanPricingOnSaleInvoice: {
        id: 26,
        permissionName: "Can Pricing On Sale Invoice",
        key: "8e761fb1-ce8b-4bc0-a852-f70d2d95befc",
        value: "CanPricingOnSaleInvoice",
        moduleId: modules.Sales.id,
        typeId: permissionType.PricingOnSale.id,
        checked: false,
      },
    CanPricingOnSaleOrder: {
        id: 26,
        permissionName: "Can Pricing On Sale Order",
        key: "a64ed512-b0a7-4f0f-8343-92bc6a31151d",
        value: "CanPricingOnSaleOrder",
        moduleId: modules.Sales.id,
        typeId: permissionType.PricingOnSale.id,
        checked: false,
      },
    CanPricingOnQuotation: {
        id: 26,
        permissionName: "Can Pricing On Quotation",
        key: "3ede107a-8c4d-4349-89b4-93458184ba69",
        value: "CanPricingOnQuotation",
        moduleId: modules.Sales.id,
        typeId: permissionType.PricingOnSale.id,
        checked: false,
      },
    CanPricingOnCustomer: {
        id: 26,
        permissionName: "Can Pricing On Customer",
        key: "ed4c2ce2-67bb-4c06-9664-a3f7b1cbddb2",
        value: "CanPricingOnCustomer",
        moduleId: modules.Sales.id,
        typeId: permissionType.PricingOnSale.id,
        checked: false,
      },
    CanViewPriceLabeling: {
        id: 26,
        permissionName: "Can View Price Labeling",
        key: "3366741e-8572-4232-ad51-1054ddada991",
        value: "CanViewPriceLabeling",
        moduleId: modules.Sales.id,
        typeId: permissionType.PricingOnSale.id,
        checked: false,
      },



    //Inquiry Setup
    CanViewInquirySetup: {
    id: 26,
    permissionName: 'Can View Inquiry Setup',
    key: '43a13004-2008-443c-ad0a-e78bef596cc6',
    value: 'CanViewInquirySetup',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquirySetup.id,
    checked: false
},
    CanAddInquirySetup: {
    id: 26,
    permissionName: 'Can Add Inquiry Setup',
    key: '9184394d-fdcd-46a5-8e89-df81253989d6',
    value: 'CanAddInquirySetup',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquirySetup.id,
    checked: false
},
    CanEditInquirySetup: {
    id: 26,
    permissionName: 'Can Edit Inquiry Setup',
    key: 'bd969484-f154-4564-b2b4-a97f9cce167c',
    value: 'CanEditInquirySetup',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquirySetup.id,
    checked: false
},



    //Inquiry Type
    CanViewInquiryType: {
    id: 26,
    permissionName: 'Can View Inquiry Type',
    key: 'd7fe62e8-635d-45db-ac1f-458d5654224f',
    value: 'CanViewInquiryType',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquiryType.id,
    checked: false
},
    CanAddInquiryType: {
    id: 26,
    permissionName: 'Can Add Inquiry Type',
    key: 'bfe10710-5b15-4c18-9ba0-3c2cdc2d85ae',
    value: 'CanAddInquiryType',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquiryType.id,
    checked: false
},
    CanEditInquiryType: {
    id: 26,
    permissionName: 'Can Edit Inquiry Type',
    key: 'bbaa3d21-fdbe-4b32-a3ab-2dfc2d5ded38',
    value: 'CanEditInquiryType',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquiryType.id,
    checked: false
},


    //Inquiry Priority
    CanViewInquiryPriority: {
    id: 26,
    permissionName: 'Can View Inquiry Priority',
    key: 'ab4638d4-1e58-460e-a535-98490d281340',
    value: 'CanViewInquiryPriority',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquiryPriority.id,
    checked: false
},
    CanAddInquiryPriority: {
    id: 26,
    permissionName: 'Can Add Inquiry Priority',
    key: '196cd398-38f1-452c-9848-9a6f481dea05',
    value: 'CanAddInquiryPriority',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquiryPriority.id,
    checked: false
},
    CanEditInquiryPriority: {
    id: 26,
    permissionName: 'Can Edit Inquiry Priority',
    key: 'c70fe0ec-a0cd-49e0-a187-8b1a4b046100',
    value: 'CanEditInquiryPriority',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.InquiryPriority.id,
    checked: false
},

    //Heared About
    CanViewHearedAbout: {
    id: 26,
    permissionName: 'Can View Heared About',
    key: '0d9079f6-fd10-43ab-ad0c-a07c68e40fe9',
    value: 'CanViewHearedAbout',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.HearedAbout.id,
    checked: false
},
    CanAddHearedAbout: {
    id: 26,
    permissionName: 'Can Add Heared About',
    key: '55c5a482-e68d-4da2-88c7-1561ac629434',
    value: 'CanAddHearedAbout',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.HearedAbout.id,
    checked: false
},
    CanEditHearedAbout: {
    id: 26,
    permissionName: 'Can Edit Heared About',
    key: '0596769f-bc1e-4978-98c2-9fceffda6bc3',
    value: 'CanEditHearedAbout',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.HearedAbout.id,
    checked: false
},

    //Inquiry 
    CanViewInquiry: {
    id: 26,
    permissionName: 'Can View Inquiry',
    key: '6b4b8459-372b-485f-b9be-04051e8a5020',
    value: 'CanViewInquiry',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.Inquiry.id,
    checked: false
},
    CanAddInquiry: {
    id: 26,
    permissionName: 'Can Add Inquiry',
    key: 'aa2450c7-7081-4a2f-98e9-e20b51a18b3d',
    value: 'CanAddInquiry',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.Inquiry.id,
    checked: false
},
    CanEditInquiry: {
    id: 26,
    permissionName: 'Can Edit Inquiry',
    key: 'b7f48cc2-d0c8-4c5b-8c47-dba33bef24e1',
    value: 'CanEditInquiry',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.Inquiry.id,
    checked: false
},
    CanViewInquiryDashboard: {
    id: 26,
    permissionName: 'Can View Inquiry Dashboard',
    key: 'dfbe4c60-8880-4602-a320-c567f30a1783',
    value: 'CanViewInquiryDashboard',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.Inquiry.id,
    checked: false
},
    CanReplyInquiryDashboard: {
    id: 26,
    permissionName: 'Can Reply Inquiry Dashboard',
    key: '5ba32a80-880a-42ca-b385-45d00aff7f8e',
    value: 'CanReplyInquiryDashboard',
    moduleId: modules.InquiryManagement.id,
    typeId: permissionType.Inquiry.id,
    checked: false
},

    //Reparing Order:

    CanAddReparingOrder: {
    id: 26,
    permissionName: 'Can Add Reparing Order',
    key: 'c06373af-4e10-4aaa-ae59-1f81542eca21',
    value: 'CanAddReparingOrder',
    moduleId: modules.Purchase.id,
    typeId: permissionType.ReparingOrder.id,
    checked: false
},
    CanViewReparingOrder: {
    id: 26,
    permissionName: 'Can View Reparing Order',
    key: 'f19024c2-7728-48ac-a4e5-a7b8ccbd014f',
    value: 'CanViewReparingOrder',
    moduleId: modules.Purchase.id,
    typeId: permissionType.ReparingOrder.id,
    checked: false
},
    CanEditReparingOrder: {
    id: 26,
    permissionName: 'Can Edit Reparing Order',
    key: 'd978f3ed-3002-4723-901b-7844d92a1cd6',
    value: 'CanEditReparingOrder',
    moduleId: modules.Purchase.id,
    typeId: permissionType.ReparingOrder.id,
    checked: false
},



    //WarrantyCategory:

    CanAddWarrantyCategory: {
    id: 26,
    permissionName: 'Can Add Warranty Category',
    key: '9278ad00-f535-4841-b5e4-2a6664bcad58',
    value: 'CanAddWarrantyCategory',
    moduleId: modules.Purchase.id,
    typeId: permissionType.WarrantyCategory.id,
    checked: false
},
    CanViewWarrantyCategory: {
    id: 26,
    permissionName: 'Can View Warranty Category',
    key: 'a0701db3-1929-4051-b243-8e8d8b8ec608',
    value: 'CanViewWarrantyCategory',
    moduleId: modules.Purchase.id,
    typeId: permissionType.WarrantyCategory.id,
    checked: false
},
    CanEditWarrantyCategory: {
    id: 26,
    permissionName: 'Can Edit Warranty Category',
    key: 'f682e5e4-d1ab-490a-bbf4-61e453d6ea96',
    value: 'CanEditWarrantyCategory',
    moduleId: modules.Purchase.id,
    typeId: permissionType.WarrantyCategory.id,
    checked: false
},

    //Description:

    CanAddDescription: {
    id: 26,
    permissionName: 'Can Add Description',
    key: 'ff8845d5-3f28-4642-a8ff-56d09c392d1a',
    value: 'CanAddDescription',
    moduleId: modules.Purchase.id,
    typeId: permissionType.Description.id,
    checked: false
},
    CanViewDescription: {
    id: 26,
    permissionName: 'Can View Description',
    key: '174058a9-4cf0-4ed0-98a6-906d32d5d99f',
    value: 'CanViewDescription',
    moduleId: modules.Purchase.id,
    typeId: permissionType.Description.id,
    checked: false
},
    CanEditDescription: {
    id: 26,
    permissionName: 'Can Edit Description',
    key: '7445c53a-e068-41ff-90c0-6da2561aea7c',
    value: 'CanEditDescription',
    moduleId: modules.Purchase.id,
    typeId: permissionType.Description.id,
    checked: false
},
    //Problem:

    CanAddProblem: {
    id: 26,
    permissionName: 'Can Add Problem',
    key: '3724332c-8f32-4e7a-83f7-95dd00bf5d58',
    value: 'CanAddProblem',
    moduleId: modules.Purchase.id,
    typeId: permissionType.Problem.id,
    checked: false
},
    CanViewProblem: {
    id: 26,
    permissionName: 'Can View Problem',
    key: 'b4d60724-cdc0-4f06-b56c-36ea6df000a2',
    value: 'CanViewProblem',
    moduleId: modules.Purchase.id,
    typeId: permissionType.Problem.id,
    checked: false
},
    CanEditProblem: {
    id: 26,
    permissionName: 'Can Edit Problem',
    key: '2b6498b8-96a5-4520-b9ee-c2501479f71a',
    value: 'CanEditProblem',
    moduleId: modules.Purchase.id,
    typeId: permissionType.Problem.id,
    checked: false
},
    //Accessory:

    CanAddAccessory: {
    id: 26,
    permissionName: 'Can Add Accessory',
    key: '46d076aa-75af-4454-8fdf-99e45a20982d',
    value: 'CanAddAccessory',
    moduleId: modules.Purchase.id,
    typeId: permissionType.Accessory.id,
    checked: false
},
    CanViewAccessory: {
    id: 26,
    permissionName: 'Can View Accessory',
    key: '107b4648-81f6-4cb6-9d85-dd46a3ae6824',
    value: 'CanViewAccessory',
    moduleId: modules.Purchase.id,
    typeId: permissionType.Accessory.id,
    checked: false
},
    CanEditAccessory: {
    id: 26,
    permissionName: 'Can Edit Accessory',
    key: 'd8af455d-f48d-4666-88e9-dc40321b6e0d',
    value: 'CanEditAccessory',
    moduleId: modules.Purchase.id,
    typeId: permissionType.Accessory.id,
    checked: false
},













});

export default permissions;
