<template>
    <div class="row" v-if="isValid('CanAddPurchaseReturn')">
        <div class="col-md-12 ">

            <div class="col-lg-12">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 v-if="purchase.id === '00000000-0000-0000-0000-000000000000'"
                                        class="page-title">
                                        {{
                                                $t('AddPurchaseReturn.AddPurchaseReturn')
                                        }}
                                    </h4>
                                    <h4 v-else class="page-title">Update Purchase Return</h4>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                       class="btn btn-sm btn-outline-danger">
                                        {{ $t('Sale.Close') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="hr-dashed hr-menu mt-0" />
                <div class="row">
                    <div class="col-lg-6">
                        <div class="row form-group" v-bind:key="supplierRender">
                            <label class="col-form-label col-lg-4">
                                <span class="tooltip-container text-dashed-underline ">
                                    {{
                                        $t('AddPurchaseOrder.Supplier')
                                    }} : <span class="text-danger">*</span>
                                </span>
                            </label>
                            <div class="inline-fields col-lg-8">
                                <supplierdropdown v-model="$v.purchase.supplierId.$model"
                                                  :disable="purchase.approvalStatus === 5 && purchase.id != '00000000-0000-0000-0000-000000000000'"
                                                  v-bind:values="purchase.supplierId" :status="purchase.isRaw" />
                                <!--<a href="javascript:void(0);" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                                    class="text-primary">View Supplier Details</a>-->
                                <!--<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight"
                                    aria-labelledby="offcanvasRightLabel">
                                    <div class="offcanvas-header">
                                        <h5 id="offcanvasRightLabel" class="m-0">Supplier Details</h5>
                                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="offcanvas-body">
                                        <div class="row">-->
                                <!-- <div class="col-lg-12 form-group">
                                    <label>{{ $t('AddSaleOrder.Mobile') }}  :</label>
                                    <input type="text" class="form-control" v-model="purchase.mobile" />
                                </div>
                                <div class="col-lg-12 form-group">
                                    <label>{{ $t('CustomerAddress') }}  :</label>
                                    <textarea rows="3" v-model="purchase.customerAddress" class="form-control"> </textarea>
                                </div> -->
                                <!--</div>
                                    </div>
                                </div>-->
                            </div>
                        </div>

                        <div class="row form-group">
                            <label class="col-form-label col-lg-4">
                                <span class="tooltip-container text-dashed-underline ">{{ $t('AddPurchaseReturn.Invoice') }} #</span>
                            </label>
                            <div class="inline-fields col-lg-8">
                                <input v-model="registrationNo" class="form-control" type="text" disabled>
                            </div>
                        </div>

                        <div class="row form-group">
                            <label class="col-form-label col-lg-4">
                                <span class="tooltip-container text-dashed-underline ">
                                    {{ $t('AddPurchaseReturn.PurchaseInvoice') }} :
                                </span>
                            </label>
                            <div class="inline-fields col-lg-8">
                                <purchaseinvoicedropdown v-on:input="GetData" v-model="purchase.purchaseInvoiceId"
                                                         :supplierid="purchase.supplierId" :key="purchaseInvoiceRender" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row form-group">
                            <label class="col-form-label col-lg-4">
                                <span class="tooltip-container text-dashed-underline ">
                                    {{ $t('AddPurchaseReturn.WareHouse') }} :<span class="text-danger"> *</span>
                                </span>
                            </label>
                            <div class="inline-fields col-lg-8">
                                <warehouse-dropdown v-bind:key="rander" v-bind:values="purchase.wareHouseId"
                                                    v-model="$v.purchase.wareHouseId.$model" />
                            </div>
                        </div>

                        <div class="row form-group" v-if="raw == 'true'">
                            <label class="col-form-label col-lg-4">
                            </label>
                            <div class="inline-fields col-lg-8">
                                <div class="checkbox form-check-inline mx-2">
                                    <input type="checkbox" id="inlineCheckbox1" v-model="purchase.isRaw"
                                           @change="ChangeSupplier">
                                    <label for="inlineCheckbox1"> {{ $t('AddPurchaseReturn.RawProduct') }} </label>
                                </div>

                            </div>
                        </div>
                    </div>


                    <!-- <purchase-item :key="rander" :purchase="purchase" :raw="purchase.isRaw"
                        :taxMethod="purchase.taxMethod" :taxRateId="purchase.taxRateId" @input="SavePurchaseItems" class="pb-5"/> -->

                    <purchase-item @input="SavePurchaseItems" :taxMethod="purchase.taxMethod" @summary="updateSummary"
                                   :taxRateId="purchase.taxRateId" :purchase="purchase" :raw="purchase.isRaw"
                                   :key="rander" @discountChanging="updateDiscountChanging" :adjustmentProp="purchase.discount"
                                   :adjustmentSignProp="adjustmentSignProp" :isDiscountOnTransaction="purchase.isDiscountOnTransaction"
                                   :transactionLevelDiscountProp="purchase.transactionLevelDiscount" :isFixed="purchase.isFixed"
                                   :isBeforeTax="purchase.isBeforeTax" />

                    <div class="col-lg-12 invoice-btn-fixed-bottom">
                        <div v-if="!loading && purchase.id == '00000000-0000-0000-0000-000000000000'"
                             class="col-md-12 arabicLanguage">

                            <button class="btn btn-outline-primary me-2" v-if="isValid('CanAddPurchaseReturn')"
                                    :disabled="$v.$invalid || purchase.purchasePostItems.filter(x => x.outOfStock).length > 0 || purchase.purchasePostItems.filter(x => x.totalPiece == '').length > 0 || purchase.purchasePostItems.filter(x => x.unitPrice == '').length > 0 || (isFifo ? (purchase.purchasePostItems.filter(x => x.expiryDate == '').length > 0 || purchase.purchasePostItems.filter(x => x.batchNo == '').length > 0) : false)"
                                    v-on:click="savePurchase">
                                {{ $t('AddPurchaseReturn.Save') }}
                            </button>
                            <button class="btn btn-danger me-2" v-on:click="goToPurchase">
                                {{ $t('AddPurchaseReturn.Cancel') }}
                            </button>
                        </div>
                    </div>
                </div>
                <purchaseReturnHistorymodel :purchaseReturn="purchaseReturn" :show="show" v-if="show"
                                            @close="show = false" />
                <loading :name="loading" :active.sync="loading" :can-cancel="false" :is-full-page="true">
                </loading>
            </div>

        </div>
    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>
<script>
    import clickMixin from '@/Mixins/clickMixin'
    import Loading from 'vue-loading-overlay';
    import moment from "moment";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    //import Multiselect from 'vue-multiselect'
    //import VueBarcode from 'vue-barcode';
    export default {
        mixins: [clickMixin],
        components: {
            Loading
        },
        data: function () {
            return {
                discountTypeOption: 'At Line Item Level',
                adjustmentSignProp: '+',

                rendered: 0,
                registrationNo: "",
                purchase: {
                    id: "00000000-0000-0000-0000-000000000000",
                    date: "",
                    registrationNo: "",
                    supplierId: "",
                    invoiceNo: "",
                    invoiceDate: "",
                    purchaseOrder: "",
                    wareHouseId: "",
                    isRaw: false,
                    isPurchaseReturn: true,
                    purchasePostItems: [],
                    purchaseInvoiceId: "",

                    discount: 0,
                    isDiscountOnTransaction: false,
                    isFixed: false,
                    isBeforeTax: true,
                    transactionLevelDiscount: 0,

                    grossAmount: 0,
                    vatAmount: 0,
                    discountAmount: 0,
                    totalAmount: 0,
                },
                purchaseReturn: [],
                show: false,
                raw: '',
                rander: 0,
                counter: 0,
                loading: false,
                disable: false,
                isFifo: false,
                language: 'Nothing',
                supplierRender: 0,
                options: [],
                purchaseInvoiceRender: 0
            };
        },
        validations: {
            purchase: {
                date: { required },
                registrationNo: { required },
                supplierId: { required },
                invoiceNo: {},
                invoiceDate: {},
                wareHouseId: {},
            },
        },
        methods: {
            updateSummary: function (summary) {
                this.purchase.grossAmount = summary.total;
                this.purchase.vatAmount = summary.vat;
                this.purchase.discountAmount = summary.discount;
                this.purchase.totalAmount = summary.withVat;

            },

            updateDiscountChanging: function (isFixed, isBeforeTax) {
                this.purchase.isFixed = isFixed
                this.purchase.isBeforeTax = isBeforeTax
            },

            GotoPage: function (link) {
                this.$router.push({ path: link });
            },

            ViewPurchaseHistory: function (id) {

                this.show = !this.show;

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;

                root.$https.get('/PurchasePost/PurchaseReturnHistory?id=' + id + '&isReturnView=' + true + '&isMultiUnit=' + isMultiUnit, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {

                            root.purchaseReturn = response.data.purchaseReturnListHistory;

                        }
                    },
                        function (error) {
                            this.loading = false;
                            console.log(error);
                        });
            },
            LoadSupplierInvoice: function () {
                this.purchaseInvoiceRender++;
            },
            ChangeSupplier: function () {
                this.supplierRender++;
            },
            languageChange: function (lan) {
                if (this.language == lan) {
                    if (this.purchase.id == '00000000-0000-0000-0000-000000000000') {

                        var getLocale = this.$i18n.locale;
                        this.language = getLocale;

                        this.$router.go('/addproduct');
                    }
                    else {
                        this.$swal({
                            title: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'Error!' : 'خطأ',
                            text: (this.$i18n.locale == 'en' || this.isLeftToRight()) ? 'You cannot Change language during update, otherwise your current page data will be lose!' : 'لا يمكنك تغيير اللغة أثناء التحديث ، وإلا ستفقد بيانات صفحتك الحالية!',
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            icon: 'error',
                            timer: 4000,
                            timerProgressBar: true,
                        });
                    }
                }


            },

            GetData: function (id) {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                var isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                root.$https.get('/PurchasePost/PurchasePostDetail?id=' + id + '&isMultiUnit=' + isMultiUnit, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.purchase = response.data;
                        root.purchase.purchaseInvoiceId = response.data.id;
                        root.purchase.supplierId = response.data.supplierId;
                        root.purchase.purchasePostItems = response.data.purchasePostItems;
                        root.purchase.id = '00000000-0000-0000-0000-000000000000';
                        root.purchase.date = moment().format("LLL");

                        root.discountTypeOption = root.purchase.isDiscountOnTransaction ? 'At Transaction Level' : 'At Line Item Level'

                        root.adjustmentSignProp = root.purchase.discount >= 0 ? '+' : '-'

                        root.rander++;
                        root.CheckPurchaseReturnCounter(id);
                    }

                });
            },
            CheckPurchaseReturnCounter: function (id) {

                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.disable = false;
                root.$https
                    .get('/PurchasePost/PurchaseReturnHistoryCounter?id=' + id, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {

                        if (response.data != 0) {
                            root.counter = response.data;
                            root.disable = true;
                        }
                    });
            },
            AutoIncrementCode: function () {
                var root = this;
                var token = "";
                if (root.$session.exists()) {
                    token = localStorage.getItem("token");
                }
                root.$https
                    .get("/PurchasePost/PurchaseReturnAutoGenerateNo?terminalId=" + localStorage.getItem('TerminalId') + '&invoicePrefix=' + localStorage.getItem('InvoicePrefix') + '&userID=' + localStorage.getItem('UserID'), {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then(function (response) {
                        if (response.data != null) {
                            root.registrationNo = response.data.purchaseReturn;
                        }
                    });
            },
            SavePurchaseItems: function (purchaseOrderItems) {
                this.purchase.purchasePostItems = purchaseOrderItems;
            },
            savePurchase: function () {
                this.loading = true;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.purchase.isPurchaseReturn = true;
                this.purchase.isMultiUnit = localStorage.getItem('IsMultiUnit') == 'true' ? true : false;
                this.$https
                    .post('/PurchasePost/SavePurchasePostInformation', root.purchase, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.go();
                                }
                            });
                        }
                        else if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Update") {
                            root.info = response.data.bpi
                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved!' : '!تم الحفظ',
                                text: response.data.message.isAddUpdate,
                                type: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonStyling: false,
                                icon: 'success',
                                timer: 1500,
                                timerProgressBar: true,

                            }).then(function (ok) {
                                if (ok != null) {
                                    root.$router.push({
                                        path: '/PurchaseReturn',
                                        query: {
                                            data: 'PurchaseReturns'
                                        }
                                    })
                                }
                            });
                        }
                        else {

                            root.$swal({
                                title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                                text: response.data.message.isAddUpdate,
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                icon: 'error',
                                timer: 1500,
                                timerProgressBar: true,
                            });
                        }
                    })
                    .catch(error => {

                        root.$swal.fire(
                            {
                                type: 'error',
                                icon: 'error',
                                title: root.$t('AddPurchaseReturn.Error'),
                                text: error.response.data,
                                confirmButtonClass: "btn btn-danger",
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false
                    })
                    .finally(() => root.loading = false)
            },

            goToPurchase: function () {
                if (this.isValid('CanViewPurchaseReturn')) {
                    this.$router.push({
                        path: '/PurchaseReturn',
                        query: {
                            data: 'PurchaseReturns'
                        }
                    })
                }
                else {
                    this.$router.go();
                }


            },
        },
        created: function () {

            this.$emit('input', this.$route.name);
            this.isFifo = localStorage.getItem('fIFO') == 'true' ? true : false;
        },
        mounted: function () {
            this.language = this.$i18n.locale;
            //this.options = ['Inclusive', 'Exclusive'];
            if ((this.$i18n.locale == 'en' || this.isLeftToRight())) {
                this.options = ['Inclusive', 'Exclusive'];
            }
            else {
                this.options = ['شامل', 'غير شامل'];
            }
            if (this.$session.exists()) {
                this.raw = localStorage.getItem('IsProduction');
            }
            this.purchase.invoiceDate = moment().format("DD MM YYYY");
            if (this.$route.query.data == undefined) {
                this.AutoIncrementCode();
            }
            if (this.$route.query.data != undefined) {
                this.warehouse = this.$route.query.data;
            }
            this.purchase.date = moment().format("LLL");
        },
    };
</script>

