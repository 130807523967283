<template>
    <div class="row" v-if="isValid('CanViewBalanceSheetReport')">
        <div class="col-lg-12">
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <div class="row">
                            <div class="col">
                                <h4 class="page-title">Advance Balance Sheet</h4>
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:void(0);">
                                            {{ $t("BalanceSheetReport.Home") }}</a>
                                    </li>
                                    <li class="breadcrumb-item active">Advance Balance Sheet</li>
                                </ol>
                            </div>
                            <div class="col-auto align-self-center">
                                <a href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                                    <i class="far fa-file-excel font-14"></i>
                                    {{ $t("BalanceSheetReport.ExportCsv") }}
                                </a>
                                <a v-on:click="GotoPage('/StartScreen')" href="javascript:void(0);"
                                    class="btn btn-sm btn-outline-danger">
                                    {{ $t("Categories.Close") }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class=" col-lg-4   form-group">
                    <label>Period:</label>
                    <perioddropdown v-model="periodName" :values="periodName" :key="rander" />
                </div>
                <div class=" col-lg-4   form-group">
                    <label>Date:</label>
                    <datepicker v-model="date" :period="true" :key="rander" />
                </div>

                <div class=" col-lg-4   form-group">
                    <label>Compare With:</label>
                    <monthlydropdown v-model="numberOfCompare" :values="numberOfCompare" :PeriodName="periodName" :key="render" />
                </div>

            </div>
            <div class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="table-responsive  "></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <acessdenied></acessdenied>
    </div>
</template>
<script>
import clickMixin from "@/Mixins/clickMixin";
import moment from "moment";
export default {
    mixins: [clickMixin],
    props: ["formName"],
    data: function () {
        return {
            date: "",
            periodName: "Monthly",
            numberOfCompare: 1,
            rander: 0,
            render:0,
            result:[]
        };
    },
    watch: {
        date: function (date) {
            var numberOfCompare = this.numberOfCompare;
            this.getData(date, numberOfCompare);
        },
        numberOfCompare: function (numberOfCompare) {
            var date = this.date;
            this.getData(date, numberOfCompare);
        },
        periodName: function(date,numberOfCompare) {
            this.render++;
            this.getData(date, numberOfCompare);
        }
    },
    methods: {
        GotoPage: function (link) {
            this.$router.push({ path: link });
        },

        convertDate: function (date) {
            return moment(date).format("l");
        },

        getData: function (date, numberOfCompare) {
            var root = this;
            var token = "";
            if (this.$session.exists()) {
                token = localStorage.getItem("token");
            }
            this.$https.get("/Report/AdvanceBalanceSheet?date=" + date + "&numberOfCompare=" + numberOfCompare + '&formName=' + this.periodName,{ headers: { Authorization: `Bearer ${token}` } })
                .then(function (response) {
                    if (response.data != null) {
                        root.result =  response.data;
                    }
                });
        },
    },
    mounted: function () {
        this.language = this.$i18n.locale;
        this.date = moment().format("DD MMM YYYY");
        this.getData(this.date, this.numberOfCompare);
        this.rander++;
    },
};
</script>
